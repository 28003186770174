import { Col, Row } from "reactstrap";
import { TeamHittingStatsTrendsView } from "../hitter-finder/TrendsView/TeamHittingStatsTrendsView";
import { RecentTeamHittingStatsView } from "./RecentTeamHittingStatsView";
import { useState } from "react";
import { OpposingTeamSplitsView } from "./splits/OpposingTeamSplitsView";

export const OpposingTeamInfoView = (props) => {

    const [activeView, setActiveView] = useState('recent');
    const { opposingTeam, leagueWideStats, pitcher } = props;
    const { teamLogoUrl, teamHittingStats, statRankings, splits } = opposingTeam;

    const pitcher_hand = pitcher.handedness.throwing_hand;

    if (!teamHittingStats) {
        return <div>No Stats</div>
    }

    const logo = <img className="hitting-team-logo" src={teamLogoUrl}></img>

    return (
        <div>
            <OpposingTeamInfoViewSelector activeView={activeView} onClick={setActiveView}></OpposingTeamInfoViewSelector>
            <Row>
                {activeView == 'recent' && <RecentTeamHittingStatsView logo={logo} statRankings={statRankings} teamHittingStats={teamHittingStats} leagueWideStats={leagueWideStats} ></RecentTeamHittingStatsView>}
                {activeView == 'trends' && <TeamHittingStatsTrendsView logo={logo} chart_data={teamHittingStats.every_game} leagueWideStats={leagueWideStats}></TeamHittingStatsTrendsView>}
                {activeView === 'splits' && <OpposingTeamSplitsView pitcherHand={pitcher_hand} logo={logo} stats={splits} leagueWideStats={leagueWideStats}></OpposingTeamSplitsView>}
            </Row>
        </div>
    );
};


const OpposingTeamInfoViewSelector = (props) => {
    const { activeView, onClick } = props;

    return (
        <div>
            <Row className='bottom-border bg-white'>
                <Col
                    className={`bottom-border view-selector ${activeView == 'recent' ? 'active' : ''}`}
                    onClick={() => onClick('recent')}
                >Recent
                </Col>
                <Col
                    className={`bottom-border view-selector ${activeView == 'trends' ? 'active' : ''}`}
                    onClick={() => onClick('trends')}
                >Trends
                </Col>
                <Col
                    className={`bottom-border view-selector ${activeView == 'splits' ? 'active' : ''}`}
                    onClick={() => onClick('splits')}
                >Splits
                </Col>
            </Row>
        </div>
    );
}
import { Col, Row } from "reactstrap";
import { GameLogView } from "./GameLogView";
import { OpposingTeamInfoView } from "./OpposingTeamInfoView";
import { PitcherStatsView } from "./PitcherStatsView";

export const PitcherInfoMultiView = (props) => {
    const { view } = props;

    return (
        <div>
            <ViewSelector {...props}></ViewSelector>
            {view === 'opposing-team-info' && <OpposingTeamInfoView {...props}></OpposingTeamInfoView>}
            {view === 'game-log' && <GameLogView {...props}></GameLogView>}
            {view === 'pitcher-stats' && <PitcherStatsView {...props}></PitcherStatsView>}
        </div>
    );

};

const ViewSelector = (props) => {
    const { view, onClick } = props;

    return (
        <div>
            <Row className=''>
                <Col
                    className={`bottom-border view-selector ${view == 'pitcher-stats' ? 'active' : ''}`}
                    onClick={() => onClick('pitcher-stats')}
                >Stats

                </Col>
                <Col
                    className={`bottom-border view-selector ${view == 'game-log' ? 'active' : ''}`}
                    onClick={() => onClick('game-log')}
                >Game Log

                </Col>
                <Col
                    className={`bottom-border view-selector expandable ${view == 'opposing-team-info' ? 'active' : ''}`}
                    onClick={() => onClick('opposing-team-info')}
                >Opp Team
                </Col>
            </Row>
    </div>
    );

}


import { Col, Row } from "reactstrap";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { get_display_value } from "../../display-helpers/stat_value_display";
import { SeasonLeaders } from "./SeasonLeaders";

export const LeagueLeaders = (props) => {

    const { scoreboard, league_settings, week_by_week_stats, week_number } = props;

    if (league_settings?.length == 0 || scoreboard?.length == 0) {
        return null;
    }

    const stat_categories = league_settings.settings.stat_categories;
    const stat_groups = stat_categories.groups.group;
    const stat_group_names = stat_groups.map(x => x.group_name);

    let row_data = []

    const group_rows = stat_group_names.map((group, index) => {
        const stat_group = stat_categories.stats.stat.filter(s => s.group === group);
        const league_leaders_by_group = [];
        const league_leaders = [];
        const columns = stat_group.map((s, j) => {
            if (s.is_only_display_stat == 1) {
                return null;
            }

            week_by_week_stats.forEach((team, k) => {
                const week_of_interest = team.team_stats_by_week.find(x => x.week == week_number);



                const stat_of_interest = week_of_interest.weekly_stats.find(stat => stat.stat_id == s.stat_id);
                if (stat_of_interest?.rank == 1) {
                    league_leaders.push({ stat_of_interest, team })
                }
            })

            league_leaders_by_group.push({ league_leaders })
        });

        row_data.push({ group, league_leaders })

        return { group, league_leaders_by_group }
    });

    const weekly_leader_rows = row_data.map(row => {
        const row_title = row.group === 'batting' ? 'Hitting Leaders (Week) 🏆' : 'Pitching Leaders (Week) 🏆'
        const cols = row.league_leaders.map(leader => {
            const display_value = get_display_value(leader.stat_of_interest, leader.stat_of_interest.value)
            return (
                <Col className="league-leader-col">
                    <Row className="justify-center text-size-80 league-leader-value good-labe">{display_value}</Row>
                    <Row>
                        <PlayerStatDisplayCol className='good-label' size='xs' label={leader.stat_of_interest.display_name} value={leader.team.team.name}></PlayerStatDisplayCol>
                    </Row>
                </Col>
            )
        })

        return (
            <Row className="">
                <Col>
                    <Row className=""><h6>{row_title}</h6></Row>
                    <Row className="weekly-summary-standout">{cols}</Row>
                    <Row className="spacer-25"></Row>
                </Col>
            </Row>
        )
    })

    return (
        <Col>
            {weekly_leader_rows}
        </Col>
    );
};



import { useAuthContext } from "../context/auth-context";
import AppHeader from "./app-header";
import { Outlet } from "react-router-dom";
import RegisterContainer from "./auth/register-container";
import { useEffect, useState } from "react";
import LoadingIndicator from "./loading-indicator";


const Layout = (props) => {
    const { user, logout } = useAuthContext();
    const [registerFlag, setRegisterFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user !== undefined) { // works because user is set to null in useAuthContext (i think)

            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 200); // Adjust the delay as needed

            return () => clearTimeout(timer);

        }

    }, [user])

    if (isLoading) {
        return <LoadingIndicator></LoadingIndicator>
    }

    if (!user) {
        return <RegisterContainer></RegisterContainer>
    }

    return (
        <div>

            <AppHeader></AppHeader>
            <Outlet></Outlet>

        </div>
    )
}

export default Layout;
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { Bar, Line, ReferenceLine, XAxis, YAxis } from "recharts";
import { ResponsiveBarChart } from "./TrendCharts/ResponsiveBarChart";
import { create_and_merge_trendline_data } from "./TrendCharts/trendline";
import { renderYAxisPercentage } from "./TrendCharts/render-y-axis-percentage";

export const TeamHittingStatsTrendsView = (props) => {
    const [dataToShow, setDataToShow] = useState('runs');
    const { chart_data, leagueWideStats, logo } = props;

    const toggleData = (dataKey) => {
            setDataToShow(dataKey)
    }

    const toggleGraph = () => {
        if (dataToShow == 'runs') {
            setDataToShow('ks');
        } else if (dataToShow == 'ks') {
            setDataToShow('avg');
        } else if (dataToShow == 'avg') {
            setDataToShow('hh%')
        } else {
            setDataToShow('runs');
        }
    }

    const lg_runs_per_game_avg = leagueWideStats.hitter.all.totals[0].TG == 0 ? 0 : leagueWideStats.hitter.all.totals[0].R / leagueWideStats.hitter.all.totals[0].TG;
    const lg_ks_per_game_avg = leagueWideStats.hitter.all.totals[0].TG == 0 ? 0 : leagueWideStats.hitter.all.totals[0].SO / leagueWideStats.hitter.all.totals[0].TG;
    const lg_hard_percent_avg = leagueWideStats.hitter.all.totals[0]['HardHit%'];
    const lg_avg_avg = leagueWideStats.hitter.all.totals[0].AVG;


    const last_10_games = chart_data.slice(-10);

    const dataKeyMap = {
        'runs': 'R',
        'ks': 'K',
        'avg': 'AVG',
        'hh%': 'hardHit%'
    }

    const trendlineData = create_and_merge_trendline_data(last_10_games, dataKeyMap[dataToShow]);
   
    const league_averages = {
        'runs': lg_runs_per_game_avg,
        'ks': lg_ks_per_game_avg,
        'avg': lg_avg_avg,
        'hh%': lg_hard_percent_avg,
    }


    return (
        <div>
            <div className='solid-border-temp'>
                <Row className='trend-charts-row horizontal-scroll flex-nowrap'>
                    <Col className='solid-border-temp' onClick={toggleGraph}>
                        <TeamHittingStatsTrendsChart logo={logo} chart_data={trendlineData.data} trendlineInfo={{slope: trendlineData.slope,  intercept: trendlineData.intercept}} league_averages={league_averages} leagueWideStats={leagueWideStats} setDataToShow={toggleData} dataToShow={dataToShow}></TeamHittingStatsTrendsChart>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const TeamHittingStatsTrendsChart = (props) => {
    const {chart_data, leagueWideStats, logo, league_averages, trendlineInfo, setDataToShow, dataToShow} = props;

    const titleDict = {
        'runs': <span className="bold">{logo}  Runs per Game</span>,
        'ks': <span className="bold">{logo}  Ks per Game</span>,
        'hh%': <span className="bold">{logo}  Hard Hit %</span>,
        'avg': <span className="bold">{logo}  Batting Avg</span>,
    }

    const dataSetDisplayInfo = {
        'runs': { color: '#4477AA', display: 'R'},
        'ks': { color: '#AA3377', display: 'K'},
        'avg': {color: '#CCBB44', display: 'AVG'},
        'hh%': { color: '#66CCEE', display: 'HH%'},
    };

    const renderXAxisTicks = (tickProps) => {
        const { x, y, payload } = tickProps;
        const { value, offset } = payload;

        const dateParts = value.split('-');
        const day = dateParts[2].startsWith(0) ? dateParts[2][1] : dateParts[2];
        const month = dateParts[1].startsWith(0) ? dateParts[1][1] : dateParts[1];

        return <text className='text-size-70' x={x - 9} y={y + 5} text-anchor={'left'}>{month}/{day}</text>;

    };

    const x_axis_tickline = false;
    const x_axis_interval = 0;
    const x_axis = {dataKey: 'date', customRenderFxn: renderXAxisTicks, tickLine: x_axis_tickline, interval: x_axis_interval};


    const xAxesDictionary = {
        'runs': <XAxis dataKey={x_axis.dataKey} tickLine={x_axis.tickLine} tick={x_axis.customRenderFxn} interval={x_axis.interval}></XAxis>,
        'ks': <XAxis dataKey={x_axis.dataKey} tickLine={x_axis.tickLine} tick={x_axis.customRenderFxn} interval={x_axis.interval}></XAxis>,
        'hh%': <XAxis dataKey={x_axis.dataKey} tickLine={x_axis.tickLine} tick={x_axis.customRenderFxn} interval={x_axis.interval}></XAxis>,
        'avg': <XAxis dataKey={x_axis.dataKey} tickLine={x_axis.tickLine} tick={x_axis.customRenderFxn} interval={x_axis.interval}></XAxis>,
    }

    const yAxesDictionary = {
        'runs': [<YAxis  interval={0} domain={[0, 'dataMax']}></YAxis>],
        'ks':  [<YAxis  interval={0} domain={[0, 'dataMax']}></YAxis>],
        'hh%':  [<YAxis  interval={0} tick={renderYAxisPercentage} domain={[0, 'dataMax']}></YAxis>],
        'avg': [<YAxis  interval={0} tick={renderYAxisPercentage} domain={[0, 'dataMax']}></YAxis>],
    }

    const dataBarsDictionary = {
        'runs': [<Bar minPointSize={3} barSize={15} dataKey={'R'} fill={dataSetDisplayInfo['runs'].color}></Bar>],
        'ks': [<Bar minPointSize={3} barSize={15} dataKey={'K'} fill={dataSetDisplayInfo['ks'].color}></Bar>],
        'hh%': [<Bar minPointSize={3} barSize={15} dataKey={'hardHit%'} fill={dataSetDisplayInfo['hh%'].color}></Bar>],
        'avg': [<Bar minPointSize={3} barSize={15} dataKey={'AVG'} fill={dataSetDisplayInfo['avg'].color}></Bar>]
    }

    const leagueAvgValuesReferenceLineDictionary = {
        'runs': [
            <ReferenceLine type='monotone' strokeDasharray='3 3' y={league_averages['runs']} stroke={'#BBBBBB'}></ReferenceLine>
        ],
        'ks': [
            <ReferenceLine type='monotone' strokeDasharray='3 3' y={league_averages['ks']} stroke={'#BBBBBB'}></ReferenceLine>
        ],
        'hh%': [
            <ReferenceLine type='monotone' strokeDasharray='3 3' y={league_averages['hh%']} stroke={'#BBBBBB'}></ReferenceLine>
        ],
        'avg': [
            <ReferenceLine type='monotone' strokeDasharray='3 3' y={league_averages['avg']} stroke={'#BBBBBB'}></ReferenceLine>
        ]

    }

    const dataSetSelectorProps = {setDataToShow, dataSetDisplayInfo, dataToShow};

    const margin = {
       
        left: -25,
        top: 10,
        right: 5

    }

    const trendlineColorMap = {
        'runs': trendlineInfo.slope > 0 ? 'red' : 'green',
        'ks': trendlineInfo.slope > 0 ? 'green' : 'red',
        'hh%': trendlineInfo.slope > 0 ? 'red' : 'green',
        'avg': trendlineInfo.slope > 0 ? 'red' : 'green'

    }
    const trendline = <Line type="monotone" dataKey="trendValue" stroke={trendlineColorMap[dataToShow]} dot={false} />

    return (
            <ResponsiveBarChart
                trendline={trendline}
                chart_data={chart_data} 
                title={titleDict[dataToShow]}
                margin={margin} 
                dataBars={dataBarsDictionary[dataToShow]} 
                yAxes={yAxesDictionary[dataToShow]}
                xAxes={xAxesDictionary[dataToShow]}
                dataSetSelectorProps={dataSetSelectorProps}
                referenceLines={leagueAvgValuesReferenceLineDictionary[dataToShow]}
            ></ResponsiveBarChart>
    )
}


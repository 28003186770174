import { calculate_week_record } from "./weekly-record-calculator";



export const calculate_upsets = (scoreboard, starting_ranks, team_records) => {

    // console.log(scoreboard);
    // console.log(team_records)
    // console.log(starting_ranks);

    const upsets = [];
    scoreboard.scoreboard.forEach(matchup => {
        if (matchup.status == 'midevent') {
            return;
        }

        if (matchup.is_tied == 1) {
            return;
        }
        const team_1 = matchup.teams[0];
        const team_2 = matchup.teams[1];

        const winner_team_key = matchup.winner_team_key;
        const losing_team = team_1.team_key == winner_team_key ? team_2 : team_1;
        const winning_team = team_1.team_key == winner_team_key ? team_1 : team_2;
        
        
        const winning_team_rank = starting_ranks.find(x => x.team.team_key == winner_team_key).starting_rank;
            winning_team.starting_rank = winning_team_rank;
        const losing_team_rank = starting_ranks.find(x => x.team.team_key == losing_team.team_key).starting_rank;
            losing_team.starting_rank = losing_team_rank;
        const upset_magnitude = winning_team_rank - losing_team_rank;

        if (upset_magnitude > 0) {

            const winning_team_record = team_records.find(x => x.team.team_key == winner_team_key);
            const weeks_winning_team_record = calculate_week_record(winning_team_record.prev_record.matchup, winning_team_record.new_record.matchup)

            const upset = {winning_team, losing_team, matchup_result: weeks_winning_team_record, upset_magnitude}
            upsets.push(upset);
        }
       
        

    })

    return upsets;
}

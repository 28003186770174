import { Col, Row, Table } from "reactstrap";
import { get_display_value } from "../../display-helpers/stat_value_display";
import { convertToDecimalIP } from "../../utility/baseball-math";

export const SeasonLeaders = (props) => {
    const { league_settings, week_by_week_stats, week_number } = props;

    if (league_settings?.length == 0 || week_by_week_stats?.length == 0) {
        return null;
    }


    const stat_categories = league_settings.settings.stat_categories;
    const stat_groups = stat_categories.groups.group;
    const stat_group_names = stat_groups.map(x => x.group_name);

    const flattened_stats = [];
    const team_stats_for_the_week = [];

    stat_group_names.forEach((group, index) => {
        
        flattened_stats.push({ group, stats: [] })

        const stat_group = stat_categories.stats.stat.filter(s => s.group === group);

        stat_group.forEach((s,j) => {
            if (s.is_only_display_stat == 1) {
                return;
            }

            flattened_stats[index].stats.push(s)

            week_by_week_stats.forEach((team, k) => {
                const week_of_interest = team.team_stats_by_week.find(x => x.week == week_number);

                if (!team_stats_for_the_week.find(x => x.team.team_id == team.team.team_id)) {
                    team_stats_for_the_week.push({ week: week_of_interest.week, team: team.team, stats: week_of_interest.weekly_stats })
                }
            })
        })

    })


    // EXTRACT THIS IT'S SHARED WITH LeagueWideLineChart (this has been modified and is fresher)
    function get_cumulative_stats_by_team(week_by_week_stats, stat_id) {
        // Calculate cumulative totals for each team by week
        const team_cumulative_data = week_by_week_stats?.map(team_stats => {
            let IPs = 0;
            let ERs = 0;
            let WHs = 0;
            let ABs = 0;
            let Hs = 0;

            const team_name = team_stats.team.name;
            const team_stats_by_week = team_stats.team_stats_by_week;

            const data = team_stats_by_week.map((week, ii) => {
                // NEED TO FIGURE OUT HOW TO HANDLE NO STATS FOR A WEEK
                if (week.weekly_stats?.length == 0) {
                    return {week: week.week, value: 0, stat: {}};
                }

                const ips = parseFloat(convertToDecimalIP(week.weekly_stats.find(x => x.abbr == 'IP')?.value));
                IPs += ips;

                const stat = week.weekly_stats.find(s => s.stat_id == stat_id);
               
                if (stat.abbr === 'AVG') {
                    const HperABs = week.weekly_stats.find(x => x.abbr == 'H/AB')?.value;
                    if (HperABs) {
                        const parts = HperABs.split('/');
                        // console.log(parts)
                        if (parts.length > 1) {
                            ABs += (parseFloat(parts[1]) || 0);
                            Hs += (parseFloat(parts[0]) || 0);
                        }
                    }
                }

                if (stat.abbr === 'ERA') {
                    const ers = Math.round(ips * (parseFloat(stat?.value || 0) || 0) / 9);
                    ERs += ers;
                }

                if (stat.abbr === 'WHIP') {
                    const whs = Math.round(ips * (parseFloat(stat?.value || 0) || 0) / 9);
                    WHs += whs;
                }
                const value = parseFloat(stat.value);

                return { week: week.week, value, stat };
            });


           
            // Sort data by week in ascending order
            data.sort((a, b) => a.week - b.week);

            // Calculate cumulative totals
            let cumulativeTotal = 0;
            const cumulativeData = data.map(item => {
                if (item.stat.abbr == 'ERA') {
                    cumulativeTotal = ERs / IPs * 9;
                } else if (item.stat.abbr == 'WHIP') {
                    cumulativeTotal = WHs / IPs * 9;
                } else if (item.stat.abbr == 'AVG') {
                    cumulativeTotal = ABs == 0 ? 0 : Hs / ABs;
                } else {
                    cumulativeTotal += item.value;
                }

                return { ...item, cumulativeTotal };
            });

            return { team: team_stats.team, data: cumulativeData };
        });

        // Determine ranks by week
        const allWeeks = [...new Set([].concat(...team_cumulative_data.map(team => team.data.map(d => d.week))))].sort((a, b) => a - b);

        const rankedData = allWeeks.map(week => {
            // Extract cumulative totals for the current week
            let stat;
            const weekData = team_cumulative_data.map(team => {
                const weekStat = team.data.find(d => d.week === week);
                const { value, ...rest } = weekStat.stat;
                stat = rest;
                return {
                    team: team.team,
                    week,
                    cumulativeTotal: weekStat ? weekStat.cumulativeTotal : 0
                };
            });

            // Sort teams by cumulative total to determine rank
            if (stat.sort_order == 0) {
                weekData.sort((a, b) => a.cumulativeTotal - b.cumulativeTotal);
            } else {
                weekData.sort((a, b) => b.cumulativeTotal - a.cumulativeTotal);
            }

            // Assign rank
            return weekData.map((teamStat, index) => ({
                ...teamStat,
                rank: index + 1
            }));
        });

        // Flatten the ranked data
        const flattenedRankedData = [].concat(...rankedData);

        // Group the ranked data by team
        const groupedData = team_cumulative_data.map(team => {
            const teamData = flattenedRankedData.filter(item => item.team === team.team);
            return {
                team: team.team,
                data: teamData.map(d => ({ week: d.week, cumulativeTotal: d.cumulativeTotal, rank: d.rank }))
            };
        });

        return groupedData;
    }

    const cumulative_stats = [];
    flattened_stats.forEach((group, index) => {

        cumulative_stats.push({ group: group.group, stats: [] });
        group.stats.forEach(stat => {
            const cumulative_stat_totals = get_cumulative_stats_by_team(week_by_week_stats, stat.stat_id);
            cumulative_stats[index].stats.push({ stat, team_totals: cumulative_stat_totals });
        });

    });


    const season_leader_rows = flattened_stats.map((group, i) => {

        const cols = group.stats.map((stat, j) => {

            const stat_leader_group = cumulative_stats.find(s => s.group == group.group);
            const this_stat_team_totals = stat_leader_group.stats.find(x => x.stat.stat_id == stat.stat_id);

            const team_cum_stat_info = this_stat_team_totals.team_totals.map(team => {
                const team_total = team.data.find(w => w.week == week_number);
                const result = { team, info: team_total };
                result.info.stat_id = stat.stat_id;
                result.info.stat = stat;
                return result;
            });

            const first = team_cum_stat_info.find(x => x.info.rank == 1);
            const second = team_cum_stat_info.find(x => x.info.rank == 2);
            const third = team_cum_stat_info.find(x => x.info.rank == 3);
            const last = team_cum_stat_info.find(x => x.info.rank == team_cum_stat_info.length);
            const last_row = <tr><td>{team_cum_stat_info.length}</td><td>{last.team.team}</td><td>{last.info.cumulativeTotal}</td></tr>;

            const places = [first, second, third];
            const rows = places.map((p, i) => {

                const last_weeks_contribution = team_stats_for_the_week.find(x => x.team.team_id == p.team.team.team_id).stats.find(y => y.stat_id == p.info.stat_id).value;
                return (
                    <tr>
                        <td>{i + 1}</td>
                        <td>{p.team.team.name}</td>
                        <td className="text-align-center">{get_display_value(p.info.stat, p.info.cumulativeTotal)}</td>
                        <td className="text-align-center">{last_weeks_contribution}</td>
                    </tr>
                );
            });

            return (
                <Col className="season-stat-leader">

                    <Table borderless className="weekly-summary-table text-size-70" size='sm'>
                        <thead>
                            <tr>
                                <th className="text-align-center border-bottom" colSpan={4}>{stat.display_name}</th>
                            </tr>
                            <tr>
                                <th className="text-align-center"></th>
                                <th className="">Team</th>
                               
                                <th className="text-align-center">Season</th>
                                <th className="text-align-center">Last Week</th>
                            </tr>

                        </thead>
                        <tbody>
                            {rows}
                            {/* {last_row} */}
                        </tbody>
                    </Table>
                </Col>

            );
        });

        const row_title = group.group === 'batting' ? 'Hitting Races' : 'Pitching Races';
        const row = (
            <Row className="">
                <Col>
                    <Row className=""><h6>{row_title}</h6></Row>
                    <Row className="weekly-summary-standout">{cols}</Row>
                    <Row className="spacer-25"></Row>
                </Col>
            </Row>
        );
        return row;

    });

    return season_leader_rows;
};

import { Table } from "reactstrap";

export const PitcherSplitsTable = (props) => {
    const { splits, splitsToShowOnLoad, bordersToRemove, className, showTitleRow, title, hitterHand, hitterHomeGame } = props;

    const splitsToShow = splits.filter(s => splitsToShowOnLoad.includes(s.Split));

    const splitRows = splitsToShow.map((split, index) => {

        const className = bordersToRemove?.includes(split.Split) ? "border-none" : "";


        // TO DO: THIS IS FOR SURE NOT THE BEST WAY TO DO THIS..... LETS FIGURE SOMETHING ELSE OUT
        let hitterHandRelevantClass = '';
        if (hitterHand == 'L' && split.Split == 'vs L') {
            hitterHandRelevantClass+=' relevant '
        } else if (hitterHand == 'R' && split.Split == 'vs R') {
            hitterHandRelevantClass+=' relevant '
        }

        let hitterHomeRelevantClass = '';
        if (hitterHomeGame && split.Split == 'Away') {
            hitterHomeRelevantClass+= ' relevant '
        } else if (!hitterHomeGame && split.Split == 'Home') {
            hitterHomeRelevantClass+= ' relevant '
        }



        return (
            <tr key={index} className={className + hitterHandRelevantClass + hitterHomeRelevantClass}>
                <td><span className="bold">{split.Split}</span></td>
                <td>{split.TBF}</td>
                <td>{split.AVG?.toFixed(3)}</td>
                <td>{split.ER}</td>
                <td>{split.ERA?.toFixed(2)}</td>
                <td>{split.SO}</td>
                <td>{split.BABIP?.toFixed(3)}</td>
                <td>{split.FIP?.toFixed(2)}</td>
            </tr>
        );
    });

    return (
        <div>
            {/* <div>Splits Selector</div> */}
            <Table size="sm" className={className}>
                <thead>
                    {showTitleRow && <tr className='justify-center border-none'><th colSpan={8}>{title}</th></tr>}
                    <tr className="border-none">
                        <th></th>
                        <th>BF</th>
                        <th>AVG</th>
                        <th>ER</th>
                        <th>ERA</th>
                        <th>SO</th>
                        <th>BABIP</th>
                        <th>FIP</th>
                    </tr>
                </thead>
                <tbody>
                    {splitRows}
                </tbody>
            </Table>
        </div>
    );
};

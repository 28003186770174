import getApi from "./axios/api";

export async function getFreeAgentHitters( userToken, refreshUserToken, leagueId, position, timeFrame, sortCriteria, pageOffset) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/getfreeagents/get-hitters-projected-matchups/${leagueId}/${position}/${timeFrame}/${sortCriteria}/${pageOffset}`);
    return resp;
}

export async function searchHittersByName(token, refreshUserToken, leagueId, searchTerm, pageOffset) {
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    const api = getApi(token, refreshUserToken);
    const resp = await api.get( `/players/search/${leagueId}/${encodedSearchTerm}/${pageOffset}`);
    return resp;
}
import {Col, Row} from 'reactstrap';
import Game from './game';
import { useState } from 'react';
import { formatDateToMonthSlashDay, formatMonthSpaceDay, getWeekday } from '../../dates/date-helper';

function GameRow(props) {
    const {isOpen, leagueSettings, rosters} = props;
    const [showGamesRow, setShowGamesRow] = useState(isOpen);
    const toggleHideShow = () => {
      setShowGamesRow(!showGamesRow);
    }

    const games = props.games.map((game, index) => {
      return <Game key={index} gameInfo={game} leagueSettings={leagueSettings} rosters={rosters} leagueId={props.leagueId} league={props.league} addToWatch={props.addToWatch} removeFromWatch={props.removeFromWatch} watchedPlayerKeys={props.watchedPlayerKeys} thirdPartyRatings={props.thirdPartyRatings}></Game>;
    });

    const hideShowText = showGamesRow ? 'Hide' : 'Show';
    const dateDisplay = formatDateToMonthSlashDay(props.gameDate);
    const weekday = getWeekday(props.gameDate);

    return (
      <Row className="game-row">
        <Col>
          <Row className="game-date" onClick={toggleHideShow}>
            <Col xs='8' className='text-size-90 '>{dateDisplay} <span className='text-size-90'>({weekday})</span></Col>
            <Col className='text-align-right matchup-details clickable'><span className='bold text-size-90'>{hideShowText}</span></Col>
          </Row>
          {showGamesRow && <Row className="justify-center-on-small">{games}</Row>}
        </Col>
      </Row>
    );
  }

  export default GameRow;


export const create_and_merge_trendline_data = (chart_data, dataKey) => {

   // Function to get trendline data
const get_trendline_data = (chart_data, valueKey) => {

    // Function to calculate the slope and intercept for the trendline
    function calculateTrendline(data, valueKey) {
        const n = data.length;
        let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
    
        data.forEach((point, index) => {
            const value = Number(point[valueKey]);
            sumX += index;
            sumY += value;
            sumXY += index * value;
            sumX2 += index * index;
        });
    
        const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
        const intercept = (sumY - slope * sumX) / n;
    
        // Ensure that the trendline does not dip below y=0
        const positiveIntercept = Math.max(0, intercept);
        return { slope, intercept: positiveIntercept };
    }
    
  
    // Generate trendline data points
    function generateTrendlineData(data, slope, intercept) {
        return data.map((point, index) => ({
            name: point.date,
            trendValue: slope * index + intercept
        }));
    }
  
    const { slope, intercept } = calculateTrendline(chart_data, valueKey);

    const trendlineData = generateTrendlineData(chart_data, slope, intercept);
  
    return {data: trendlineData, slope, intercept};
  }
  
  // Specify the key to use for trendline calculation

  const trendlineData = get_trendline_data(chart_data, dataKey);
  
  // Merge the trendline data with the original data
  const combinedData = chart_data.map((dataPoint, index) => ({
    ...dataPoint,
    trendValue: trendlineData.data[index].trendValue
  }));

  return {data: combinedData, slope: trendlineData.slope, intercept: trendlineData.intercept};
}


export const convertToDecimalIP = (ipString) => {
    if (!ipString) {
        return 0;
    }

    if (!(typeof ipString == 'string')) {
        return 0;
    }

    if (!ipString.includes('.')) {
        return ipString
    }

    const [wholeInnings, fractionOuts] = ipString.split('.').map(Number);
    return wholeInnings + (fractionOuts / 3);
}

export const convertToTraditionalIP = (decimalIP) => {
    if (decimalIP === 0) {
        return '0.0';
    }

    const wholeInnings = Math.floor(decimalIP);
    const decimalFraction = decimalIP - wholeInnings;
    const fractionOuts = Math.round(decimalFraction * 3);

    return `${wholeInnings}.${fractionOuts}`;
}
import { Col, Row } from 'reactstrap';
import { PlayerRankingInfo } from './PlayerRankingInfo';
import { useState } from 'react';
import { HitterInfoMultiView } from './HitterInfoMultiView';
import { PlayerInfoHeader } from '../player-info/PlayerInfoHeader';
import AddDropModal from '../add-drop-scheduler/add-drop-modal/add-drop-modal';

export const HitterInfo = (props) => {
    const { hitter, rosters, selectedLeague, leagueSettings } = props;
    const [viewSelected, setViewSelected] = useState('upcoming');
    const [isAddDropModalOpen, setIsAddDropModalOpen] = useState(false);

    const toggleAddDropModal = () => setIsAddDropModalOpen(!isAddDropModalOpen)

    return (
        <div>
            <AddDropModal leagueSettings={leagueSettings} player={hitter} toggle={toggleAddDropModal} league={selectedLeague} isOpen={isAddDropModalOpen} roster={rosters?.futureRoster}></AddDropModal>
            <PlayerInfoHeader openAddDrop={toggleAddDropModal} player={hitter}></PlayerInfoHeader>
               
            <Row className='bottom-border'>
                <PlayerRankingInfo hitter={props.hitter}></PlayerRankingInfo>
            </Row>

            <Row>

                {/* <OwnedAndRosteredInfo hitter={props.hitter}></OwnedAndRosteredInfo> */}

            </Row>

            <Row className='bottom-border text-align-center '>
                <HitterInfoMultiView
                    view={viewSelected} // new
                    onClick={setViewSelected} // new
                    {...props}
                    
                ></HitterInfoMultiView>
            </Row>
        </div>
    );
};



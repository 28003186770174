import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useState, useContext, createContext, useEffect } from 'react';
import { auth } from '../firebase';

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const token = await user.getIdToken();
        setUserToken(token);
      } else {
        setUserToken(null); // Reset token if user is not authenticated
      }
    });

    return () => unsubscribe();
  }, []);

  const refreshUserToken = async () => { 
    try {
      if (user) {
        const token = await user.getIdToken(true); // Force refresh if needed
        setUserToken(token);
        return token; // Return the refreshed token
      }
      return null; // User is not authenticated, return null or handle appropriately
    } catch (error) {
      console.error('Failed to refresh user token:', error);
      throw error; // Throw an error to indicate token refresh failure
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserToken(null);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  return (
    <AuthContext.Provider value={{ user, userToken, refreshUserToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuthContext = () => useContext(AuthContext);
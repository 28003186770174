import { Cell, Pie, PieChart } from "recharts";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";
import { get_display_value, get_display_value_custom_round } from "../../display-helpers/stat_value_display";
import { populate_stat_sets, get_max_stat_value, get_min_stat_value, get_average_stat_value } from "./strategy-builder-container";

export const LeagueStatPieChart = (props) => {
    const { week_by_week_stats, team_id, subset_selected_key, selectedStat } = props;
    if (!week_by_week_stats) {
        return <div></div>;
    }

    const teams_stats = week_by_week_stats.find(x => x.team.team_id == team_id);

    let team_specific_set_of_stats_for_subset = [];
    let rest_of_league_set_of_stats_for_subset = [];

    ({ team_specific_set_of_stats_for_subset, rest_of_league_set_of_stats_for_subset } = populate_stat_sets(subset_selected_key, teams_stats, team_specific_set_of_stats_for_subset, rest_of_league_set_of_stats_for_subset, week_by_week_stats));

    const stat_id = selectedStat.stat_id || 7;

    const RADIAN = Math.PI / 180;
    const reversed = ['ERA', 'WHIP'];

    const stroke_color = 'white';
    const stroke_width = 3;

    const data = 

        [
            { name: 'A', value: 36, color: '#4EB265', stroke: stroke_color, strokeWidth: stroke_width },
            { name: 'B', value: 36, color: '#CAE0AB', stroke: stroke_color, strokeWidth: stroke_width  },
            { name: 'C', value: 36, color: '#F7F056', stroke: stroke_color, strokeWidth: stroke_width  },
            { name: 'D', value: 36, color: '#EE8026', stroke: stroke_color, strokeWidth: stroke_width  },
            { name: 'E', value: 36, color: '#DC050C', stroke: stroke_color, strokeWidth: stroke_width  },

        ];
    
        if (!reversed.includes(selectedStat.abbr)) {
            data.reverse()
        }

    const cx = 150;
    const cy = 195;
    const iR = 80;
    const oR = 130;
    const value = 50;

    const needle = (value, data, cx, cy, iR, oR, color, r, key) => {
        let total = 0;
        data.forEach((v) => {
            total += v.value;
        });
        const ang = 180 * (1 - value / total);
        const length = iR * .9
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xba = x0 + r * sin;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            <circle key={`${key}-1`} cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
            <path key={key} d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
        ];
    };

    const length_randomizing_values = [];
    for (let i = 0.97; i <= 1.03; i += 0.01) {
        if (i.toFixed(2) !== '1.00') {
            length_randomizing_values.push(i.toFixed(2)); // toFixed(2) ensures the increment precision
        }
    }

    const other_team_indicator = (normalized_value, raw_value, data, cx, cy, iR, oR, color, r, key) => {
        const randomIndex = Math.floor(Math.random() * length_randomizing_values.length);
        const randomValue = parseFloat(length_randomizing_values[randomIndex]);
        let total = 0;
        data.forEach((v) => {
            total += v.value;
        });
        const ang = 180 * (1 - normalized_value / total);
        const length = ((oR + iR) / 2) * randomValue
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            //<line key={key} x1={x0} y1={y0} x2={xp} y2={yp} stroke={color} strokeWidth={r} />,
                <text key={`text-${key}`} x={xp} y={yp} dy={-5} fill={color} className="bold" fontSize="12" textAnchor="middle">
                 x
             </text>,
        ];
    };

    const tick_label = (normalized_value, value, data, cx, cy, iR, oR, color, index, className) => {
        let total = 0;
        data.forEach((v) => {
            total += v.value;
        });
        const ang = 180 * (1 - normalized_value / total);
        const length = (oR * 1.05);
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            <text className={`bold ${className}`} key={`text-${value}-${index}`} x={xp} y={yp} dy={-5} fill={color} fontSize="12" textAnchor="middle">
                {value}
            </text>,
        ];
    };

    const tick_mark = (normalized_value, value, data, cx, cy, iR, oR, color, index, className) => {
        let total = 0;
        data.forEach((v) => {
            total += v.value;
        });
        const ang = 180 * (1 - normalized_value / total);
        const length = (5);
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const x0 = cx + 5 + (oR-3) * cos;
        const y0 = cy + 5 + (oR-3) * sin;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return (
            <line key={index} x1={x0} y1={y0} x2={xp} y2={yp} stroke={color} strokeWidth={1} />
        );
    };

    const chart_title = (
        [
        <text x={cx} y={cy - (.9 * cy)} fontSize="12" textAnchor="middle" className="bold">
            League Scoring Distribution
        </text>,
        <text x={cx} y={cy - (.8 * cy)} fontSize="12" textAnchor="middle" className="bold">
            ({selectedStat.abbr})
        </text>
        ]
    )
    function calculate_normalized_stat_value(stat, stat_max_value, stat_min_value, stat_avg_value, min_angle, max_angle) {
        const ratio = (stat.value - stat_min_value) / (stat_max_value - stat_min_value);
        const normalized_value = min_angle + ratio * (max_angle - min_angle);

        return normalized_value;


        //========================
        // puts the avg value in the center:
        if (stat.value < stat_avg_value) {
            normalized_value = (stat_avg_value - stat_min_value) == 0 ? 0 : min_angle + (stat.value - stat_min_value) / (stat_avg_value - stat_min_value) * (90 - min_angle);
        } else {
            normalized_value = (stat_max_value - stat_avg_value) == 0 ? 0 : 90 + (stat.value - stat_avg_value) / (stat_max_value - stat_avg_value) * (max_angle - 90);
        }
        return normalized_value;
    }
    
    function generateEvenlySpacedIntegers(min, max, count) {
        if (count < 2) {
            throw new Error('Count must be at least 2 to create a range.');
        }

        const result = [];
        if (max - min <= 6) {
           for (let i=min; i<=max; i++) {
            result.push(i)
           }
            return result;
        }
    
        const step = (max - min) / (count - 1);
       
    
        for (let i = 0; i < count; i++) {
            result.push(Math.round(min + step * i));
        }
    
        return result;
    }

    function generateEvenlySpacedFloats(min, max, count) {
        if (count < 2) {
            throw new Error('Count must be at least 2 to create a range.');
        }

        const result = [];
    
        const step = (max - min) / (count - 1);
       
    
        for (let i = 0; i < count; i++) {
            result.push((min + step * i));
        }
    
        return result;
    }

    const everyones_stats = [...rest_of_league_set_of_stats_for_subset, {team: teams_stats.team, stats: team_specific_set_of_stats_for_subset}]
    const stat_max_value = get_max_stat_value(everyones_stats, stat_id);
    const stat_min_value = get_min_stat_value(everyones_stats, stat_id);
    const stat_avg_value = get_average_stat_value(everyones_stats, stat_id);


    const min_angle = 10;
    const max_angle = 170;

    const team_stat = team_specific_set_of_stats_for_subset.find(x => x.stat_id == stat_id);
    const team_stat_value_norm = calculate_normalized_stat_value(team_stat, stat_max_value, stat_min_value, stat_avg_value, min_angle, max_angle);

    const team_needle = needle(team_stat_value_norm, data, cx, cy, iR, oR, '#33BBEE', 4, 'my-needle-key');

    const league_indicators = rest_of_league_set_of_stats_for_subset.map((team, index) => {
        const stat = team.stats.find(x => x.stat_id == stat_id);
        let normalized_value = calculate_normalized_stat_value(stat, stat_max_value, stat_min_value, stat_avg_value, min_angle, max_angle);

        return other_team_indicator(normalized_value, stat.value, data, cx, cy, iR, oR, 'black', 1, index);
    });


    const evenly_spaced_floats = generateEvenlySpacedFloats(stat_min_value, stat_max_value, 5)
    const tick_labels_for_continuous_scale = evenly_spaced_floats.map((val, index) => {
        const normalized_value = calculate_normalized_stat_value({ value: val }, stat_max_value, stat_min_value, stat_avg_value, min_angle, max_angle);
        let display = get_display_value(selectedStat, val);
        return tick_label(normalized_value, display, data, cx, cy, iR, oR, 'black', index);
    });

    const evenly_spaced_ints = generateEvenlySpacedIntegers(stat_min_value, stat_max_value, 5);

    const tick_labels_for_discreet_scale = evenly_spaced_ints.map((val, index) => {
        const normalized_value = calculate_normalized_stat_value({ value: val }, stat_max_value, stat_min_value, stat_avg_value, min_angle, max_angle)
        return tick_label(normalized_value, val, data, cx, cy, iR, oR, 'black', index)
    })

   
    const my_teams_stat_value = get_display_value(team_stat, team_stat.value);
    let my_teams_display_val;
    if (selectedStat.abbr == 'AVG') {
        my_teams_display_val = remove_leading_zeros(parseFloat(team_stat.value).toFixed(3));
    } else {
        my_teams_display_val = remove_leading_zeros(Math.round(team_stat.value));
    }

    const my_teams_tick_label = tick_label(team_stat_value_norm, 'X', data, cx, cy, iR, oR * 0.75, '#33BBEE', 1, 'zzz', 'text-size-110');


    const stats_with_continuous_tick_label = ['AVG', 'WHIP', 'SLG', 'ERA'];
    const tick_locations = stats_with_continuous_tick_label.includes(selectedStat.abbr) ? evenly_spaced_floats : evenly_spaced_ints;
    const tick_marks = tick_locations.map((x, index) => {
        const normalized_value = calculate_normalized_stat_value({ value: x }, stat_max_value, stat_min_value, stat_avg_value, min_angle, max_angle);
        return tick_mark(normalized_value, x, data, cx, cy, iR, oR, 'black', index)
    })

    return (
        <div className="trend-chart-container stat-pie-chart justify-center text-align-center">
            <PieChart width={350} height={230}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx={cx}
                    cy={cy}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill="#8884d8"
                    stroke="none"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                {league_indicators}
                {team_needle}
                {stats_with_continuous_tick_label.includes(selectedStat.abbr) ? tick_labels_for_continuous_scale : tick_labels_for_discreet_scale}
                {my_teams_tick_label}
                {chart_title}
                {tick_marks}

            </PieChart>
        </div>

    );
};

import { useState } from 'react';
import { HitterStatsView } from './HitterStatsView';
import { statsToShow } from './hitter-stats-to-show';
import { HitterLeagueComparisonView } from './HitterLeagueComparisonView';
import { HitterStatsPerGameView } from './HitterStatsPerGameView';


export const HitterStatsPerSeasonView = (props) => {
    const [activeView, setActiveView] = useState('vs-avg');

    const toggleView = () => {
        if (activeView === 'total') {
            setActiveView('per-game')
        }
        if (activeView === 'per-game') {
            setActiveView('vs-avg');
        }
        if (activeView === 'vs-avg') {
            setActiveView('total')
        }
    }

    const season_splits = props.hitter.splits?.season.totals;
    const season_stats = props.hitter.stats?.season;

    const title = activeView == 'total' ? 'Total' : activeView == 'per-game' ? 'Per Game' : 'vs League Avg';

    const getViewToShow = (view) => {
        if (view == 'vs-avg') {
            return <HitterLeagueComparisonView {...props}></HitterLeagueComparisonView>;
        } else if (view == 'per-game') {
            return <HitterStatsPerGameView {...props}></HitterStatsPerGameView>;
        } else {
            return <HitterStatsView statsToShow={statsToShow} stats={season_stats?.totals} splits={season_splits} platoonInfo={props.hitter.platoonInfo}></HitterStatsView>
        }
    }


    return (
        <div onClick={toggleView}>
            {title}
            {getViewToShow(activeView)}
        </div>
    )
    
};

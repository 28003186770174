import { GameLogView } from './GameLogView';
import { ViewSelector } from './ViewSelector';
import { SeasonStatsView } from './SeasonStatsView';
import { UpcomingGamesView } from './UpcomingGamesView';

export const HitterInfoMultiView = (props) => {
    const { view } = props;

    return (
        <div>
            <ViewSelector {...props}></ViewSelector>
            {view === 'upcoming' && <UpcomingGamesView {...props}></UpcomingGamesView>}
            {view === 'game-log' && <GameLogView {...props}></GameLogView>}
            {view === 'stats' && <SeasonStatsView {...props}></SeasonStatsView>}
        </div>
    );

};



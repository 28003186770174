import axios from "axios";
import { createErrorResponse, createSuccessResponse } from "./api-response";
import getApi from "./axios/api";

export async function getScheduledAddDropRecords(userToken, refreshUserToken, leagueId, teamId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/roster/scheduleAddDrops/${leagueId}/${teamId}`);
    return resp;
}

export async function addScheduleAddDropRecord(userToken, refreshUserToken, args) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post(`/roster/scheduleAddDrop`, args);
    return resp;
}

export async function executeAddDrop(userToken, refreshUserToken, args) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post(`/roster/executeAddDrop`, args);
    return resp;
}

export async function removeScheduleAddDropRecord(userToken, refreshUserToken, args) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/roster/removeScheduledAddDrop', args);
    return resp;
}

export async function confirmTime(timeToConfirm) {
    try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/addDropSchedule/confirmTime/${timeToConfirm}`

        const resp = await axios.get(url);
        return createSuccessResponse(resp.data);
    } catch (err) {
        console.error(err);
        return createErrorResponse(err);
    }
}
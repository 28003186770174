
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css";
import { RosterDropdown } from "./RosterDropdown";
import { useEffect, useState } from "react";
import { addScheduleAddDropRecord, confirmTime, executeAddDrop } from "../../../api/schedule-add-drop";
import { useAuthContext } from "../../../context/auth-context";
import ErrorAlert from "../../errors/error-alert";
import { AddDropTimeSelector } from "./AddDropTimeSelector";
import { Link } from "react-router-dom";

const AddDropModal = (props) => {
    const [playerToDrop, setPlayerToDrop] = useState();
    const [futureNow, setFutureNow] = useState('now');
    const [desiredRosteredDate, setDesiredRosteredDate] = useState(new Date());
    const [step, setStep] = useState('scheduling');
    const [hasDateBeenSelected, setHasDateBeenSelected] = useState(false);
    const [verifiedTime, setVerifiedTime] = useState();
    const [hasAcknowledgedWarning, setHasAcknowledgedWarning] = useState(false);
    const [transactionData, setTransactionData] = useState();
    const [errors, setErrors] = useState([]);
    const { userToken, refreshUserToken } = useAuthContext();


    const { isOpen, toggle, player, roster, league, leagueSettings } = props;

    const uses_faab = leagueSettings?.settings.uses_faab === '1'
    const continuous_waivers = leagueSettings?.settings.waiver_rule === 'continous';

    const handleDateChange = (selectedDate) => {
        setDesiredRosteredDate(selectedDate);
    }

    const handleAckCheckBox = (e) => {
        setHasAcknowledgedWarning(e.target.checked)
    }

    const closeModal = () => {
        toggle();
        setPlayerToDrop(null);
        setFutureNow('now');
        setDesiredRosteredDate(new Date());
        setHasDateBeenSelected(false);
        setVerifiedTime(null);
        setHasAcknowledgedWarning(false);
        setErrors([]);
        setStep('scheduling')
    }

    const restartProcess = () => {
        setPlayerToDrop(null);
        setFutureNow('now');
        setDesiredRosteredDate(new Date());
        setHasDateBeenSelected(false);
        setVerifiedTime(null);
        setHasAcknowledgedWarning(false);
        setErrors([]);
        setStep('scheduling')
    }

    const clearErrors = () => {
        setErrors([])
    }

    useEffect(() => {
        if (isOpen && hasDateBeenSelected) {
            const confirmSelectedTime = async (desiredRosteredDate) => {
                const result = await confirmTime(desiredRosteredDate);
                if (result.success) {
                    setVerifiedTime(result.data);
                } else {
                    console.error(result.error);
                }
            }

            confirmSelectedTime(desiredRosteredDate);
        }
    }, [hasDateBeenSelected])

    const handleModalSubmit = async () => {
        const args = {
            addPlayerId: player.player_id,
            dropPlayerId: playerToDrop.player_id,
            leagueId: league.league_id,
            teamId: league.team_id,
            earliestAddTimeUtc: desiredRosteredDate
        }
        if (futureNow === 'future') {
            await scheduleAddDrop(args);
        } else if (futureNow === 'now') {
            await executeAddDropAsync(args);
        }

    }

    const scheduleAddDrop = async (args) => {
        const result = await addScheduleAddDropRecord(userToken, refreshUserToken, args)
        if (result.success) {
            setStep('successfully-scheduled')
        } else {
            console.error(result.error)
            const error1 = 'Add/Drop Scheduling Failed';
            const error2 = result.error.response?.data?.message;
            setErrors([error1, error2])
            setStep('failure')
        }
    }

    const executeAddDropAsync = async (args) => {
        const result = await executeAddDrop(userToken, refreshUserToken, args)
        if (result.success) {
            setTransactionData(result.data.league[0].transactions[0].transaction[0]);
            setStep('successfully-executed');
        } else {
            setStep('failure');
            console.error(result.error)
            const error1 = 'Add/Drop Execution Failed';
            const error2 = result.error.response?.data?.message;
            setErrors([error1, error2])
        }
    }


    const willExecuteImmediately = verifiedTime?.timeUntilRequestedDate == 'past';

    const warningText = 'WARNING: This add/drop will execute immediately upon clicking \'Submit\'';
    const textToShow = !willExecuteImmediately ?
        `This add/drop will be executed in ${verifiedTime?.timeUntilRequestedDate}.` :
        warningText;

    const disableSubmitForNotSelectingDate = futureNow === 'future' && !hasDateBeenSelected;
    const disableSubmitForImmediateWithoutAck = futureNow === 'future' && willExecuteImmediately && !hasAcknowledgedWarning;
    const disableForMissingPlayers = !player?.player_id || !playerToDrop?.player_id;

    const disableSubmit = disableSubmitForNotSelectingDate || disableSubmitForImmediateWithoutAck || disableForMissingPlayers;

    const scheduleViewProps = { player, playerToDrop, setPlayerToDrop, roster, uses_faab, setFutureNow, futureNow, warningText, setHasDateBeenSelected, desiredRosteredDate, handleDateChange, verifiedTime, willExecuteImmediately, textToShow, handleAckCheckBox, clearErrors, errors }



    return (
        <Modal centered isOpen={isOpen} toggle={closeModal} size='sm' className="add-drop-modal">
            <ModalHeader toggle={closeModal}>Schedule Add/Drop</ModalHeader>
            {step === 'scheduling' && <SchedulerModalBody {...scheduleViewProps}></SchedulerModalBody>}
            {step === 'successfully-scheduled' && <SuccessfullyScheduledModalBody {...scheduleViewProps}></SuccessfullyScheduledModalBody>}
            {step === 'successfully-executed' && <SuccessfullyExecutedModalBody transactionData={transactionData} {...scheduleViewProps}></SuccessfullyExecutedModalBody>}
            {step === 'failure' && <FailureModalBody {...scheduleViewProps}></FailureModalBody>}
            <ModalFooterWithButtons restart={restartProcess} step={step} closeModal={closeModal} disableSubmit={disableSubmit} handleModalSubmit={handleModalSubmit}></ModalFooterWithButtons>
        </Modal>
    )
}


export default AddDropModal;

const ModalFooterWithButtons = (props) => {

    const { closeModal, disableSubmit, handleModalSubmit, step, restart } = props;

    if (step.startsWith('success')) {
        return (
            <ModalFooter>
                <Button color='success' onClick={closeModal}>Close</Button>
            </ModalFooter>
        )
    }

    if (step === 'failure') {
        return (
            <ModalFooter>
                <Button onClick={restart}>Back</Button>
                <Button color='danger' onClick={closeModal}>Cancel</Button>
            </ModalFooter>
        )
    }

    return (
        <ModalFooter>
            <Button color='danger' onClick={closeModal}>Cancel</Button>
            <Button color='success' disabled={disableSubmit} onClick={handleModalSubmit}>Submit</Button>
        </ModalFooter>
    )
}


const SuccessfullyScheduledModalBody = (props) => {

    return (
        <ModalBody>
             <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value`}>{props.player.name.full}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Add</Row>
                </Col>
            </Row>
            <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value `}>{props.playerToDrop.name.full}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Drop</Row>
                </Col>
            </Row>
            <Row className="text-align-center bottom-border">
                <Col>
                    <Row className={`player-ranking-value `}>{props.verifiedTime.timeUntilRequestedDate}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Will Execute</Row>
                </Col>
            </Row>
            <Row className="spacer-25"></Row>
            <Row className="text-align-center">
                <p>
                    Manage Add/Drops on the <Link to='/add-drop-scheduler' style={{ color: 'blue', textDecoration: 'none' }}><strong>Schedule Page</strong></Link>
                </p>
            </Row>
        </ModalBody>
    )
}

const SuccessfullyExecutedModalBody = (props) => {
    const { transactionData } = props;
    const status = transactionData.status;
    const type = transactionData.type;


    return (
        <ModalBody>

            <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value`}>{props.player.name.full}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Add</Row>
                </Col>
            </Row>
            <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value `}>{props.playerToDrop.name.full}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Drop</Row>
                </Col>
            </Row>
            <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value `}>{type}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Type</Row>
                </Col>
            </Row>
            <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value `}>{status}</Row>
                    <Row className={`player-ranking-label `}>Status</Row>
                </Col>
            </Row>

            <Row className="spacer-25"></Row>
            <Row className="text-align-center">
                <p>
                    If status is 'pending', you can modify this transaction in your Yahoo account.
                </p>
            </Row>
        </ModalBody>
    )
}

const FailureModalBody = (props) => {
    const { clearErrors, errors } = props;
    const displayMessages = errors.map((message, index) => {
        return (<p key={index}>{message}</p>)
    })

    return (
        <ModalBody>
            <Row className="text-align-center add-drop-error">
                {displayMessages}
            </Row>
        </ModalBody>
    )
}


const SchedulerModalBody = (props) => {
    const { player, playerToDrop, setPlayerToDrop, roster, uses_faab, setFutureNow, futureNow, warningText, setHasDateBeenSelected, desiredRosteredDate, handleDateChange, verifiedTime, willExecuteImmediately, textToShow, handleAckCheckBox, clearErrors, errors } = props;

    return (
        <ModalBody>
            <Row className="">
                <Col>
                    <Row className={`player-ranking-value`}>{player.name.full}</Row>
                    <Row className={`player-ranking-label margin-bottom-10`}>Add</Row>
                </Col>
            </Row>
            <Row className="text-align-center">
                <Col>
                    <Row className={`player-ranking-value `}><div className={`${playerToDrop?.name?.full ? '' : 'warn'}`}>{playerToDrop?.name?.full || 'Choose Player'}</div></Row>
                    <Row className={`player-ranking-label`}>Drop</Row>
                </Col>

                <RosterDropdown setSelectedItem={setPlayerToDrop} roster={roster}></RosterDropdown>

            </Row>
            {uses_faab && <><Row className='spacer-25'></Row>
                <Row className="text-align-center">
                    <Col>
                        <FormGroup>
                            <Input
                                disabled
                                id="faab_bid"
                                type="text" />
                            <Label check>
                                Bid Amount (Not Currently Supported)
                            </Label>
                        </FormGroup>
                    </Col>
                </Row></>}
            <Row className='spacer-25'></Row>
            <Row className="text-align-center">
                <Col>
                    Execution Time:
                </Col>
            </Row>
            <Row className="text-align-center">
                <Col>
                    <ButtonGroup size='sm'>
                        <Button onClick={() => setFutureNow('now')} active={futureNow === 'now'} outline color='primary'>Now</Button>
                        <Button onClick={() => setFutureNow('future')} active={futureNow === 'future'} outline color='primary'>Future</Button>
                    </ButtonGroup>

                </Col>
            </Row>
            <Row className="spacer-25"></Row>
            {futureNow === 'now' && playerToDrop && <>
                <Row className='text-align-center'>
                    <p className={"danger"}><strong>
                        {warningText}
                    </strong></p>
                </Row>
            </>}
            {futureNow === 'future' && <>
                <Row className="text-align-center">
                    <Col>
                        <AddDropTimeSelector setHasDateBeenSelected={setHasDateBeenSelected} desiredRosteredDate={desiredRosteredDate} setDesiredRosteredDate={handleDateChange}></AddDropTimeSelector>
                    </Col>
                </Row>
                {verifiedTime && <Row className='text-align-center'>
                    <p className={willExecuteImmediately ? "danger" : ''}><strong>
                        {textToShow}
                    </strong></p>
                </Row>}
                {willExecuteImmediately && <Row className="text-align-center">
                    <Col>
                        <FormGroup check>
                            <Input
                                id="checkbox2"
                                type="checkbox"
                                onClick={handleAckCheckBox} />
                            <Label check>
                                I want to proceed anyway
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>}
            </>}
            <Row className="spacer-25"></Row>
        </ModalBody>
    );
}

import WeatherGame from "./weather-game";


const WeatherGameDay = (props) => {

    if (!props.games?.length > 0) {
        return <div>No Games</div>
    }
    const games = props.games.map((game, index) => {
        return <WeatherGame key={index} game={game}></WeatherGame>
    })

    return(
        <div>
            {games}
        </div>
    )

}

export default WeatherGameDay;
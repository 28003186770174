import { useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export const WeekSelector = (props) => {
    const [isOpen, setIsOpen] = useState();
    const toggle = () => setIsOpen(!isOpen);
    const { weeks, selected_week, set_selected_week } = props;

    const defaultDisplay = `Week ${selected_week}`;

    const dropdownItems = weeks.map((week, index) => {
        const item = <DropdownItem key={index} onClick={() => set_selected_week(week)}>{`Week ${week}`}</DropdownItem>
        return item;
    })

    return (
        <div>
            <Dropdown isOpen={isOpen} toggle={toggle}>
                <DropdownToggle outline color='primary' className='filter-dropdown'>{defaultDisplay}</DropdownToggle>
                <DropdownMenu className='filter-dropdown text-align-center'>
                    {dropdownItems}
                </DropdownMenu>

            </Dropdown>



        </div>
    );
};

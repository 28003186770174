import { Row } from "reactstrap";
import { ResponsiveBarChart } from "../hitter-finder/TrendsView/TrendCharts/ResponsiveBarChart";
import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Customized, Label, LabelList, Line, Rectangle, ReferenceLine, XAxis, YAxis } from "recharts";
import { create_and_merge_trendline_data } from "../hitter-finder/TrendsView/TrendCharts/trendline";
import { useId } from "react";

export const WeeklyStatRankingTrendChart = (props) => {
    const {league_weekly_ranks, team_weekly_ranks, stat_specific_results, subset_selected_key, selectedStat, index} = props;

    if (!league_weekly_ranks) {
        return <div></div>
    }

    // console.log(league_weekly_ranks)
    // console.log(team_weekly_ranks)

    const teamsCount = league_weekly_ranks.length;

    const stat_id = selectedStat?.stat_id || 7;

    const chart_data = [...team_weekly_ranks.map(sr => {
        return {
          week: sr.week,
          value: sr.stats.find(s => s.stat_id == stat_id).rank,
          winLossTie: stat_specific_results.find(w => w.week === sr.week)?.result
        }
    })].reverse();

    const trendlineData = create_and_merge_trendline_data(chart_data, 'value');

    const xAxes = (
        <XAxis dataKey={'week'}>
            <Label offset={-4} position={"insideBottom"}value={'Week'}></Label>
        </XAxis>
    )

    let yAxisTicks = [];

    if (teamsCount <= 8) {
      yAxisTicks = Array.from(Array(teamsCount + 1).keys());
    } else {
      yAxisTicks = Array.from(Array(teamsCount / 2 + 1).keys()).map((value) => value * 2);
    }

    const yAxes = (
        <YAxis ticks={yAxisTicks} orientation="left" interval={0} domain={[0,16]}>
            <Label angle={-90} value={'Rank'}></Label>
        </YAxis>
    )

    const renderWinLossTie = (props) => {
      const { x, y, width, height, value } = props;
      const radius = width / 3;
      const centerX = x + width / 2;
      const cy = 185;

      if (!value) {
        return<></>
      }

      return (
        <g>
          <circle cx={centerX} cy={cy} r={radius} fill="black" />
          <text className='text-size-60 trend-chart-handedness-label-white' x={centerX} y={cy} fill="white" textAnchor="middle" dominantBaseline="middle">
            {value.slice(0, 1)?.toUpperCase() || '?'}
          </text>
        </g>
      );
    }

    const renderLuckIndicator = (props) => {
      const { x, y, width, height, value, index } = props;

      const centerX = x + width / 2;
      const centerY = y - 5;

      const oneThirdOfTeams = (teamsCount / 3);

      const wasInTopThird = chart_data[index].value <= oneThirdOfTeams;
      const wasInBottomThird = chart_data[index].value >= teamsCount - oneThirdOfTeams;

      let emoji;
      if (wasInTopThird && value === 'loss') {
        emoji = "💔"
      } else if (wasInBottomThird && value === 'win') {
        emoji = "🍀"
      }

      return (
        <g>
          <text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="middle" fontSize="13">
            {emoji}
          </text>
        </g>
      );

    }
    const HighlightBackground = (props) => {

      const { activeCoordinate, width, height, highlightLength, data } = props;
      if (!activeCoordinate) {
        return null;
      }
      const {x,y} = activeCoordinate;
      const dataLength = data.length;
      // Calculate the x position and width for the highlight background
      const highlightX = x + (dataLength - highlightLength) * (22) - 10;
      const highlightWidth = highlightLength * (23);
      return (
        <rect x={highlightX} y={y+15} width={highlightWidth} height={.75*height} fill="lightblue" opacity={0.3} />
      );
    };

    const highlightLength = subset_selected_key === 'season' ? chart_data.length : parseFloat(subset_selected_key.slice(1)) + 1

    const customized = <Customized component={<HighlightBackground highlightLength={highlightLength} />} />

    const bars = [
        <Bar key={0} fill='green' shape={<BarGradient></BarGradient>} barSize={20} minPointSize={3} dataKey={'value'} >
          <LabelList dataKey='winLossTie' content={renderWinLossTie}></LabelList>
          <LabelList dataKey='winLossTie' content={renderLuckIndicator}></LabelList>
        </Bar>
    ]

    const lines = [
        <Line dataKey={'value'} stroke='#CCC'></Line>
    ]

    const referenceLines = [
        <ReferenceLine key={0} type='monotone' strokeDasharray={"3 3"} y={1} stroke={'green'}>
            {/* <Label fill={'green'} value={'Best'} position='top'></Label> */}
        </ReferenceLine>,
        <ReferenceLine key={1} type='monotone' strokeDasharray={"3 3"} y={teamsCount} stroke={'red'}>
            {/* <Label fill={'red'} value={'Worst'} position='bottom'></Label> */}
        </ReferenceLine>
    ]

    const trendline = <Line type="monotone" dataKey="trendValue" stroke={'cyan'} dot={false} />

    const setDataToShow = () => console.log('data to show set');
    const dataSetDisplayInfo = {};
    const dataToShow = ''
    const dataSetSelectorProps = { setDataToShow, dataSetDisplayInfo, dataToShow };

    const margin = {
        top: 10,
        right: 0,
        left: -15,
        bottom: 5,
    }

    const CustomGridLine = (props) => {
      const { y, ...rest } = props;
      if (y === props.height) {
        // Skip drawing the top line
        return null;
      }
      return <line {...rest} />;
    };

    const grid =  <CartesianGrid gridLine={<CustomGridLine />} vertical={false} strokeDasharray="3 3" />;

    return (
        <>
        <ResponsiveBarChart
            dataSetSelectorProps={dataSetSelectorProps}
            chart_data={trendlineData.data}
            xAxes={xAxes}
            yAxes={yAxes}
            dataBars={bars}
            margin={margin}
            
            grid={grid}
            // title={<Row className="text-align-center chart-title-one-off"><strong className="text-align-center">{`${selectedStat?.display_name}`}</strong><span className=" text-align-center bold text-size-90">Rank by Week</span></Row>}

            // trendline={trendline}
            referenceLines={referenceLines}
            customized={customized}
            

        >
        </ResponsiveBarChart>
        </>
    );
};

function BarGradient(props) {
    const id = useId();
    const gradientId = `gradient-${id}`;
    const clipPathId = `clipPath-${id}`;

    return (
      <>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="100%">
            <stop offset="10%" stopColor="#EE6677" />
            <stop offset="55%" stopColor="#EECC66" />
            <stop offset="100%" stopColor="#228833" />
          </linearGradient>

          <clipPath id={clipPathId}>
            <Rectangle {...props} />
          </clipPath>
        </defs>

        <rect
          x={props.x}
          width={props.width}
          height={props.background?.height}
          fill={`url(#${gradientId})`}
          y={props.background?.y}
          clipPath={`url(#${clipPathId})`}
        />
      </>
    );
  }
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { remove_leading_zeros } from '../../display-helpers/remove-leading-zeros';


// const green_class = 'green-gradient';
// const red_class = 'red-gradient'
const green_class = 'green';
const red_class = 'red'


export const AggregatePitcherStats = (props) => {
    const [showVsAvg, setShowVsAvg] = useState(false);

    const toggleView = () => setShowVsAvg(!showVsAvg);

    // console.log(props)
    const oppStats = props.oppAggregateStats;

    const combinedEra = oppStats.combinedERA?.toFixed(2) || '--'
    const combinedHRp9 = oppStats.combinedHRper9?.toFixed(2) || '--'
    const combinedBAA = oppStats.combinedBAA?.toFixed(3) || '--'


    const leagueAvgStats = props.leagueWideStats.pitcher.starting_pitcher_stats;
    
    if (!leagueAvgStats) {
        return null;
    }

    const eraColor = oppStats.combinedERA > leagueAvgStats.ERA ? green_class : red_class;
    const combinedHrColor = oppStats.combinedHRper9 > leagueAvgStats['HR/9'] ? green_class : red_class;
    const combinedBaaColor = oppStats.combinedBAA > leagueAvgStats['AVG'] ? green_class : red_class;
    
    const data = {eraColor, combinedEra, combinedHrColor, combinedHRp9, combinedBaaColor, combinedBAA};
    
    return (
        <div>
            <Row className='justify-center'>Opponent's Combined Stats</Row>
            {!showVsAvg && <StatValues {...data} onClick={toggleView}></StatValues>}
            {showVsAvg && <VsAvgValues {...data} onClick={toggleView} leagueAverages={leagueAvgStats}></VsAvgValues>}
        </div>
    );
};


const StatValues = (props) => {
    const {eraColor, combinedEra, combinedHrColor, combinedHRp9, combinedBaaColor, combinedBAA, onClick} = props;
    return (
        <Row onClick={onClick}>
            <Col>
                <Row className={`player-ranking-value ${eraColor}`}>{remove_leading_zeros(combinedEra)}</Row>
                <Row className={`player-ranking-label`}>ERA</Row>
            </Col>
            <Col>
                <Row className={`player-ranking-value ${combinedHrColor}`}>{remove_leading_zeros(combinedHRp9)}</Row>
                <Row className={`player-ranking-label`}>HR/9</Row>
            </Col>
            <Col>
                <Row className={`player-ranking-value ${combinedBaaColor}`}>{remove_leading_zeros(combinedBAA)}</Row>
                <Row className={`player-ranking-label`}>BAA</Row>
            </Col>

        </Row>
    )
}

const VsAvgValues = (props) => {
    const { combinedEra, combinedHRp9, combinedBAA, leagueAverages, onClick} = props;
    const red_threshold = .98;
    const green_threshold = 1.02;
   

    const era = combinedEra / leagueAverages.ERA;
    const eraColor = era > green_threshold ? green_class : era < red_threshold ? red_class : '';

    const hr = combinedHRp9 / leagueAverages['HR/9'];
    const hrColor = hr > green_threshold ? green_class : hr < red_threshold ? red_class : '';

    const baa = combinedBAA / leagueAverages.AVG;
    const baaColor = baa > green_threshold ? green_class : baa < red_threshold ? red_class: '';

    const getDisplayValue = (val) => {
        if (val === 0) {
            return '0';
        }

        if (val === 1) {
            return '1';
        }

        return val.toFixed(2);
    }

    return (
        <Row onClick={onClick}>
            <Col>
                <Row className={`player-ranking-value ${eraColor}`}>{remove_leading_zeros(getDisplayValue(era))}x</Row>
                <Row className={`player-ranking-label`}>ERA</Row>
            </Col>
            <Col>
                <Row className={`player-ranking-value ${hrColor}`}>{remove_leading_zeros(getDisplayValue(hr))}x</Row>
                <Row className={`player-ranking-label`}>HR/9</Row>
            </Col>
            <Col>
                <Row className={`player-ranking-value ${baaColor}`}>{remove_leading_zeros(getDisplayValue(baa))}x</Row>
                <Row className={`player-ranking-label`}>BAA</Row>
            </Col>

        </Row>
    )
}
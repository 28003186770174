import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/auth-context";
import { getMlbTransactions } from "../../api/mlb-transactions";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import MlbTransactionsTable from "./mlb-transactions-table";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import { convertToMMDDYYYY } from "../../dates/date-helper";


const MlbTransactions = (props) => {
    const {user, userToken, refreshUserToken, logout} = useAuthContext();
    const [transactions, setTransactions] = useState();
    const [transactionDate, setTransactionDate] = useState(new Date());

    useEffect(() => {
        if (user) {
            const getTransactions = async (userToken, refreshUserToken) => {
                const formattedDate = convertToMMDDYYYY(transactionDate);
                const result = await getMlbTransactions(userToken, refreshUserToken, formattedDate);
                if (result.success) {
                    setTransactions(result.data);
                } else {
                    handleUnauthorized(result.error, logout)
                    console.error(result)
                }
            }

            getTransactions(userToken, refreshUserToken)
        }
    }, [transactionDate])


    return (
        <div>
        <div>Trannys</div>
        
        <DatePicker selected={transactionDate} onChange={setTransactionDate}></DatePicker>
        <Row>
            <Col sm="7">
                <MlbTransactionsTable transactions={transactions}></MlbTransactionsTable>
            </Col>
            <Col></Col>
        </Row>
        </div>
    )
}

export default MlbTransactions;
import { useState } from "react";
import { Row, Col } from "reactstrap"
import { ParkInfoPopover } from "./ParkInfoPopover";


export const ParkInfo = (props) => {
    const { parkInfo, id } = props;

    let color = '';
    if (parkInfo.ParkFactor < 98) {
        color='red'
    } else if (parkInfo.ParkFactor > 102) {
        color='green'
    }
    
   

   


    return (
        <div>
            <span id={id} className={color}>{parkInfo.TeamAbbr}</span>
            <ParkInfoPopover targetId={id} parkInfo={parkInfo}></ParkInfoPopover>

        </div>
    )
}




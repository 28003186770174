
export const calculate_luckiest_wins = (scoreboard, details, league_settings) => {

    if (league_settings?.length == 0 || scoreboard?.length == 0) {
        return null;
    }

    if (scoreboard.scoreboard[0].status == 'midevent') {
        return null;
    }

    const stat_categories = league_settings.settings.stat_categories;
    const stat_groups = stat_categories.groups.group;
    const stat_group_names = stat_groups.map(x => x.group_name);

    const flatter_stats = [];
    stat_group_names.forEach((group, index) => {
        const stat_group = stat_categories.stats.stat.filter(s => s.group === group);
        flatter_stats.push({ group, results: [] })

        stat_group.forEach((stat, i) => {
            if (stat.is_only_display_stat == 1) {
                return;
            }

            let luckiest_win;
            let unluckiest_loss;
            details.team_records.forEach(team => {
                const stat_of_interest = team.record.find(x => x.stat.stat_id == stat.stat_id);
                const did_win = stat_of_interest.matchup.wins > 0;

                const challenger = { team: team.team, stat: stat_of_interest };

                if (did_win && !luckiest_win) {
                    luckiest_win = challenger;
                    return;
                }

                if (did_win && luckiest_win) {
                    const challenger_value = parseFloat(stat_of_interest.stat.value);
                    const current_luckiest_value = parseFloat(luckiest_win.stat.stat.value)

                    if (stat_of_interest.stat.sort_order == '0') {
                        const is_luckier_than_current_luckiest = challenger_value > current_luckiest_value;
                        if (is_luckier_than_current_luckiest) {
                            luckiest_win = challenger;
                            return;
                        }
                    } else {
                        const is_luckier_than_current_luckiest = challenger_value < current_luckiest_value;
                        if (is_luckier_than_current_luckiest) {
                            luckiest_win = challenger;
                            return;
                        }
                    }
                }


                const did_lose = stat_of_interest.matchup.losses > 0;

                if (did_lose && !unluckiest_loss) {
                    unluckiest_loss = challenger;
                    return;
                }

                if (did_lose && unluckiest_loss) {
                    const challenger_value = parseFloat(stat_of_interest.stat.value);
                    const current_unluckiest_value = parseFloat(unluckiest_loss.stat.stat.value)

                  
                    if (stat_of_interest.stat.sort_order == '0') {
                        const is_luckier_than_current_luckiest = challenger_value < current_unluckiest_value;
                        if (is_luckier_than_current_luckiest) {
                            unluckiest_loss = challenger;
                            return;
                        }
                    } else {
                        const is_luckier_than_current_luckiest = challenger_value > current_unluckiest_value;
                        if (is_luckier_than_current_luckiest) {
                            unluckiest_loss = challenger;
                            return;
                        }
                    }
                }

            })

            const result = { stat, luckiest_win, unluckiest_loss };
            flatter_stats[index].results.push(result)
            
            // console.log(luckiest_win)
            // console.log(unluckiest_loss)
        })


       

    })

    return flatter_stats
}
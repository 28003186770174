import { useEffect, useState } from "react";

import { getSeasonStats } from "../../api/pitcher-stats";
import { Table } from "reactstrap";
import { useAuthContext } from "../../context/auth-context";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";

export const PitcherSeasons = (props) => {
    const [seasons, setSeasons] = useState();
    const {user, userToken, refreshUserToken, logout} = useAuthContext();

    useEffect(() => {
        console.log('Calling useEffect()')
        const getSeasons = async (userToken, refreshUserToken, yahooPlayerId) => {
            const resp = await getSeasonStats(userToken, refreshUserToken, yahooPlayerId);
            if (resp.success) {
                if (resp.data?.stats) {
                    resp.data.stats.reverse();
                }
                setSeasons(resp.data.stats);
            } else {
                handleUnauthorized(resp.error, logout)
                console.error(resp.error)
            }
        }

        getSeasons(userToken, refreshUserToken, props.playerId)
    }, [props.playerId])


    if (!(seasons?.length > 0)) {
        return <div>Seasons</div>
    }

    const getValueBetweenTags = (linkElement) => {
        var regex = />([^<]+)</;
        var match = regex.exec(linkElement);
        if (match && match.length > 1) {
            return match[1];
        }
        return null;
    }
   
    const actualSeasons = seasons.filter(s => s.Season.startsWith('<a href'))

    const sanitized = [];
    for (let i = 0; i < actualSeasons.length; i++) {
        const season = {...actualSeasons[i]};
        if (season.Season.startsWith('<a href')) {
            season.Season = getValueBetweenTags(season.Season)
        }
        if (season.Team.startsWith('<a href')) {
            season.Team = getValueBetweenTags(season.Team)
        }

        sanitized.push(season);
    }

    const seasonsFilter = ["2021", "2022", "2023", "2024", "TotalNot"]
    const seasonsToShow = sanitized.filter(s => seasonsFilter.includes(s.Season))

    let filtered = seasonsToShow;
    if (seasonsToShow.length > 8) {
        filtered = seasonsToShow.filter(x => x.IP > 10 || !x.Team.includes('(')); //(AAA) so this incldues all big league stats regardless of IPs
    }

    const seasonRows = filtered.map((season, index) => {

        return (
            <tr className={"className"} key={index}>
                <td><span className="bold">{season.Season}</span></td>
                <td>{season.Team}</td>
                <td>{season.IP}</td>
                <td>{remove_leading_zeros(season.AVG?.toFixed(3))}</td>
                <td>{season.ERA?.toFixed(2)}</td>
                <td>{season.FIP?.toFixed(2)}</td>
                <td>{season.SO}</td>
                <td>{season['K/9'].toFixed(1)}</td>
                <td>{season['BB/9'].toFixed(1)}</td>

            </tr>
        )
    })

    return (
        <div>
            {/* <div>Splits Selector</div> */}
            <Table size="sm" className="text-size-70">
                <thead>
                    <tr className="border-none">
                        <th>Year</th>
                        <th>Team</th>
                        <th>IP</th>
                        <th>BAA</th>
                        <th>ERA</th>
                        <th>FIP</th>
                        <th>K</th>
                        <th>K/9</th>
                        <th>BB/9</th>

                    </tr>
                </thead>
                <tbody>
                    {seasonRows}
                </tbody>
            </Table>
        </div>
    )
}

export default PitcherSeasons;
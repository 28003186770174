import { Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { MatchupSummaryContainer } from './MatchupSummaryContainer';
import { getPitcherSplitsBatch } from '../../api/pitcher-stats';
import { handleUnauthorized } from '../errors/handle-unauthorized';
import { useAuthContext } from '../../context/auth-context';

export const UpcomingMatchupDetails = (props) => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const { isShowingDetails, hitter, leagueWideStats, upcomingGames } = props;
    const [pitcherSplits, setPitcherSplits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // console.log(props)
    useEffect(() => {
        
        const getPitcherSplitsAsync = async (userToken, refreshUserToken, pitcherNames) => {
            setIsLoading(true);
            const result = await getPitcherSplitsBatch(userToken, refreshUserToken, pitcherNames);
            if (result.success) {
                setPitcherSplits(result.data);
            } else {
                handleUnauthorized(result.error, logout)
                console.error(result.error);
            }
            setIsLoading(false);
        }

        const pitcherNames = props.upcomingGames.map(x => x.opposingPitcher?.Name);

        if (pitcherNames?.length > 0 && isShowingDetails.isShowing && !pitcherSplits.length > 0) {
            getPitcherSplitsAsync(userToken, refreshUserToken, pitcherNames);
        }

        if (isShowingDetails.selectedGameId) {
            const targetElement = document.getElementById(isShowingDetails.selectedGameId);
            if (targetElement) {
                if (targetElement.tabIndex > 0) {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                }
            }
        }

    }, [upcomingGames, isShowingDetails]);




    return (
        <div className='margin-top-5 border-top border-bottom'>

            {isShowingDetails.isShowing && <Row className='width-100'><MatchupSummaryContainer leagueWideStats={leagueWideStats} hitter={hitter} upcomingGames={upcomingGames} pitcherSplits={pitcherSplits} isLoading={isLoading} ></MatchupSummaryContainer></Row>}
        </div>
    );
};

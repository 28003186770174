import axios from 'axios';
import { createErrorResponse, createSuccessResponse } from '../api-response';


let _isInitialized = false;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


const getApi = (userToken, refreshUserToken) => {

  if (!_isInitialized) {
    setupRequestInterceptor(api, userToken);
    setupResponseInterceptor(api, refreshUserToken)
    _isInitialized = true;
  }

  return api;
}


const setupRequestInterceptor = (api, userToken) => {
  console.log('ADDING REQ INTERCEPTOR')
  // Add a request interceptor to attach the token to every request
  api.interceptors.request.use(
    
    (config) => {
      if (userToken) {
        config.headers['x-access-token'] = userToken; // Set the custom header with the token
      }
      return config;
    },
    (error) => {
      return Promise.reject(createErrorResponse(error));
    }
  );
};


const setupResponseInterceptor = (api, refreshUserToken) => {
  console.log('ADDING RESP INTERCEPTOR')
  // Add a response interceptor to handle 401 errors and other responses
  api.interceptors.response.use(
    async (response) => {
      // Handle successful response
      return createSuccessResponse(response.data);
    },
    async (error) => {
      const { response, config } = error;
      const originalRequest = config;

      if (response && response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Flag to prevent multiple retries

        try {
          const newToken = await refreshUserToken(); // Call refreshUserToken function directly
          if (newToken) {
            originalRequest.headers['x-access-token'] = newToken;

            // Retry the original request with the new token
            const retryResponse = await api(originalRequest);
            return createSuccessResponse(retryResponse);
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          return Promise.reject(createErrorResponse(refreshError));
        }
      }
      // If the response is not 401 or retry fails, reject the promise with a custom error response
      return createErrorResponse(error);
    }
  );
};

export default getApi;

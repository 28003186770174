import { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import getFreeAgents from "../api/get-free-agent-starters"
import LoadingIndicator from './loading-indicator';
import CollapsibleGamesContainer from './games/collapsible-games-container'
import ErrorAlert from './errors/error-alert';
import MyTeamsProjectedStarters from './my-teams-projected-starters';
import { addToWatchlistEntry, getWatchedPlayerIds, removeFromWatchlistEntry } from '../api/watchlist';
import { useAuthContext } from '../context/auth-context';
import { handleUnauthorized } from './errors/handle-unauthorized';
import { getThirdPartyMatchupRatings } from '../api/third-party-ratings';
import { UserLeagueDropdown } from './user-leagues/user-league-dropdown';
import { get_user_leagues_async } from './user-leagues/get-user-leagues';
import { NoLeagueConnectedInstructions } from './user-leagues/NoLeagueConnectedInstructions';
import { useLeagueSelector } from '../context/league-selector-context';
import { get_league_settings } from '../api/yahoo-integration-info';
import { getUserCurrentAndFutureRoster } from '../api/roster';

function ProjectedStarters() {
  const [freeAgents, setFreeAgents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userLeagues, setUserLeagues] = useState([]);
  const [error, setError] = useState({});
  const [watchedPlayerKeys, setWatchedPlayerKeys] = useState([]);
  const [thirdPartyRatings, setThirdPartyRatings] = useState([]);
  const { user, userToken, refreshUserToken, logout } = useAuthContext();
  const { selectedLeague, setSelectedLeague } = useLeagueSelector();
  const [yahooLeagueSettings, setYahooLeagueSettings] = useState();
  const [rosters, setRosters] = useState([]);

  useEffect(() => {
    if (user) {
      const showFreeAgents = async (userToken, refreshUserToken, league) => {
        const leagueId = league.league_id;
        setLoading(true);
        setSelectedLeague(league);

        const watchedPlayerKeysResp = await getWatchedPlayerIds( userToken, refreshUserToken, leagueId);
        if (watchedPlayerKeysResp.success) {
          setWatchedPlayerKeys(watchedPlayerKeysResp.data);
        }

        const response = await getFreeAgents(userToken, refreshUserToken, leagueId);
        if (response.success) {
          setFreeAgents(response.data)
        } else {
          console.log(response.error)
          handleUnauthorized(response.error, logout);

          setError("yahoo-authentication")
        }
        setLoading(false)
      }


      if (selectedLeague) {
        showFreeAgents(userToken, refreshUserToken,selectedLeague)
      }

      get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken)

      async function get3rdPartyRatings(userToken, refreshUserToken) {
        const matchupRatingsResponse = await getThirdPartyMatchupRatings(userToken, refreshUserToken)
        if (matchupRatingsResponse.success) {
          setThirdPartyRatings(matchupRatingsResponse.data);
        } else {
          handleUnauthorized(matchupRatingsResponse.error, logout)
          console.log('Something went wrong with 3rd party matchup ratings')
        }
      }

      get3rdPartyRatings(userToken, refreshUserToken);

      const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
        const result = await get_league_settings(userToken, refreshUserToken, leagueId);
        if (result.success) {
            setYahooLeagueSettings(result.data);
        } else {
            handleUnauthorized(result.error, logout);
            console.log(result.error)
        }
    }
    if (userToken && selectedLeague) {
        getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id)
    }

    const getRosterAsync = async (userToken, refreshUserToken, leagueId, teamId) => {
        const resp = await getUserCurrentAndFutureRoster(userToken, refreshUserToken, leagueId, teamId);
        if (resp.success) {
            setRosters(resp.data);
        } else {
            handleUnauthorized(resp.error, logout)
            console.error(resp.error)
        }
    }

    if (userToken && selectedLeague) {
        getRosterAsync(userToken, refreshUserToken, selectedLeague.league_id, selectedLeague.team_id);
    }


    }
  }, [user, selectedLeague]);


  const handleApiError = (error) => {
    setFreeAgents([]);
  }

  const clearErrors = () => {
    setError({})
  }

  const addToWatchList = async (gameId, playerId, leagueId) => {
    console.log(`adding player ${playerId}, game ${gameId}, league ${leagueId} to watchlist`)
    const request = { gameId, playerId, leagueId }
    const resp = await addToWatchlistEntry(userToken, refreshUserToken, request)
    if (resp.success) {
      setWatchedPlayerKeys([...watchedPlayerKeys, { player_id: playerId, game_id: gameId }])
    } else {
      handleUnauthorized(resp.error, logout)
      console.error(resp.error)
    }
  }

  const removeFromWatch = async (gameId, playerId, leagueId) => {
    console.log(`removing player ${playerId}, game ${gameId}, league ${leagueId} from watchlist`)
    //ToDo: For now we're ignoring gameId
    const request = { gameId, playerId, leagueId }
    const resp = await removeFromWatchlistEntry(userToken, refreshUserToken, request)
    if (resp.success) {
      const watchedKeys = resp.data;
      setWatchedPlayerKeys(watchedKeys);
      
    } else {
      handleUnauthorized(resp.error, logout)
      console.error(resp.error)
    }
  }


  const renderLoadingIndicator = () => {
    return <LoadingIndicator></LoadingIndicator>
  }

  const renderShowFreeAgentInstructions = () => {
    return <div className=" text-align-center show-free-agent-instructions"><p>Select a league from the dropdown in order to see some projected starters</p></div>
  }

  const renderYahooAuthenticationFailure = () => {
    const messageLine1 = "There was an issue with your Yahoo account."
    const messageLine2 = "FF likely needs to be reauthorized.";

    const messageLines = [messageLine1, messageLine2];
    return (
      <div className="fit-content">
        <ErrorAlert clearErrors={clearErrors} messages={messageLines}></ErrorAlert>
      </div>
    )
  }

  const renderGenericError = () => {
    return (
      <Row className="generic-error">
        <Col className="fit-content error-message">
          <p>Something went wrong.</p>
          <p>Please try again.</p>
        </Col>
      </Row>
    )
  }

  const renderErrorMessage = (error) => {
    if (error === "yahoo-authentication") {
      return renderYahooAuthenticationFailure()
    } else {
      return renderGenericError();
    }
  }

  const renderFreeAgentProjectedStarters = (playerData) => {
    return (
      <CollapsibleGamesContainer leagueSettings={yahooLeagueSettings} rosters={rosters} playerData={playerData} watchedPlayerKeys={watchedPlayerKeys} title={"Free Agent Scheduled Starters"} league={selectedLeague} leagueId={selectedLeague.league_id} addToWatchList={addToWatchList} removeFromWatch={removeFromWatch} thirdPartyRatings={thirdPartyRatings}></CollapsibleGamesContainer>
    )
  }

  const renderMyTeamsScheduledStarters = () => {
    return <MyTeamsProjectedStarters leagueId={selectedLeague.league_id} userLeagues={userLeagues} setUserLeagues={setUserLeagues}></MyTeamsProjectedStarters>
  }

  const renderFreeAgentsAndRosteredScheduledStarters = () => {
    if (Object.keys(error)?.length > 0) {
      return renderErrorMessage(error);
    }

    return (
      <Col>
        {(!(userLeagues?.length > 0) && !isLoading) ? <NoLeagueConnectedInstructions></NoLeagueConnectedInstructions> : null}
        {(userLeagues?.length > 0 && freeAgents.length === 0 && !isLoading) ? renderShowFreeAgentInstructions() : null}
        {isLoading ? renderLoadingIndicator() : userLeagues?.length > 0 ? renderFreeAgentProjectedStarters(freeAgents) : null}
        {isLoading ? null : <hr></hr>}
        {isLoading ? renderLoadingIndicator() : userLeagues?.length > 0 && selectedLeague.league_id ? renderMyTeamsScheduledStarters() : null}
      </Col>
    )
  }


  return (
    <div>
      <Row className='margin-5'>
        <UserLeagueDropdown userLeagues={userLeagues} onLeagueSelection={setSelectedLeague} selectedLeague={userLeagues.find(x => x.league_id == selectedLeague.league_id)}></UserLeagueDropdown>
      </Row>
      <Row>
        {renderFreeAgentsAndRosteredScheduledStarters()}
      </Row>
    </div>)
}

export default ProjectedStarters;



import { Row, Col } from "reactstrap";
import ConnectedLeagues from "./connected-leagues";
import AddLeagueButton from "../add-league-button";


const ConnectedLeaguesDrawer = (props) => {
    const { userLeagues, deleteLeague, testAuth } = props;

    const renderLeaguesTable = () => {
        return (
            <div>
                <Row className="text-align-center connected-leagues-header"><h4>Connected Leagues</h4></Row>
                <Row>
                    <Col className="text-align-center">
                        {!(userLeagues?.length > 0) && <p>Click + to Connect a League</p>}
                        {userLeagues?.length > 0 && <ConnectedLeagues deleteLeague={deleteLeague} userLeagues={userLeagues}></ConnectedLeagues>}
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div>
             {renderLeaguesTable()}
        </div>
    )
}

export default ConnectedLeaguesDrawer;
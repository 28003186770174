import { ComposedChart, ResponsiveContainer } from "recharts";
import { Row } from "reactstrap";
import { DataSetSelector } from "./DataSetSelector";

export const ResponsiveBarChart = (props) => {
    const { customized, grid, trendline, chart_data, title, barSizeInfo, dataBars, referenceLines, yAxes, xAxes, dataSetSelectorProps, margin, onClick } = props;

    const { setDataToShow, dataSetDisplayInfo, dataToShow } = dataSetSelectorProps;

    return (
        <div className={`trend-chart-container`}>
            {title && <Row className='justify-center'>{title}</Row>}
            <Row className='trend-chart' onClick={onClick}>
                <ResponsiveContainer className=''>
                    <ComposedChart
                        data={chart_data}
                        margin={margin}
                        barGap={barSizeInfo?.barGap || 0}
                    >
                        {customized}
                        {grid}
                        {dataBars}
                        {trendline}
                        {referenceLines}
                        {xAxes}
                        {yAxes}
                    </ComposedChart>
                </ResponsiveContainer>
            </Row>
            <Row>
                <DataSetSelector setDataToShow={setDataToShow} dataSetsDisplayInfo={dataSetDisplayInfo} showing={[dataToShow]}></DataSetSelector>
            </Row>
        </div>
    );
};

import { Col, Row } from 'reactstrap';


export const HitterStatsViewSelector = (props) => {
    const { activeView, onClick } = props;

    return (
        <div>
            <Row className='bottom-border bg-white'>
                <Col
                    className={`bottom-border view-selector ${activeView == 'recent' ? 'active' : ''}`}
                    onClick={() => onClick('recent')}
                >Recent
                </Col>
                <Col
                    className={`bottom-border view-selector ${activeView == 'trends' ? 'active' : ''}`}
                    onClick={() => onClick('trends')}
                >Trends

                </Col>
                <Col
                    className={`bottom-border view-selector ${activeView == 'season' ? 'active' : ''}`}
                    onClick={() => onClick('season')}
                >Season

                </Col>

            </Row>
        </div>
    );
};

import { Row, Col } from "reactstrap";

export const BasicStatsDisplay = (props) => {
    const { stats, leagueWidePitcherStats } = props;
    if (!stats) {
        return <div></div>;
    }

    console.log('ToDo: League Wide Stats show on Click')

    const stats_to_show = props.statsToShow;


    const cols = stats_to_show.map(x => {
        const statValue = stats[x.key];
        const statLabel = x.display;

        let displayValue = statValue;
        if (x.predisplay_func) {
            displayValue = x.predisplay_func(statValue);
        }

        return (
            <Col>
                <Row className='player-ranking-value'>{displayValue}</Row>
                <Row className='player-ranking-label'>{statLabel}</Row>
            </Col>
        );
    });

    return (
        <div>
            <Row className="bg-light-gray">
                <Col></Col>
                {cols}
                <Col></Col>
            </Row>
        </div>
    );
};

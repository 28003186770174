import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap"
import { useLeagueSelector } from "../../context/league-selector-context";
import { useAuthContext } from "../../context/auth-context";
import { getWeekByWeek } from "../../api/league-matchups";
import { get_league_settings } from "../../api/yahoo-integration-info";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { UserLeagueDropdown } from "../user-leagues/user-league-dropdown";
import { WeekSelector } from "./WeekSelector";
import { calculate_weekly_scoreboard_details, get_subset_scoreboard_details } from "../strategy-builder/weekly_scoreboard_details";
import { LeagueLeaders } from "./LeagueLeaders";
import { calculate_upsets } from "./biggest-upset-calculator";
import { calculate_luckiest_wins } from "./lucky-wins-calculator";
import { LuckyReport } from "./LuckyReport";
import { Upsets } from "./Upsets";
import { StandingShakeups } from "./StandingShakeups";
import { LeagueStandings } from "./LeagueStandings";
import { DominantPerformances } from "./DominantPerformances";
import { SeasonLeaders } from "./SeasonLeaders";



export const WeeklySummaries = (props) => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();
    const [selectedWeek, setSelectedWeek] = useState();
    const [userLeagues, setUserLeagues] = useState([]);
    const [yahooLeagueSettings, setYahooLeagueSettings] = useState([]);
    const [weekly_scoreboard, setWeeklyScoreboard] = useState();
    const [weekByWeekStats, setWeekByWeekStats] = useState();

    useEffect(() => {
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);

        if (selectedLeague) {
            const getWeekByWeekAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await getWeekByWeek(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setWeekByWeekStats(result.data.weekly_stats);
                    setWeeklyScoreboard(result.data.weekly_scoreboard);
                    setSelectedWeek(result.data.weekly_scoreboard.slice(-1)[0].week - 1) // default to last week bc its finished
                } else {
                    handleUnauthorized(result.error, logout);
                    console.error(result.error)
                }
            }

            getWeekByWeekAsync(userToken, refreshUserToken, selectedLeague.league_id)

            const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_settings(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setYahooLeagueSettings(result.data);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }

            getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id)
        }
    }, [selectedLeague])

    if (!weekly_scoreboard?.length > 0) {
        return <div><UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown></div>
    }

    const this_weeks_scoreboard = weekly_scoreboard.find(x => x.week == selectedWeek);
    const is_in_progress = this_weeks_scoreboard.scoreboard[0].status == 'midevent';
    const is_playoffs = this_weeks_scoreboard.scoreboard[0].is_playoffs;
    

    const weeks_for_selector = [...weekly_scoreboard].reverse().map(w => w.week)

    if (is_in_progress) {
        return (
        <div className="App fit-better weekly-summary empty">
            <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
            <Row>
                <Col><WeekSelector weeks={weeks_for_selector} selected_week={selectedWeek} set_selected_week={setSelectedWeek}></WeekSelector></Col>
                <Col>
                    
                </Col>
                <Col></Col>
            </Row>
            <Row className="text-align-center">
                <Col className=" weekly-summary-standout">
                    <p>This week is in progress.</p>
                    <p>Summary will be available once the week is complete.</p>
                </Col>
            </Row>
            
        </div>
        )
    }

    if (is_playoffs) {
        return (
        <div className="App fit-better weekly-summary empty">
            <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
            <Row>
                <Col><WeekSelector weeks={weeks_for_selector} selected_week={selectedWeek} set_selected_week={setSelectedWeek}></WeekSelector></Col>
                <Col>
                    
                </Col>
                <Col></Col>
            </Row>
            <Row className="text-align-center">
                <Col className=" weekly-summary-standout">
                    <p>This week is playoffs.</p>
                    <p>Playoff summaries are not available yet.</p>
                </Col>
            </Row>
            
        </div>
        )
    }
    
    

    const scoreboard_details = calculate_weekly_scoreboard_details(weekly_scoreboard, weekByWeekStats);

    const weeks_to_compare = [`W${selectedWeek -2}`,`W${selectedWeek - 1}` ];

    const team_records_for_compared_weeks = [];
    weeks_to_compare.forEach(subsetView => {
        const scoreboard_details_for_subset = get_subset_scoreboard_details(scoreboard_details, subsetView); 

        const team_records = weekByWeekStats.map((weeklyStats, i) => {
            const totals = { matchup: { wins: 0, losses: 0, ties: 0 }, vs_league: { wins: 0, losses: 0, ties: 0 } };
            const this_teams_scoreboard_details_for_subset = scoreboard_details_for_subset.find(t => t.team.team_id == weeklyStats.team.team_id);       
            this_teams_scoreboard_details_for_subset?.record.forEach(rec => {
                totals.matchup.wins += rec.matchup.wins
                totals.matchup.losses += rec.matchup.losses
                totals.matchup.ties += rec.matchup.ties
            })
    
            const matchup_win_percent = (totals.matchup.wins + totals.matchup.losses + totals.matchup.ties) === 0 ? 0 :  
                (totals.matchup.wins + .5 * totals.matchup.ties) / (totals.matchup.wins + totals.matchup.losses +  totals.matchup.ties)
        
            this_teams_scoreboard_details_for_subset?.record.forEach(rec => {
                totals.vs_league.wins += rec.vs_league.wins
                totals.vs_league.losses += rec.vs_league.losses
                totals.vs_league.ties += rec.vs_league.ties
            })
            const vs_league_win_percent = (totals.vs_league.wins + totals.vs_league.losses + totals.vs_league.ties) === 0 ? 0 :  
                (totals.vs_league.wins + .5 * totals.vs_league.ties) / (totals.vs_league.wins + totals.vs_league.losses + totals.vs_league.ties)
    
            return {team: weeklyStats.team, record: totals, matchup_win_percent, vs_league_win_percent}
        })

        team_records_for_compared_weeks.push({subset_key: subsetView, team_records})
    
    })
    
    const this_week = team_records_for_compared_weeks[1];
    const prev_week = team_records_for_compared_weeks[0];

    const this_week_sorted = sort_teams_by_matchup_win_percent(this_week.team_records);
    const prev_week_sorted = sort_teams_by_matchup_win_percent(prev_week.team_records);

    const rank_changes = calculateRankChanges( this_week_sorted, prev_week_sorted);
    
    const biggest_riser = findBestDelta(rank_changes);
    const biggest_faller = findWorstDelta(rank_changes);

    

    const team_starting_ranks = rank_changes.map(x => {
        return {starting_rank: x.prev_rank, team: x.team};
    });

    
    const upsets = calculate_upsets(this_weeks_scoreboard, team_starting_ranks, rank_changes);

    const this_weeks_details = scoreboard_details.find(x => x.week == selectedWeek);
    const lucky_wins = calculate_luckiest_wins(this_weeks_scoreboard, this_weeks_details, yahooLeagueSettings)

    


    return (
        <div className="App fit-better weekly-summary">
            <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
            <Row>
                <Col><WeekSelector weeks={weeks_for_selector} selected_week={selectedWeek} set_selected_week={setSelectedWeek}></WeekSelector></Col>
                <Col>
                    
                </Col>
                <Col></Col>
            </Row>
            <Row className="">
                <StandingShakeups biggest_riser={biggest_riser} biggest_faller={biggest_faller}></StandingShakeups>
            </Row>
            <Row className="spacer-25"></Row>
                <Upsets upsets={upsets}></Upsets>
                
        <Row className="spacer-25"></Row>
                <DominantPerformances week_number={selectedWeek} week_by_week_stats={weekByWeekStats} league_settings={yahooLeagueSettings} scoreboard_details={this_weeks_details}></DominantPerformances>
            <Row className="spacer-25"></Row>
            <Row className="">
                <LeagueLeaders scoreboard={this_week} league_settings={yahooLeagueSettings} week_by_week_stats={weekByWeekStats} week_number={selectedWeek}></LeagueLeaders>
            </Row>
            {/* <Row className="spacer-25"></Row> */}
            <Row className="">
                <LuckyReport lucky_wins={lucky_wins}></LuckyReport>
            </Row>
            {/* <Row className="spacer-25"></Row> */}
            <Row>
                <Col>
                    <SeasonLeaders league_settings={yahooLeagueSettings} week_by_week_stats={weekByWeekStats} week_number={selectedWeek}></SeasonLeaders>
                </Col>
            </Row>
            <Row className="justify-center"> Standings</Row>
            <Row>
                <LeagueStandings team_records={team_records_for_compared_weeks[1].team_records} rank_changes={rank_changes}></LeagueStandings>
            </Row>
        </div>
    )
}

export const sort_teams_by_matchup_win_percent = (teams) => {
    return  teams.sort((a,b) => b.matchup_win_percent - a.matchup_win_percent);
}



function findBestDelta(rankChanges) {
    if (rankChanges.length === 0) {
        return null; 
    }

    return rankChanges.reduce((maxDeltaElement, currentElement) => {
        return (currentElement.delta > maxDeltaElement.delta) ? currentElement : maxDeltaElement;
    });
}

function findWorstDelta(rankChanges) {
    if (rankChanges.length === 0) {
        return null; 
    }

    return rankChanges.reduce((minDeltaElement, currentElement) => {
        return (currentElement.delta < minDeltaElement.delta) ? currentElement : minDeltaElement;
    });
}

function calculateRankChanges(currentStandings, previousStandings) {
    const rankChanges = [];

    const prevRankMap = {};
    previousStandings.forEach((team, index) => {
        prevRankMap[team.team.team_id] = {
            prev_rank: index + 1, // Rankings are 1-based
            prev_record: team.record
        };
    });

    currentStandings.forEach((team, index) => {
        const currentRank = index + 1;
        const prevInfo = prevRankMap[team.team.team_id];
        const prevRank = prevInfo.prev_rank;
        const prevRecord = prevInfo.prev_record;
        const delta = prevRank - currentRank;

        rankChanges.push({
            team: team.team,
            prev_rank: prevRank,
            new_rank: currentRank,
            delta: delta,
            new_record: team.record,
            prev_record: prevRecord
        });
    });

    return rankChanges;
}



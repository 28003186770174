import { useState } from 'react';
import { HitterStatsPerGameView } from './HitterStatsPerGameView';
import { HitterStatsPerSeasonView } from './HitterStatsPerSeasonView';
import { HitterStatsViewSelector } from './HitterStatsViewSelector';
import { LeftRightyGameBreakdown } from './LeftRightyGameBreakdown';
import { HitterLeagueComparisonView } from './HitterLeagueComparisonView';
import { RecentGameInfo } from './RecentGameInfo';
import { HitterStatTrendsView } from './TrendsView/HitterStatTrendsView';



export const HitterStatsMultiView = (props) => {
    const { activeView, onClick } = props;

    return (
        <div>
            <HitterStatsViewSelector activeView={activeView} onClick={onClick} ></HitterStatsViewSelector>
            
            {activeView === 'trends' && <HitterStatTrendsView {...props}></HitterStatTrendsView>}
            {activeView === 'season' && <HitterStatsPerSeasonView {...props}></HitterStatsPerSeasonView> }
            {activeView === 'recent' && <RecentGameInfo {...props}></RecentGameInfo>}
            {/* <LeftRightyGameBreakdown isOpen={isUpcomingGameOpen} setIsOpen={toggleIsUpcomingGameOpen} {...props}></LeftRightyGameBreakdown> */}
        </div>
    );

};



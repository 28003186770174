import { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";


export const UserLeagueDropdown = (props) => {
    const { userLeagues, onLeagueSelection, selectedLeague} = props;
    const [isOpen, setIsOpen] = useState();
    const toggle = () => setIsOpen(!isOpen);


    if (!(userLeagues?.length > 0)) {
        return (
            <Dropdown isOpen={isOpen} toggle={toggle} disabled>
                <DropdownToggle className="filter-dropdown" caret>
                    No Leagues Connected
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem disabled>No leagues available</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }


    const dropdownItems = userLeagues.map((ul, index) => {
        const item = <DropdownItem key={index} onClick={() => onLeagueSelection(ul)}>{ul.team_name} ({ul.league_id})</DropdownItem>
        return item;
    })

    const defaultDisplay = selectedLeague?.league_id ? `${selectedLeague.team_name} (${selectedLeague.league_id})` : 'Select League'

    return (
        <div id='user-league-dropdown z-index-1000' className="margin-5" tabIndex={1}>
        <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle color='success' className='filter-dropdown'>{defaultDisplay}</DropdownToggle>
            <DropdownMenu className='filter-dropdown text-align-center'>
                {dropdownItems}
            </DropdownMenu>

        </Dropdown>
        </div>
    )
}
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';


export const DataSetSelector = (props) => {
    const { dataSetsDisplayInfo, showing, setDataToShow } = props;
    const displayKeys = Object.keys(dataSetsDisplayInfo)
    
    
    const cols = displayKeys.map((x, i) => {
        let className = '';
       
        if (!showing.includes(x) && !dataSetsDisplayInfo[x].permanent) {
            className = ' not-showing ';
        }

        return (

                <Col key={i} className={`chart-legend ${className}`} onClick={() => !x.permanent ? setDataToShow(x) : null}>
                    <FontAwesomeIcon className={'chart-legend-fa'} color={dataSetsDisplayInfo[x].color} icon={faSquare} size='1x'> </FontAwesomeIcon> 
                    {` ${dataSetsDisplayInfo[x].display}`}
                </Col>

        );
    });

    return (
        <Row className='chart-legend-row text-size-80'>
            <Col></Col>
            {cols}
           
        </Row>
    );
};

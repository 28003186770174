import { Row } from 'reactstrap';
import { HitterStatsMultiView } from './HitterStatsMultiView';
import { useState } from 'react';




export const SeasonStatsView = (props) => {
    const [activeView, setActiveView] = useState('recent');
    return (
        <div>
            <Row className=''>
                <HitterStatsMultiView {...props} activeView={activeView} onClick={setActiveView}></HitterStatsMultiView>
            </Row>
        </div>
    );
};

import { Col, Row } from "reactstrap";
import { display_team_record } from "../../display-helpers/stat_value_display";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { LeagueRankingStats } from "../strategy-builder/LeagueRankingStats";
import { useState } from "react";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";

export const DominantPerformances = (props) => {
    const { scoreboard_details, league_settings, week_by_week_stats, week_number } = props;

    const [viewSelected, setViewSelected] = useState('totals');

    if (league_settings?.length == 0 || week_by_week_stats?.length == 0) {
        return null;
    }

    const stat_categories = league_settings.settings.stat_categories;
    const stat_groups = stat_categories.groups.group;
    const stat_group_names = stat_groups.map(x => x.group_name);

    const result = [];
    scoreboard_details.team_records.forEach((team_record, i) => {
        result.push({ team: team_record.team });
        const totals = { matchup: { wins: 0, losses: 0, ties: 0 }, vs_league: { wins: 0, losses: 0, ties: 0 } };
        team_record.record.forEach((stat, j) => {
            // result[i].record.push({matchup: stat.matchup, vs_league: stat.vs_league})
            totals.matchup.wins += stat.matchup.wins
            totals.matchup.losses += stat.matchup.losses
            totals.matchup.ties += stat.matchup.ties

            totals.vs_league.wins += stat.vs_league.wins
            totals.vs_league.losses += stat.vs_league.losses
            totals.vs_league.ties += stat.vs_league.ties
        })

        const matchup_win_percent = (totals.matchup.wins + totals.matchup.losses + totals.matchup.ties) === 0 ? 0 :
            (totals.matchup.wins + .5 * totals.matchup.ties) / (totals.matchup.wins + totals.matchup.losses + totals.matchup.ties)

        const vs_league_win_percent = (totals.vs_league.wins + totals.vs_league.losses + totals.vs_league.ties) === 0 ? 0 :
            (totals.vs_league.wins + .5 * totals.vs_league.ties) / (totals.vs_league.wins + totals.vs_league.losses + totals.vs_league.ties)


        result[i].record = totals;
        result[i].vs_league_win_percent = vs_league_win_percent;
        result[i].matchup_win_percent = matchup_win_percent;


    })

    const most_dominant_performances = sort_teams_by_win_percent_vs_league(result);

    const most_dominant = most_dominant_performances[0];
    if (!most_dominant) {
        return <div>No best</div>
    }

    const week_by_week_stats_for_dominant_team = week_by_week_stats.find(x => x.team.team_key == most_dominant.team.team_key);

    const dominant_team_this_week = week_by_week_stats_for_dominant_team.team_stats_by_week.find(x => x.week == week_number);

    const dominant_team_stats = { week: dominant_team_this_week.week, stats: dominant_team_this_week.weekly_stats };

    const worst_team = most_dominant_performances.slice(-1)[0];

    const week_by_week_stats_for_worst_team = week_by_week_stats.find(x => x.team.team_key == worst_team.team.team_key);

    const worst_team_this_week = week_by_week_stats_for_worst_team.team_stats_by_week.find(x => x.week == week_number);

    const worst_team_stats = { week: worst_team_this_week.week, stats: worst_team_this_week.weekly_stats };

    const toggle_view = () => {
        if (viewSelected === 'rank') {
            setViewSelected('totals');
        } else {
            setViewSelected('rank')
        }

        // if (viewSelected === 'rank') {
        //     setViewSelected('matchup');
        // } else if (viewSelected === 'matchup') {
        //     setViewSelected('vs_league');
        // } else if (viewSelected === 'vs_league') {
        //     setViewSelected('totals')
        // } else {
        //     setViewSelected('rank')
        // }
    }

    const second = most_dominant_performances[1];
    const third = most_dominant_performances[2];

    const second_worst = most_dominant_performances[most_dominant_performances.length - 2];
    const third_worst = most_dominant_performances[most_dominant_performances.length - 3];

    const win_percent_vs_league = remove_leading_zeros(most_dominant.vs_league_win_percent.toFixed(3));

    const most_dominant_display = `${display_team_record(most_dominant.record.vs_league)} (${win_percent_vs_league})`

    return (
        <>
            <Row>
                <Col>
                    <Row><h6>Best Performances ️‍🔥</h6></Row>
                    <Row className="weekly-summary-standout">
                        <Col>
                            <Row className="first-place">
                                <Col className="good-label">
                                🥇{most_dominant.team.name}
                                </Col>
                                <PlayerStatDisplayCol xs='5' className='good-label' size='sm' label='vs League' value={most_dominant_display}></PlayerStatDisplayCol>
                            </Row>
                            <Row className="first-place " onClick={toggle_view}>
                                <Col xs='1'></Col>
                                <Col>
                                    <LeagueRankingStats value_classname='good-label' setSelectedStat={() => null} viewSelected={viewSelected} subset_selected={dominant_team_stats} stat_group_names={stat_group_names} stat_categories={stat_categories} ></LeagueRankingStats>
                                </Col>
                                <Col xs='1'></Col>
                            </Row>
                            <Row className="spacer-15"></Row>
                            <Row className="text-size-70 second-place">
                                <Col>
                                🥈{second.team.name}
                                </Col>
                                <PlayerStatDisplayCol xs='5' size='sm' label='vs League' value={`${display_team_record(second.record.vs_league)} (${remove_leading_zeros(second.vs_league_win_percent.toFixed(3))})`}></PlayerStatDisplayCol>
                            </Row>
                            <Row className="text-size-70 third-place">
                                <Col>
                                🥉{third.team.name}
                                </Col>
                                <PlayerStatDisplayCol xs='5' size='sm' label='vs League' value={`${display_team_record(third.record.vs_league)} (${remove_leading_zeros(third.vs_league_win_percent.toFixed(3))})`}></PlayerStatDisplayCol>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="spacer-25"></Row>
            <Row>
                <Col>
                    <Row><h6>Worst Performances 💩</h6></Row>
                    <Row className="weekly-summary-standout">
                        <Col>
                            <Row className="first-place">
                                <Col className="bad-label">
                                🥇{worst_team.team.name}
                                </Col>
                                <PlayerStatDisplayCol xs='5' className={'bad-label'} size='sm' label='vs League' value={`${display_team_record(worst_team.record.vs_league)} (${remove_leading_zeros(worst_team.vs_league_win_percent.toFixed(3))})`}></PlayerStatDisplayCol>
                            </Row>
                            <Row className="first-place " onClick={toggle_view}>
                                <Col xs='1'></Col>
                                <Col>
                                    <LeagueRankingStats value_classname='bad-label' setSelectedStat={() => null} viewSelected={viewSelected} subset_selected={worst_team_stats} stat_group_names={stat_group_names} stat_categories={stat_categories} ></LeagueRankingStats>
                                </Col>
                                <Col xs='1'></Col>
                            </Row>
                            <Row className="spacer-25"></Row>
                            <Row className="text-size-70 second-place">
                                <Col>
                                🥈{second_worst.team.name}
                                </Col>
                                <PlayerStatDisplayCol xs='5' size='sm' label='vs League' value={`${display_team_record(second_worst.record.vs_league)} (${remove_leading_zeros(second_worst.vs_league_win_percent.toFixed(3))})`}></PlayerStatDisplayCol>
                            </Row>
                            <Row className="text-size-70 third-place">
                                <Col>
                                🥉{third_worst.team.name}
                                </Col>
                                <PlayerStatDisplayCol xs='5' size='sm' label='vs League' value={`${display_team_record(third_worst.record.vs_league)} (${remove_leading_zeros(third_worst.vs_league_win_percent.toFixed(3))})`}></PlayerStatDisplayCol>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )

};

const sort_teams_by_win_percent_vs_league = (teams) => {
    return [...teams].sort((a, b) => b.vs_league_win_percent - a.vs_league_win_percent);
};
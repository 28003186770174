import { Col, Row } from 'reactstrap';
import { ParkInfo } from './ParkInfo';

export const UpcomingGameTable = (props) => {
    const { onGameClick, armOrder, parkFactors, gameTimeWeather, gameDates } = props;
    const gameDatesElements = GetGameDateElements(gameDates);
    const armOrderElements = GetArmOrderElements(armOrder);
    const parks = GetParkFactorElements(parkFactors)
    const weather = GetWeatherElements(gameTimeWeather);

    const combined = GetCombinedElements(gameDatesElements, armOrderElements, parks, weather, onGameClick )

    return (
        <div >
            {/* <Row className='text-align-center'><strong className='text-size-90'>Summary</strong></Row> */}
            <Row className='tiny-week-row flex-nowrap text-align-center text-size-70'>
                {combined}
            </Row>
        </div>
    );
};

function GetGameDateElements(gameDates) {
    return gameDates;
}

function GetArmOrderElements(armOrder) {
    return armOrder;
}


function GetWeatherElements(weather) {
    const weatherIcons = weather.map(w => {
        if (!w?.condition) {
            return ''
        };

        const icon = <img className="weather-condition-icon-small" src={w.condition.icon}></img>
        return icon;
    })
    return weatherIcons;
}


function GetParkFactorElements(parkFactors) {
    return parkFactors.map((pf, index) => {
        if (!pf?.TeamAbbr) {
            return '-'
        }
        const abbr = pf.TeamAbbr;
        const park_factor = pf["Park Factor"];
        const pf_rank = pf["Rk."];
        const HR = pf.HR;
        const HR_Rank = pf.HR_Rank;
        const venue = `${pf.Venue} (${pf.Year})`;
        const element = <ParkInfo id={`${abbr}-${index}`}
            parkInfo={{
                TeamAbbr: abbr,
                ParkFactor: park_factor, PfRank: pf_rank,
                HR,
                HR_Rank,
                venue
            }}>

        </ParkInfo>;
        return element;
    });
}


function GetCombinedElements(gameDatesElements, armOrderElements, parks, weather, onGameClick) {

    const combined = gameDatesElements.map((date, index) => {

        const isOffDay = armOrderElements[index] === 'X';
       
        return (
            <Col className='solid-border-temp tiny-week-box'  onClick={!isOffDay ? () => onGameClick(date) : null}>
                
                <Row className='justify-center'>{armOrderElements[index]}</Row>
                <Row className='justify-center'>{date}</Row>
                <Row className='justify-center'>{parks[index]}</Row>
                <Row className='justify-center'>{weather[index]}</Row>
            </Col>
        )
    })
    return combined;
}
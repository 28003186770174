import { Row } from 'reactstrap';
import { MatchupSummary } from './MatchupSummary';
import { TeamIds } from '../../enums';

export const MatchupSummaryContainer = (props) => {
    const { upcomingGames, pitcherSplits, hitter, leagueWideStats, isLoading } = props;

    const matchupSummaries = upcomingGames.filter(g => !g.emptyGame).map((x, index) => {
        if (x.opposingPitcher) {
            x.opposingPitcher.pitcher_splits = pitcherSplits.find( ps => ps.player_name == x.opposingPitcher.Name);
        }

        let hitterHomeGame = false;
        if (TeamIds[x.gameDetails.homeTeam] == TeamIds[hitter.editorial_team_abbr]) {
            hitterHomeGame = true;
        }
        return (
            <MatchupSummary tabIndex={index} id={x.gameDetails.mlb_com_game_id} className='hitter-finder-matchup-summary' isLoading={isLoading} leagueWideStats={leagueWideStats} parkInfo={x.parkFactor} gameDetails={x.gameDetails} opposingPitcher={x.opposingPitcher} gameDate={x.date} hitter={hitter} hitterHomeGame={hitterHomeGame}></MatchupSummary>
        );

    });


    return (
        <Row className='hitter-finder-matchup-container flex-nowrap horizontal-scroll'>
            {matchupSummaries}

        </Row>
    );
};

import React, { useEffect, useState } from 'react';
import { Row, Table } from 'reactstrap';

const InningSummary = (props) => {
    const {gameData} = props;

    if (!gameData?.allPlays?.length > 0) {
        return <Row></Row>
    }

    const currentPlay = gameData.allPlays.slice(-1)[0];
    const currentInning = currentPlay?.about?.inning;
    const inningHalf = currentPlay?.about?.halfInning;

    // console.log(currentInning)
    const playsThisInning = gameData.allPlays.filter(x => x.about.inning == currentInning && x.about.halfInning == inningHalf);
    // console.log(playsThisInning)

    const descriptionsOfPlays = playsThisInning.map(x => x.result.description);
    // console.log(descriptionsOfPlays)

    const rows = descriptionsOfPlays.map( (x, i) => <tr><td>{i}</td><td>{x}</td></tr>);

    return (
        <Row>
            <Row>Inning Summary</Row>
            <Table size="small">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Desc</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </Row>
    )
}

export default InningSummary;
import { Row } from "reactstrap";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { getEnding } from "../../display-helpers/number-endings";
import { get_display_value } from "../../display-helpers/stat_value_display";

export const LeagueRankingStats = (props) => {

    const { stat_categories, selectedStat, setSelectedStat, stat_group_names, viewSelected, subset_selected, team_record, value_classname } = props;


    const group_rows = stat_group_names.map((group, index) => {

        const stat_group = stat_categories.stats.stat.filter(s => s.group === group);

        const columns = stat_group.map((s, j) => {
            if (s.is_only_display_stat == 1) {
                return null;
            }

            const active_classname = s.stat_id == selectedStat?.stat_id ? ' active-stat ' : ''

            if (viewSelected === 'rank') {
                const display = `${subset_selected.stats.find(x => x.stat_id === s.stat_id).rank}${getEnding(subset_selected.stats.find(x => x.stat_id === s.stat_id).rank)}`;
                return <PlayerStatDisplayCol key={j} className={`${active_classname} ${value_classname}`} onClick={() => setSelectedStat(s)} size='sm' label={s.abbr} value={display}></PlayerStatDisplayCol>;
            } else if (viewSelected === 'totals') {
                const display = get_display_value(subset_selected.stats.find(x => x.stat_id === s.stat_id), subset_selected.stats.find(x => x.stat_id === s.stat_id).value)
                return <PlayerStatDisplayCol  key={j} className={`${active_classname} ${value_classname}`} onClick={() => setSelectedStat(s)} size='sm' label={s.abbr} value={display}></PlayerStatDisplayCol>;
            } else {
                const stat_record = team_record?.record.find(x => x.stat.stat_id === s.stat_id);
                let display = '-';
                if (stat_record) {
                    display = `${stat_record?.[viewSelected].wins}-${stat_record?.[viewSelected].losses}-${stat_record?.[viewSelected].ties}`
                }

                if (viewSelected == 'matchup') {
                    return <PlayerStatDisplayCol key={j} className={`${active_classname} ${value_classname}`} onClick={() => setSelectedStat(s)} size='sm' label={s.abbr} value={display}></PlayerStatDisplayCol>;
                } else {
                    return <PlayerStatDisplayCol key={j} className={`${active_classname} ${value_classname}`} onClick={() => setSelectedStat(s)} size='xs' label={s.abbr} value={display}></PlayerStatDisplayCol>;
                }

            }
        });

        return <Row key={index}>{columns}</Row>;

    });

    return group_rows;
};

import { Row } from "reactstrap";
import { useState } from "react";
import { SplitsTable } from "./SplitsTable";
import { SplitsTableRatios } from "./SplitsTableRatios";

export const OpposingTeamSplitsView = (props) => {
    const { stats, leagueWideStats, logo, pitcherHand } = props;
    const [activeView, setActiveView] = useState('totals');

    const toggleView = () => {
        if (activeView === 'totals') {
            setActiveView('vs-avg');
        } else {
            setActiveView('totals');
        }
    };

    const statsToShow = ['PA', 'AVG', 'K%', 'OPS', 'OBP', 'wOBA'];
    const splitsToShow = ['all', 'vs_l', 'vs_r'];


    const calculate_ratio_vs_league_avg = (statsToShow, splitsToShow, teamStats, leagueWideStats) => {

        const results = {};
        splitsToShow.forEach(splitKey => {
            const team_stats = teamStats[splitKey];
            const league_stats = leagueWideStats.hitter[splitKey].totals[0];

            const sub_results = {};
            statsToShow.forEach(statKey => {
                if (statKey === 'PA') {
                    sub_results[statKey] = (team_stats?.[statKey] || 0); 
                } else {
                    sub_results[statKey] = league_stats[statKey] === 0 ? 0 : (team_stats?.[statKey] || 0) / league_stats[statKey];
                }
            });

            results[splitKey] = sub_results;
        });

        return results;
    };

    const team_stats_vs_league_avg_ratios = calculate_ratio_vs_league_avg(statsToShow, splitsToShow, stats, leagueWideStats);

    const title = <span className="bold">{logo} Splits</span>;
    return (
        <div className="margin-5">
            <Row>
                {title}
            </Row>
            <Row onClick={toggleView}>
                {activeView == 'totals' && <SplitsTable statsToShow={statsToShow} stats={stats} pitcherHand={pitcherHand}></SplitsTable>}
                {activeView == 'vs-avg' && <SplitsTableRatios statsToShow={statsToShow} stats={team_stats_vs_league_avg_ratios} pitcherHand={pitcherHand}></SplitsTableRatios>}
            </Row>
        </div>
    );
};

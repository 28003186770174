import { Table } from "reactstrap";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'



const AddDropPlan = (props) => {
    const renderPlannedAddDrops = (scheduleRecords) => {

        const planned = scheduleRecords?.filter(sr => sr.has_executed === false && sr.attempts === 0);
        let plannedRows = (
            <>
            <tr>
                <td colSpan={4}>No add/drops planned.</td>
            </tr>
            <tr>
                <td colSpan={4}>These can be scheduled from Hitter/Pitcher Forager pages.</td>
            </tr>
            </>
        )
        if (planned?.length > 0) {
            plannedRows = planned?.map(r => {
                const localDateTime = moment.utc(r.earliest_add_time_utc).local().format('dd MM/DD HH:mm A')
                return (
                    <tr>
                        <td>{localDateTime}</td>
                        <td>{r.addPlayerName}</td>
                        <td>{r.dropPlayerName}</td>
                        <td><FontAwesomeIcon className="error" icon={faTrash} size="1x" onClick={() => props.removeFromPlan({ addPlayerId: r.add_player_id, dropPlayerId: r.drop_player_id, leagueId: props.leagueId, teamId: props.teamId })}></FontAwesomeIcon></td>
                    </tr>
                )
            })
        }
       
        return (
            <div className="add-drop-stage solid-border text-align-center">
                <h3>Scheduled</h3>
                <Table size='sm' className='text-size-70'>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Add</th>
                            <th>Drop</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {plannedRows}
                    </tbody>
                </Table>
            </div>
        )
    }

    const renderCompletedAddDrops = (scheduleRecords) => {
        const completed = scheduleRecords?.filter(sr => sr.has_executed === true);
      
        const completedRows = completed?.map(r => {
            const localDateTime = moment.utc(r.earliest_add_time_utc).local().format('dd MM/DD hh:mm A')
            return (
                <tr>
                    <td>{localDateTime}</td>
                    <td>{r.addPlayerName}</td>
                    <td>{r.dropPlayerName}</td>
                    <td></td>
                </tr>
            )
        })

        return (
            <div className="add-drop-stage solid-border text-align-center">
                <h3>Completed</h3>
                <Table size='sm' className='text-size-70'>
                    <thead>
                        <tr>
                            <th>Completed</th>
                            <th>Added</th>
                            <th>Dropped</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {completedRows}
                    </tbody>
                </Table>
            </div>
        )
    }

    const renderFailedAddDrops = (scheduleRecords) => {
        const failed = scheduleRecords?.filter(sr => sr.has_executed == false && sr.attempts > 0);
        if (!failed?.length > 0) {
            return <div></div>
        }
        
        const failedRows = failed?.map(r => {
            const localDateTime = moment.utc(r.last_attempt_utc).local().format('dd MM/DD hh:mm A');
            return (
                <tr>
                    <td>{r.addPlayerName}</td>
                    <td>{r.dropPlayerName}</td>
                    <td>{r.error_description}</td>
                    <td>{localDateTime}</td>
                </tr>
            )
        })

        return (
            <div className="add-drop-stage solid-border text-align-center">
                <h3>Failed</h3>
                <Table size='sm' className='text-size-70'>
                    <thead>
                        <tr>
                            <th>Add</th>
                            <th>Drop</th>
                            <th>Reason</th>
                            <th>Last Attempt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {failedRows}
                    </tbody>
                </Table>
            </div>
        )
    }


    return (
        <div className="add-drop-stage text-align-center">
            {renderPlannedAddDrops(props.scheduleRecords)}
            {renderCompletedAddDrops(props.scheduleRecords)}
            {renderFailedAddDrops(props.scheduleRecords)}

        </div>
    )
}


export default AddDropPlan;
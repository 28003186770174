import { Row } from 'reactstrap'
import { useEffect, useState } from 'react';
import AddDropPlan from './add-drop-plan';
import { useAuthContext } from '../../context/auth-context';
import { getScheduledAddDropRecords, removeScheduleAddDropRecord } from '../../api/schedule-add-drop';
import { UserLeagueDropdown } from '../user-leagues/user-league-dropdown';
import { get_user_leagues_async } from '../user-leagues/get-user-leagues';
import { useLeagueSelector } from '../../context/league-selector-context';

const AddDropScheduler = () => {
    const [addDropScheduleRecords, setAddDropScheduleRecords] = useState();
    const [userLeagues, setUserLeagues] = useState([]);
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();

    useEffect(() => {
        if (user) {
            const getScheduleRecords = async (leagueId, teamId, userToken, refreshUserToken) => {
                const result = await getScheduledAddDropRecords(userToken, refreshUserToken, leagueId, teamId)
                if (result.success) {
                    setAddDropScheduleRecords(result.data);
                } else {
                    console.error(result.error)
                }
            }

            if (selectedLeague?.league_id) {
                getScheduleRecords(selectedLeague.league_id, selectedLeague.team_id, userToken, refreshUserToken);
            }

            get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);
        }

    }, [selectedLeague])

    const removeFromPlan = async (args) => {
        const result = await removeScheduleAddDropRecord(userToken, refreshUserToken, args);
        if (result.success) {
            setAddDropScheduleRecords(result.data);
        } else {
            console.log(result.error)
        }
    }

    return (
        <div className=''>
            <Row>
                <UserLeagueDropdown userLeagues={userLeagues} onLeagueSelection={setSelectedLeague} selectedLeague={selectedLeague}></UserLeagueDropdown>
            </Row>
            <Row>
                <AddDropPlan scheduleRecords={addDropScheduleRecords} removeFromPlan={removeFromPlan} leagueId={selectedLeague.league_id} teamId={selectedLeague.team_id}></AddDropPlan>
            </Row>

        </div >
    )


}

export default AddDropScheduler;
import { Col, Row } from "reactstrap";
import { PlayerStatDisplayCol } from "./PlayerStatDisplayCol";

export const HitterStatsViewRow = (props) => {
    const { statKey, statLabel, seasonStatsPerGame, vsRightyStatsPerGame, vsLeftyStatsPerGame, shouldRound, toFixed, isPercent, isMultiplier, className, calc_className} = props;

    return (
        <Row className={className}>
            <Col xs='1' className={'player-stat-row-label'}>
                {statLabel}
            </Col>
            <PlayerStatDisplayCol calc_className={calc_className} size='sm' label='all' value={seasonStatsPerGame[statKey]} shouldRound={shouldRound} toFixed={toFixed} isPercent={isPercent} isMultiplier={isMultiplier}></PlayerStatDisplayCol>
            <PlayerStatDisplayCol  calc_className={calc_className} size='sm' label='vsRHP' value={vsRightyStatsPerGame[statKey]} shouldRound={shouldRound} toFixed={toFixed} isPercent={isPercent} isMultiplier={isMultiplier}></PlayerStatDisplayCol>
            <PlayerStatDisplayCol  calc_className={calc_className} size='sm' label='vsLHP' value={vsLeftyStatsPerGame[statKey]} shouldRound={shouldRound} toFixed={toFixed} isPercent={isPercent} isMultiplier={isMultiplier}></PlayerStatDisplayCol>
        </Row>
    );

};

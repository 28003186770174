import { Col, Row } from 'reactstrap';


export const ViewSelector = (props) => {
    const { view, onClick } = props;

    return (
        <div>
            <Row className='bg-white'>
                <Col
                    className={`bottom-border view-selector ${view == 'upcoming' ? 'active' : ''}`}
                    onClick={() => onClick('upcoming')}
                >Next Week

                </Col>
                <Col
                    className={`bottom-border view-selector ${view == 'game-log' ? 'active' : ''}`}
                    onClick={() => onClick('game-log')}
                >Game Log

                </Col>
                <Col
                    className={`bottom-border view-selector expandable ${view == 'stats' ? 'active' : ''}`}
                    onClick={() => onClick('stats')}
                >Stats
                </Col>
            </Row>
    </div>
    );
};

import { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export const RosterDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { selectedItem, setSelectedItem, roster } = props;

    const toggle = () => setIsOpen(!isOpen);

    const dropdownItems = roster?.map((player, i) => {
        const eligiblePositions = player.eligible_positions.position.filter(x => x != 'Util' && x != 'P') || [];
        if (eligiblePositions.length === 0) {
            eligiblePositions.push('Util');
        }
    
        const itemDisplay = `${player.name.full} - ${eligiblePositions.join(', ')} `

        return <DropdownItem key={i} onClick={() => setSelectedItem(player)}>{itemDisplay}</DropdownItem>;
    });

    return (
        <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
            <DropdownToggle caret className=''>{'Choose Player To Drop'}</DropdownToggle>
            <DropdownMenu className='roster-dropdown-scrollable'>
                {dropdownItems}
            </DropdownMenu>
        </Dropdown>
    );
};

import getApi from "./axios/api";

export async function getWatchedPlayers(userToken, refreshUserToken, leagueId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/watchlist/${leagueId}`);
    return resp;
}

export async function getWatchedPlayerIds( userToken, refreshUserToken, leagueId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/watchlist/getPlayerIds/${leagueId}`);
    return resp;
}

export async function addToWatchlistEntry(userToken, refreshUserToken, request) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/watchlist/addToWatchlist', request);
    return resp;
}

export async function removeFromWatchlistEntry(userToken, refreshUserToken, request) {
  
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/watchlist/removeFromWatchlist', request);
    return resp;
  
}

// src/components/FirebaseAuthUI.js
import React, { useEffect } from 'react';
import * as firebaseui from 'firebaseui';
import { GoogleAuthProvider, EmailAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';

const FirebaseAuthUI = (props) => {
  useEffect(() => {
    const uiConfig = {
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          signInMethod: 'popup'
        },
        {
          provider: EmailAuthProvider.PROVIDER_ID, 
          requireDisplayName: false,
          buttonColor: 'black',
         
        }
      ],
      signInFlow: 'popup', // Ensure the sign-in flow is popup for Google
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          return false; // Prevents redirect
        },
      }
    };


    // Initialize the FirebaseUI Widget using Firebase.
    const ui = new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', uiConfig);

    // Cleanup the widget on unmount
    return () => {
      ui.delete();
    };
  }, []);

  return <div id="firebaseui-auth-container"></div>;
};

export default FirebaseAuthUI;

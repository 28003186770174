import { Bar,  ComposedChart, LabelList, Line, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useState } from 'react';
import { DataSetSelector } from './DataSetSelector';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { create_and_merge_trendline_data } from './trendline';

export const BasicStatsTrendChartEnhanced = (props) => {
    const initialBarToShow = 'H';
    const [barToShow, setBarToShow] = useState(initialBarToShow);
    


    const toggleData = (dataKey) => {
       setBarToShow(dataKey)
    }

    const onChartClick = () => {
        if (barToShow == 'AB') {
            setBarToShow('H')
        }
        if (barToShow == 'H') {
            setBarToShow('R')
        } else if (barToShow == 'R') {
            setBarToShow('RBI')
        } else if (barToShow == 'RBI') {
            setBarToShow('HR')
        } else if (barToShow == 'HR') {
            setBarToShow('SB')
        } else if (barToShow == 'SB') {
            setBarToShow('H')
        }
    }

    const { leagueWideStats, chart_data, timeframe, setTimeframe } = props;
    const every_game = chart_data.every_game;

    
    const last_10_games = every_game.slice(-10);

    const last_20_games = every_game.slice(-20);

    const last_30_games = every_game.slice(-30);

    const last_x_games = timeframe === 'L10' ? last_10_games : timeframe === 'L20' ? last_20_games : last_30_games;

    const including_attempted_steals = last_x_games.map(x => ({
        ...x,
        ASB: x.SB + x.CS
    }))

    const trendlineData = create_and_merge_trendline_data(including_attempted_steals, barToShow);

    const statTotals = every_game.reduce((acc, stats) => {
        acc.H += stats.H || 0;
        acc.RBI += stats.RBI || 0;
        acc.HR += stats.HR || 0;
        acc.SB += stats.SB || 0;
        acc.R += stats.R || 0;
        acc.AB += stats.AB || 0;
        return acc;
    }, { H: 0, RBI: 0, HR: 0, SB: 0, R: 0, AB: 0 });

    const playerAverages = Object.keys(statTotals).reduce((acc, key) => {
        acc[key] = statTotals[key] / every_game.length;
        return acc;
    }, {});



    const hits_per_gm_avg = leagueWideStats?.hitter?.all?.per_game[0].H;
    const hrs_per_gm_avg = leagueWideStats?.hitter?.all?.per_game[0].HR;
    const rbis_per_gm_avg = leagueWideStats?.hitter?.all?.per_game[0].RBI;
    const runs_per_gm_avg = leagueWideStats?.hitter?.all?.per_game[0].R;
    const sbs_per_gm_avg = leagueWideStats?.hitter?.all?.per_game[0].SB;

    const leagueAverages = {
        H: hits_per_gm_avg,
        HR: hrs_per_gm_avg,
        RBI: rbis_per_gm_avg,
        R: runs_per_gm_avg,
        SB: sbs_per_gm_avg
    };

    const displayInfo = {
        'AB': {color: '#ccc', display: 'AB', permanent: true },
        'H': {color: '#EE6677', display: 'H' },
        'R': {color: '#CCBB44', display: 'R' },
        'RBI': {color: '#228833', display: 'RBI' },
        'HR': {color: '#4477AA', display: 'HR' },
        'SB': {color: '#AA3377', display: 'SB' },
    };


    const player_avg_color = '#33BBEE'

    const leagueAvgReferenceLineDict = {
        H: [
            <ReferenceLine type='monotone' y={hits_per_gm_avg} strokeDasharray="3 3" stroke={'black'}></ReferenceLine>,
            <ReferenceLine type='monotone' y={playerAverages['H']} strokeDasharray="3 3" stroke={player_avg_color}></ReferenceLine>
        ],
        HR: [
            <ReferenceLine type='monotone' y={hrs_per_gm_avg} strokeDasharray="3 3" stroke={'black'}></ReferenceLine>,
            <ReferenceLine type='monotone' y={playerAverages['HR']} strokeDasharray="3 3" stroke={player_avg_color}></ReferenceLine>
        ],
        RBI: [
            <ReferenceLine type='monotone' y={rbis_per_gm_avg} strokeDasharray="3 3" stroke={'black'}></ReferenceLine>,
            <ReferenceLine type='monotone' y={playerAverages['RBI']} strokeDasharray="3 3" stroke={player_avg_color}></ReferenceLine>
        ],
        R: [
            <ReferenceLine type='monotone' y={runs_per_gm_avg} strokeDasharray="3 3" stroke={'black'}></ReferenceLine>,
            <ReferenceLine type='monotone' y={playerAverages['R']} strokeDasharray="3 3" stroke={player_avg_color}></ReferenceLine>
        ],
        SB: [
            <ReferenceLine type='monotone' y={sbs_per_gm_avg} strokeDasharray="3 3" stroke={'black'}> </ReferenceLine>,
            <ReferenceLine type='monotone' y={playerAverages['SB']} strokeDasharray="3 3" stroke={player_avg_color}></ReferenceLine>
        ],
        AB: [
            <ReferenceLine type='monotone' y={sbs_per_gm_avg} strokeDasharray="3 3" stroke={'black'}></ReferenceLine>,
            <ReferenceLine type='monotone' y={playerAverages['AB']} strokeDasharray="3 3" stroke={'BBBBBB'}></ReferenceLine>
        ]
    };
    const league_average_line = leagueAvgReferenceLineDict[barToShow];



    const trendlineInfo= {slope: trendlineData.slope,  intercept: trendlineData.intercept}

    const trendlineColorMap = {
        'H': trendlineInfo.slope > 0 ? 'green' : 'red',
        'HR': trendlineInfo.slope > 0 ? 'green' : 'red',
        'R': trendlineInfo.slope > 0 ? 'green' : 'red',
        'RBI': trendlineInfo.slope > 0 ? 'green' : 'red',
        'SB': trendlineInfo.slope > 0 ? 'green' : 'red',
        'AB': trendlineInfo.slope > 0 ? 'green' : 'red',
    }
    
    // const trendline = <Line type="monotone" dataKey="trendValue" stroke={'#332288'} dot={false} />
    // const trendline = <Line type="monotone" dataKey="trendValue" stroke={displayInfo[barToShow].color} dot={false} />
    const trendline = <Line type="monotone" dataKey="trendValue" stroke={trendlineColorMap[barToShow]} dot={false}></Line>

    const renderCustomXAxisValue = (x) => {
        const dateParts = x.split('-')
        let month = dateParts[1];
        if (month.startsWith('0')) {
            month = month[1];
        }

        let day = dateParts[2];
        if (day.startsWith('0')) {
            day = day[1];
        }

        const formatted = `${month}/${day}`
        return formatted;
    };
    const renderCustomYAxisValue = (y) => {
        return y;
    };

    const basicStatsTrendChartProps = { timeframe, setTimeframe, trendline, onChartClick, title: `${barToShow} / G`, setDataToShow: toggleData, playerAverages, leagueAverages, setDataToShow: toggleData, dataSetDisplayInfo: displayInfo, chart_data: trendlineData.data, dataKey: barToShow, referenceLines: league_average_line, renderCustomXAxisValue, renderCustomYAxisValue };

    
    return (
        <ActualChart {...basicStatsTrendChartProps}></ActualChart>
    )

};

const ActualChart = (props) => {
    const {
        timeframe,
        setTimeframe,
        chart_data,
        dataKey,
        referenceLines,
        renderCustomXAxisValue,
        renderCustomYAxisValue,
        title, 
        dataSetDisplayInfo,
        setDataToShow,
        y_axis_max,
        playerAverages,
        leagueAverages,
        barSize, 
        onChartClick,
        trendline
    } = props;

    const renderGameStartedIndicator = (props, test, test1) => {
        const { x, y, width, height, value } = props;

        if (chart_data.length > 20) {
            return ''
        }

        const cy = 199;

        const radius = 6;
        if (!value) {
            return (
                <g>
                    <circle cx={x + width / 2} cy={cy} stroke={'black'} r={radius} fill="white" />
                </g>
            )
        }

        return (
            <g>
                <circle cx={x + width / 2} cy={cy} r={radius} fill="black" />
            </g>
        );
    };

    const renderRightyLeftySpIndicator = (props) => {
        const { x, y, width, height, value } = props;

        if (chart_data.length > 20) {
            return ''
        }

        const radius = 6;
        if (!value) {
            return null;
        }

        // L-true
        const parts = value.split('-');
        if (!parts.length == 2) {
            return null;
        }

        const didStart = parts[1];
        const handedNess = parts[0];

        if (didStart === 'true') {
            return (
                <g>
                    <text className='text-size-60 trend-chart-handedness-label-white' z-index="100" x={x + width / 2} y={200} textAnchor="middle" dominantBaseline="middle">
                        {handedNess}
                    </text>
                </g>
            )
        }

        return (
            <g>
                <text className='text-size-60 trend-chart-handedness-label-black' z-index="100" x={x + width / 2} y={200} textAnchor="middle" dominantBaseline="middle">
                    {handedNess}
                </text>
            </g>
        );



    };

    const sample_bar_size =  200 / chart_data.length ;
    const ABs_bar = (
        <Bar
            dataKey={'AB'}
            fill={'#ccc'}
            barSize={sample_bar_size}
            minPointSize={3}

        >
        </Bar>
    )

    const ASBs_bar = (
        <Bar
            dataKey={'ASB'}
            fill={'#ccc'}
            barSize={sample_bar_size}
            minPointSize={3}
        >
        </Bar>
    )

    const event_bar_size = (sample_bar_size * .75)
   
    const bar = (
        <Bar
            barSize={event_bar_size}
            dataKey={dataKey}
            fill={dataSetDisplayInfo[dataKey].color}
            minPointSize={(dataKey === 'H' || dataKey === 'SB') ? 0 : 3}
        >
            <LabelList dataKey={'started'} content={renderGameStartedIndicator}></LabelList>
            <LabelList dataKey={'opposing_starter_handedness'} content={renderRightyLeftySpIndicator}></LabelList>
        </Bar>
    )

    const keysForAbsToShow = ["H"];
    const bars = [bar];
    if (keysForAbsToShow.includes(dataKey)) {
        bars.unshift(ABs_bar);
    }

    if (dataKey == 'SB') {
        bars.unshift(ASBs_bar);
    }


    const renderMonthTick = (tickProps) => {
        
        const { x, y, payload, index, visibleTicksCount } = tickProps;
        // console.log(visibleTicksCount)

        const divisor = 10 / visibleTicksCount;
      
        if (index % Math.round(1 / divisor) === 0) {
            
            const { value, offset } = payload;

            const dateParts = value.split('-');
            const day = dateParts[2].startsWith(0) ? dateParts[2][1] : dateParts[2];
            const month = dateParts[1].startsWith(0) ? dateParts[1][1] : dateParts[1];
    
            return <text className='text-size-60' x={x - 9} y={y + 5}  >{month}/{day}</text>;       
        }

        return '';
    }


    const dataMax = dataKey === 'H' ?
        Math.max(...chart_data.map(x => x['AB'])) :
        Math.max(...chart_data.map(x => x[dataKey]));

    const y_ticks = dataMax === 0 ? [0,.25,.5] : Array.from({ length: dataMax + 1 }, (_, i) => i);

    const barGap = -(sample_bar_size + event_bar_size) / 2

    return (
        <div className='trend-chart-container'>
            <Row className='justify-center'><span className='trend-chart-title'>{title}</span></Row>
            <Row onClick={onChartClick} className='trend-chart'>
                <ResponsiveContainer className=''>
                    <ComposedChart
                        barGap={barGap}
                        data={chart_data}
                        margin={{
                            top: 5,
                            right: 3,
                            left: -30,
                            bottom: -10,
                        }}
                    >
                      
                        {bars}
                        {referenceLines}
                        {trendline}
                        <XAxis
                            dataKey={'date'}
                            tick={renderMonthTick}
                            tickLine={false}
                            interval={0}
                           
                        />
                        <YAxis ticks={y_ticks} interval={0} domain={[0, 'dataMax']}></YAxis>

                    </ComposedChart>
                </ResponsiveContainer>
            </Row>
            <Row>
                <DataSetSelector setDataToShow={setDataToShow} dataSetsDisplayInfo={dataSetDisplayInfo} showing={[dataKey]}></DataSetSelector>
            </Row>
            <Row className='text-size-70'>
                <Col></Col>
                <Col>
                    <span style={{ color: '#33BBEE', 'font-size': '130%' }}>---</span> Player Season Avg (<span className='bold'>{playerAverages[dataKey]?.toFixed(2)}</span>)
                </Col>
                <Col>
                    <span style={{ color: 'black', 'font-size': '130%' }}>---</span> League Avg (<span className='bold'>{leagueAverages[dataKey]?.toFixed(2)}</span>)
                </Col>

            </Row>
            <Row>
                <Col></Col>
                <Col className='solid-border-temp'>
                    <svg width="20" height="20">
                        <g>
                            <circle cx={7} cy={10} r={7} fill="black" />
                            <text x={5} y={13} className='text-size-60 trend-chart-handedness-label-white' fill='white'>L</text>
                        </g>
                    </svg>
                    <span className='text-size-70'>Started vs L SP</span>
                </Col>
                <Col className='solid-border-temp'>
                    <svg width="20" height="20">
                        <g>
                            <circle cx={8} cy={10} r={7} fill="white" stroke='black' />
                            <text x={6} y={13} className='text-size-60 trend-chart-handedness-label-black'>L</text>
                        </g>
                    </svg>
                    <span className='text-size-70'>Sat vs L SP</span>
                </Col>
            </Row>
        </div>
    );
}
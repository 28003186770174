import { Button, ButtonGroup } from "reactstrap";

export const SeasonRanksViewSwitcherButtonGroup = (props) => {
    const { toggleViewSelected, viewSelected } = props;

    return (
        <ButtonGroup size='sm' key={viewSelected}>
            <Button color='primary' onClick={toggleViewSelected} active={viewSelected === 'rank'} outline className="player-add-btn ranks-raw-radio-btn"></Button>
            <Button color='primary' onClick={toggleViewSelected} active={viewSelected === 'matchup'} outline className="player-add-btn ranks-raw-radio-btn"></Button>
            <Button color='primary' onClick={toggleViewSelected} active={viewSelected === 'vs_league'} outline className="player-add-btn ranks-raw-radio-btn"></Button>
            <Button color='primary' onClick={toggleViewSelected} active={viewSelected === 'totals'} outline className="player-add-btn ranks-raw-radio-btn"></Button>
        </ButtonGroup>
    );
};

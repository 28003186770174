import { createContext, useContext, useState } from "react";


const LeagueSelectorContext = createContext();

export const LeagueSelectorProvider = ({children}) => {
    const [selectedLeague, setSelectedLeague] = useState('');

    return (
        <LeagueSelectorContext.Provider value={{selectedLeague, setSelectedLeague}}>
            {children}
        </LeagueSelectorContext.Provider>
    )
};

export const useLeagueSelector = () => useContext(LeagueSelectorContext);
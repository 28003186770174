import { Row, Col } from 'reactstrap';
import { remove_leading_zeros } from '../../display-helpers/remove-leading-zeros';

export const PlayerStatDisplayCol = (props) => {
    const { size, value, label, isPercent, shouldRound, toFixed, isMultiplier, calc_className, onClick, className, label_className, xs } = props;
    const displayValue = remove_leading_zeros(!shouldRound ?  value : value === 0 ? 0 : value.toFixed(toFixed));

    const calculated_class_name = typeof calc_className === 'function' ? calc_className(value) : '';
    

    return (
        <Col xs={xs} className={''} onClick={onClick ? onClick : null}>
            <Row className={`player-ranking-value ${size} ${calculated_class_name} ${className}`}>{displayValue}{isPercent ? '%' : ''}{isMultiplier ? 'x' : ''}</Row>
            <Row className={`player-ranking-label ${size} ${label_className}`}>{label}</Row>
        </Col>
    );

};

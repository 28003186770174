import { remove_leading_zeros } from "./remove-leading-zeros";



export function get_display_value(stat, val) {
    let display;
    if (!stat?.abbr) {
        console.warn('dont think you meant to do this')
        console.warn(stat);
        return val;
    }
    const no_op_stats = ['H/AB', 'G', 'IP']
    if (no_op_stats.includes(stat.abbr)) {
        return val;
    }

    const val_float = parseFloat(val);
    if (val_float == 0) {
        return 0;
    }
    
    if (stat.abbr == 'AVG') {
        display = remove_leading_zeros(val_float.toFixed(3));
    } else if (stat.abbr == 'ERA') {
        display = (val_float.toFixed(2));
    } else if (stat.abbr == 'WHIP') {
        display = (val_float.toFixed(2));
    } else {
        display = remove_leading_zeros(Math.round(val_float));
    }
    return display;
}

export function get_display_value_for_avgs(stat, val) {
    let display;
    const no_op_stats = ['H/AB', 'G',]
    if (no_op_stats.includes(stat.abbr)) {
        return val;
    }

    const val_float = parseFloat(val);
    if (val_float == 0) {
        return 0;
    }
    
    if (stat.abbr == 'AVG') {
        display = remove_leading_zeros(val_float.toFixed(3));
    } else if (stat.abbr == 'ERA') {
        display = (val_float.toFixed(2));
    } else if (stat.abbr == 'WHIP') {
        display = (val_float.toFixed(2));
    } else if (stat.abbr == 'IP') {
        display = (val_float.toFixed(1));
    } else if (stat.abbr == 'SV') {
        display = (val_float.toFixed(2));
    } else {
        display = remove_leading_zeros((val_float).toFixed(2));
    }
    return display;
}

export const display_team_record = (record) => {
    return `${record.wins} - ${record.losses} - ${record.ties}`
}
import moment from "moment-timezone";


export const convertTimezone = (timeString, sourceTz, targetTz) => {

  if (!timeString) {
    return 'UNK'
  }
  // let timeString = '2:20 PM';
  // let sourceTz = 'America/New_York';
  // let targetTz = 'Asia/Kolkata';
  // let outputTime = convertTimezone(timeString, sourceTz, targetTz);
  // Parse the input time string using moment
  let time = moment.tz(timeString, 'h:mm A', sourceTz);

  // Convert the time to the target timezone
  let outputTime = time.clone().tz(targetTz).format('h:mm A');

  return outputTime;
}

export const convertTo12Hour = (timeString, includeMinutes) => {
  // Parse the input time string using the Date constructor
  let date = new Date(`2000-01-01T${timeString}:00`);

  // Get the hours and minutes from the parsed date object
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Convert the hours to 12-hour format and determine if it's AM or PM
  let amOrPm = hours < 12 ? 'AM' : 'PM';
  hours = hours % 12 || 12;

  // Format the time string and return it
  let formattedTime = includeMinutes ? `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}` : `${hours} ${amOrPm}`;
  return formattedTime;
}

export const convertToMMDDYYYY = (dateObj) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  };

  const formattedDate = dateObj.toLocaleDateString('en-US', options).replace(/\//g, '-'); // Format the date into MM/DD/YYYY 

  return formattedDate;
}

export const formatDateToMonthSlashDay = (inputDate) => {
 
  const date = moment(inputDate);
  const result = date.format('M/D');

  return result;

}
export const areDatesEqual = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (d1.getTime() === d2.getTime()) {
    return true;
  }
  return false;
}

export const areDatesConsecutive = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Check if the dates are consecutive (d2 is one day after d1 or vice versa)
  const diffInDays = Math.abs((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
  if (diffInDays === 1) {
    return true;
  }

  return false;
}


const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthNums = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

export const getTodayAndFollowingWeek = () => {
  const result = [];
  const today = new Date();

  // Iterate over the next 8 days
  for (let i = 0; i < 8; i++) {
    const nextDate = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
  
    const monthName = months[nextDate.getMonth()];
    const monthNum = monthNums[nextDate.getMonth()];

    let day = nextDate.getDate();
   
    if ((`${day}`).length == 1) {
      day = `0${day}`;
    }
    const year = nextDate.getFullYear();
    // const formattedDate = `${monthName} ${day}, ${year}`;
    const formattedDate = `${year}-${monthNum}-${day}`
    result.push(formattedDate);
  }

  return result;
};

// Function to get the weekday from a date string
export function getWeekday(dateStr) {
  // dateStr = 'June 1'
  
  // Split the date string into month and day
  const [year, month, day] = dateStr.split('-');
  
  // Convert month name to month index (0-11)
  const monthIndex = new Date(`${month} 1`).getMonth();
  
  // Get the current year
  const currentYear = new Date().getFullYear();
  
  // Create a new Date object with the extracted values
  const date = new Date(currentYear, monthIndex, parseInt(day, 10));
  
  // Get the weekday name
  const weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekdayName = weekdayNames[date.getDay()];
  
  return weekdayName;
}

export function formatMonthSpaceDay(dateStr) {
  const [year, month, day] = dateStr.split('-');
  return `${month} ${day}`;
}
import { Row, Col } from 'reactstrap';
import { PlayerStatDisplayCol } from '../hitter-finder/PlayerStatDisplayCol';
import { getEnding } from '../../display-helpers/number-endings';

export const TeamStatsRows = (props) => {
  const { gameInfo } = props;



  const calc_className = () => 'de-emphasized';
  const awayRunsRank = `${gameInfo.awayTeam.runsRank}${getEnding(gameInfo.awayTeam.runsRank)}`;
  const homeRunsRank = `${gameInfo.homeTeam.runsRank}${getEnding(gameInfo.homeTeam.runsRank)}`;
  const awayKsRank = `${gameInfo.awayTeam.strikeoutsRank}${getEnding(gameInfo.awayTeam.strikeoutsRank)}`;
  const homeKsRank = `${gameInfo.homeTeam.strikeoutsRank}${getEnding(gameInfo.homeTeam.strikeoutsRank)}`;

  return (
    <div>
      <Row className='team-info'>
        <Col className='solid-border-temp'></Col>
        <Col xs='2' className='solid-border-temp'>
          {/* <Row className='justify-center text-size-60'>Ranks:</Row> */}
          <Row>
          <PlayerStatDisplayCol size='sm' calc_className={calc_className} value={awayRunsRank} label="R"></PlayerStatDisplayCol>
          <PlayerStatDisplayCol size='sm' calc_className={calc_className} value={awayKsRank} label="K"></PlayerStatDisplayCol>
          </Row>
        </Col>
        <Col className='solid-border-temp'></Col>
        <Col className='solid-border-temp'></Col>
        <Col className='solid-border-temp'></Col>
        <Col className='solid-border-temp'></Col>
        <Col className='solid-border-temp'></Col>
        <Col className='solid-border-temp'></Col>
        <Col xs='2' className='solid-border-temp'>
          <Row>
          <PlayerStatDisplayCol size='sm' calc_className={calc_className} value={homeRunsRank} label="R"></PlayerStatDisplayCol>
          <PlayerStatDisplayCol size='sm' calc_className={calc_className} value={homeKsRank} label="K"></PlayerStatDisplayCol>
          </Row>
        </Col>
        <Col className='solid-border-temp'></Col>
      </Row>
      <Row></Row>
    </div>
  )
}

import { Col, Row } from "reactstrap";
import { HitterStatsViewRow } from "./HitterStatsViewRow";


export const HitterStatsView = (props) => {
    const { stats, splits, platoonInfo, shouldRound, statsToShow, showPlateApps } = props;

    const percentageStartedOverall = ((platoonInfo.playerGamesStartedCount / platoonInfo.teamsTotalGames) * 100).toFixed(0);
    const percentageStartedVsLhp = ((platoonInfo.playerGamesStartedVsLeftyCount / platoonInfo.teamGamesVsLeftyCount) * 100).toFixed(0);
    const percentageStartedVsRhp = ((platoonInfo.playerGamesStartedVsRightyCount / platoonInfo.teamGamesVsRightyCount) * 100).toFixed(0);

    const vsLeftyStatsPerGame = splits.find(x => x.Split == "vs L") || {};
    const vsRightyStatsPerGame = splits.find(x => x.Split == "vs R") || {};
    const seasonStatsPerGame = stats.find(x => x.Season == "2024") || {};

    const dataSet = { vsLeftyStatsPerGame, vsRightyStatsPerGame, seasonStatsPerGame};

    // console.log(statsToShow)
    const rows = statsToShow.map(x => {
        return (
            <HitterStatsViewRow className={'bottom-border'} statKey={x.statKey} statLabel={x.statLabel} shouldRound={shouldRound} toFixed={2} {...dataSet}></HitterStatsViewRow>
        )
})

    return (
        <Row className="bottom-border">
            <Col>
                <HitterStatsViewRow className={'bottom-border'} statKey={'PA'} statLabel={'PA'} shouldRound={false} {...dataSet}></HitterStatsViewRow>
                {rows}
                <HitterStatsViewRow className={'bottom-border'} statKey={'AVG'} statLabel={'AVG'} toFixed={3} shouldRound={true} {...dataSet}></HitterStatsViewRow>
                
            </Col>
        </Row>
    );
};



import getApi from "./axios/api";

export async function setCode(userToken, refreshUserToken, code) {
    const body = { code }
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/yahoo/setCode', body);
    return resp;
}

export async function check_yahoo_auth(token, refreshUserToken) {
    const api = getApi(token, refreshUserToken)
    const resp = api.get('/yahoo/test-yahoo-auth');
    return resp;
}

export async function get_teams(token, refreshUserToken) {
        const api = getApi(token, refreshUserToken)
        const resp = api.get('/yahoo/get-teams');
        return resp;
}

export async function disconnect_yahoo(token, refreshUserToken) {
    const api = getApi(token, refreshUserToken)
    const resp = api.post('/yahoo/disconnect', {});
    return resp;
}

export async function get_league_settings(token, refreshUserToken, leagueId) {
    const api = getApi(token, refreshUserToken)
    const resp = api.get(`/yahoo/leagueSettings/${leagueId}`);
    return resp;
}
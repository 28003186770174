import { PitcherSplitsTable } from "../watchlist/PitcherSplitsTable";

export const PitcherSplitsView = (props) => {
    const { splits, className, splitsToShowOnLoad, bordersToRemove, hitterHand, hitterHomeGame, title } = props;

    if (splits?.length > 0) {
        return (

            <div className='solid-border-temp margin-5'>
                <PitcherSplitsTable title={title} hitterHand={hitterHand} hitterHomeGame={hitterHomeGame} className={className} showTitleRow splits={splits} splitsToShowOnLoad={splitsToShowOnLoad} bordersToRemove={bordersToRemove}></PitcherSplitsTable>
            </div>
        );
    
    } else {
        <div className='solid-border-temp margin-5'>No Splits Available...</div>
    }
   
};

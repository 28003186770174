import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth-context";
import ConnectedLeaguesDrawer from "../user-leagues/connected-leagues-drawer";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { createUserLeagueWithTeam, deleteUserLeague } from "../../api/user-leagues";
import { LeagueTypes } from "../../enums";
import { Button, Col, Row, Table } from "reactstrap";
import { check_yahoo_auth, disconnect_yahoo, get_teams, setCode} from "../../api/yahoo-integration-info";
import { Link, useSearchParams } from "react-router-dom";
import { YahooAuthorizer } from "./YahooAuthorizer";
import LoadingIndicator from "../loading-indicator";
import ErrorAlert from "../errors/error-alert";

const LandingPage = (props) => {
    const { user, logout, userToken, refreshUserToken } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [userLeagues, setUserLeagues] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasAuthedYahoo, setHasAuthedYahoo] = useState();
    const [userYahooTeams, setUserYahooTeams] = useState();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setErrors([]);
        const hasCodeInUrl = searchParams.has("code");
        async function handleUrlCode(hasCodeInUrl) {
            if (hasCodeInUrl) {
                console.log('There is a code in the URL, lets save it...')
                const code = searchParams.get('code');
                const resp = await setCode(userToken, refreshUserToken, code)
                if (resp.success) {

                } else {
                    handleUnauthorized(resp.error, logout)
                    //ToDo Handle Error
                }

                setSearchParams(new URLSearchParams())
            }
        }

        handleUrlCode(hasCodeInUrl);
        if (hasCodeInUrl) {
            return;
        }

        
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);

        const checkHasAuthorizedYahooAsync = async (user) => {
            setIsLoading(true)
            const result = await check_yahoo_auth(userToken, refreshUserToken);
            if (result.success) {
                setHasAuthedYahoo(result.data.hasAuthedYahoo)
            } else {
                handleUnauthorized(result.error, logout)
                console.error(result.error);
            }
            setIsLoading(false)

        }

        if (!hasAuthedYahoo) {
            checkHasAuthorizedYahooAsync(user);
        }

        if (hasAuthedYahoo) {
            
            const getYahooTeamsAsync = async (user) => {
                setIsLoading(true)
                const result = await get_teams(userToken, refreshUserToken);
                if (result.success) {
                    setUserYahooTeams(result.data);
                } else {
                    console.error(result.error.response.data)
                    const errorMessages = ['Something went wrong getting your teams from Yahoo.', 'Contact Support.', result.error?.response?.data?.message || 'Contact Support']
                    setErrors(errorMessages);
                }
                setIsLoading(false)
            }

            getYahooTeamsAsync(user);

        }
       

    }, [searchParams, hasAuthedYahoo])

    const clearErrors = () => {
        setErrors([]);
    }

    const createLeague = async (leagueId, teamId, teamName) => {
        console.log('Creating League...')
        const createLeagueResponse = await createUserLeagueWithTeam(userToken, refreshUserToken, leagueId, LeagueTypes.YAHOO, teamId, teamName);

        if (createLeagueResponse.success) {
            setUserLeagues(createLeagueResponse.data);

        } else {
            handleUnauthorized(createLeagueResponse.error, logout)
            // ToDo: handle error
            console.log(`Something went wrong creating League`)
        }
    }

    const deleteLeague = async (leagueId) => {
        console.log(`Deleting league with id: ${leagueId}`);
        const response = await deleteUserLeague(userToken, refreshUserToken, leagueId);
        if (response.success) {
            setUserLeagues(response.data);
        } else {
            handleUnauthorized(response.error, logout);
            // ToDo: handle error
            console.error(response.error)
        }
    }

    const disconnectYahoo = async () => {
        const resp = await disconnect_yahoo();
        if (resp.success) {
            setHasAuthedYahoo(false);
        } else {
            handleUnauthorized(resp.error, logout);
            // ToDo: handle error
            console.error(resp.error)
        }
    }

    if (isLoading) {
        return  <LoadingIndicator></LoadingIndicator>
    }

    return (
        <div className='App fit-better'>
            <Row className="empty-row">
                
            </Row>
            <Row><ErrorAlert clearErrors={clearErrors} messages={errors}></ErrorAlert></Row>
            {!hasAuthedYahoo && !isLoading && 
                <Row>
                    <Col className="margin-5">
                        <YahooAuthExplanation></YahooAuthExplanation>
                    </Col>
                </Row>}

            {userYahooTeams?.length > 0 &&  
                <Row className="text-align-center">
                    <Col>
                        <h5>Available To Connect</h5>
                        <ConnectableTeamsTable createLeague={createLeague} teams={userYahooTeams}></ConnectableTeamsTable>
                    </Col>

                </Row>}

            {hasAuthedYahoo && userYahooTeams?.length === 0 && 
                <Row className="text-align-center">
                    <Col>
                    <p>There are no fantasy baseball teams associated to your Yahoo account.</p>
                    <p>Is is possible you connected a Yahoo Account other than the one associated to your fantasy league?</p>
                    <p>Disconnect to try again.</p>
                    <Button color='warning' onClick={disconnectYahoo}>Disconnect Yahoo</Button>
                    <p></p>
                    <Row>
                        <strong>Note:</strong>
                        <p>If this browser is signed into Yahoo, it will auto-connect the signed-in acct.</p>
                        <p>You may need to go sign out of Yahoo before re-connecting.</p></Row>
                    </Col>

                </Row>
            }

            {hasAuthedYahoo && userYahooTeams?.length > 0 &&
                <Row>
                    <Col className="">
                        <ConnectedLeaguesDrawer
                            userLeagues={userLeagues}
                            deleteLeague={deleteLeague}
                        />
                    </Col>
                </Row>}

            {hasAuthedYahoo && userLeagues?.length > 0 && 
                <Row>
                    <Col className="text-align-center">
                        <p>You're all set!</p>
                        <p>Check out <Link to='/hitter-finder' style={{ color: 'blue', textDecoration: 'none' }}><strong>Hitter Forager</strong></Link> to find the hottest hitters available in your league.</p>
                        <p>Looking for juicy pitching matchups? <Link to='/pitcher-finder' style={{ color: 'blue', textDecoration: 'none' }}><strong>Pitcher Forager</strong></Link> has what you need.</p>
                    </Col>
                </Row>}

        </div>
    )
}


export default LandingPage;

const YahooAuthExplanation = (props) => {
    return (
        <div className='text-align-center'>
            <p>To import your teams, Fantasy Forager needs permission to interact with Yahoo Fantasy Sports on your behalf</p>
            <p>Click 'Authorize Forager' to continue.</p>
            <YahooAuthorizer></YahooAuthorizer>
        </div>
    )
}

const ConnectableTeamsTable = (props) => {
    const { teams, createLeague } = props;

    const teamRows = teams.map(t => {
        return (
            <tr>
                <td>{t.league_id}</td>
                <td>{t.team_name}</td>
                <td><FontAwesomeIcon color='#3B71CA' icon={faPlus} size="2x" onClick={() => createLeague(t.league_id, t.team_id, t.team_name)}></FontAwesomeIcon></td>
            </tr>
        )
    })

    return (
        <Table size="sm" striped bordered className="text-size-70">
            <thead>
                <tr>
                    <th>League</th>
                    <th>Team</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {teamRows}
            </tbody>
        </Table>
    )
}
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { BasicStatsTrendChartEnhanced } from './TrendCharts/BasicStatsTrendChartEnhanced';
import { AdvancedPercentageStatsTrendChart } from './TrendCharts/AdvancedPercentageStatsTrendChart';
import { useState } from 'react';


export const HitterStatTrendsView = (props) => {
    const [timeframe, setTimeframe] = useState('L10')
    
    const chart_data = props.hitter.chart_data;

    const basicStatsProps = { chart_data, leagueWideStats: props.leagueWideStats, timeframe };

    return (
        <div>
            <div className='solid-border-temp'>
                <Row className='justify-center trends-view-title'>
                    <Col>
                        <ButtonGroup className='margin-5' size='sm'>
                            <Button outline onClick={() => setTimeframe('L10')} active={timeframe === 'L10'}>Last 10</Button>
                            <Button outline onClick={() => setTimeframe('L20')} active={timeframe === 'L20'}>Last 20</Button>
                            <Button outline onClick={() => setTimeframe('L30')} active={timeframe === 'L30'}>Last 30</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className='trend-charts-row horizontal-scroll flex-nowrap'>
                <Col className='solid-border-temp'>
                        <BasicStatsTrendChartEnhanced {...basicStatsProps}></BasicStatsTrendChartEnhanced>
                    </Col>
                </Row>
            </div>
            <div className='solid-border-temp'>
                <Row className='trend-charts-row horizontal-scroll flex-nowrap'>
                    <Col className='solid-border-temp'>
                        <AdvancedPercentageStatsTrendChart {...basicStatsProps}></AdvancedPercentageStatsTrendChart>
                    </Col>
                </Row>
            </div>
        </div >
    );
};





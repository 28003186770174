import { Col, Row } from "reactstrap";
import { display_team_record } from "../../display-helpers/stat_value_display";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";

export const Upsets = (props) => {
    const { upsets } = props;

    if (!(upsets?.length > 0)) {
        return null;
    }


    upsets.sort((a, b) => b.upset_magnitude - a.upset_magnitude);

    const biggest_upset = upsets[0];
    // biggest upset: adf onCLick: show all upsets
    return (
        <Row>
            <Col>
                <Row><h6>Biggest Upset 👀</h6></Row>
                <Row className="weekly-summary-standout">
                    <Col className="justify-center">
                        <Row>
                            <PlayerStatDisplayCol className={'good-label'} size='sm' label='winner' value={`#${biggest_upset.winning_team.starting_rank} ${biggest_upset.winning_team.name}`}></PlayerStatDisplayCol>
                        </Row>
                        <Row className="justify-center text-align-center">
                            {/* <PlayerStatDisplayCol size='sm' label='result' value=  {display_team_record(biggest_upset.matchup_result)}></PlayerStatDisplayCol>   */}
                            <Col> {display_team_record(biggest_upset.matchup_result)}</Col>
                        </Row>
                        <Row>
                            <PlayerStatDisplayCol className={'bad-label'} size='sm' label='loser' value={`#${biggest_upset.losing_team.starting_rank} ${biggest_upset.losing_team.name}`}></PlayerStatDisplayCol>
                        </Row>
                    </Col>
                </Row>
            </Col>

        </Row>
    );
};

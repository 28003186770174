export const getEnding = (rank) => {
    const rankStr = `${rank}`;
    if (rankStr.endsWith('1') && rankStr != '11') {
      return "st";
    } else if (rankStr.endsWith('2') && rankStr != '12') {
      return "nd";
    } else if (rankStr.endsWith('3') && rankStr != '13') {
      return "rd";
    } else {
      return "th";
    }
  };


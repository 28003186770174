import { useState } from 'react';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { Label } from 'recharts';

export const PlayerNameSearchBar = (props) => {
    const {inputValue, setInputValue} = props;
    const [isValid, setIsValid] = useState(true);
    const [isTouched, setIsTouched] = useState(false);

    const submitForm = props.onSubmit;
  
    const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(value);
      setIsTouched(true); // Set isTouched to true on every change
  
      // Validate input length only if the input has been touched
      if (isTouched) {
        validateInput(value);
      }
    };
  
    const validateInput = (value) => {
      if (value.trim().length >= 3) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };
  
    const handleBlur = () => {
      setIsTouched(true);
      validateInput(inputValue);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if (isValid) {
        // Submit form or perform desired action
        submitForm(inputValue.trim());
      } else {
        console.log('Input is invalid');
      }
    };
  

    return (
        <form onSubmit={handleSubmit}>
            <Row className='player-name-search-bar'>
                {/* 
            <Col xs='9'><Input></Input></Col>
            <Col><Button size='sm' color='primary'>Search</Button></Col> */}


                <Col xs='9'>
                    <FormGroup>
                        <Label for='validationInput'>Search Player By Name</Label>
                        <Input
                            type="text"
                            id="validationInput"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            valid={isTouched && isValid}
                            invalid={isTouched && !isValid}
                        ></Input>
                    </FormGroup>
                </Col>
                <Col>
                    <Button size='sm' color='primary'>Search</Button>
                </Col>
            </Row>
        </form>
    );
};

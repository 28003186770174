import { Table } from "reactstrap"



const MlbTransactionsTable = (props) => {
    const {transactions} = props;
    const rows = transactions?.map(t => {
        const playerName = extractPlayerName(t.description);
        return (
            <tr>
                <td>{t.date}</td>
                <td>{t.status}</td>
                <td><a href={t.playerLink}>{playerName}</a></td>
                <td>{`${t.available}`}</td>
                <td>{t.description}</td>
            </tr>
        )
    })

    return (
        <Table size="sm">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Action</th>
                    <th>Player</th>
                    <th>Available</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    )
}

function extractPlayerName(str) {
    const match = str.match(/[A-Z]{2,3}\s+(\w+\s+\w+)/);
    if (match && match[1]) {
      return match[1];
    }
    return null; // Return null if no match is found
  }

export default MlbTransactionsTable;


export const get_subset_scoreboard_details = (scoreboard_details, subset) => {
    if (!scoreboard_details) {
        return [];
    }
    // desired output: [team, record: vsLeague/vsMatchup: [{stat, w,l,t}] ]

    const numOfWeeks = subset === 'season' ? scoreboard_details.length : parseFloat(subset.slice(1));
    const sorted_scoreboard_details = subset.startsWith('L') ? [...scoreboard_details].reverse() : [...scoreboard_details];
    const result = [];

    // Loop through each week up to numWeeks
    for (let weekIndex = 0; weekIndex <= numOfWeeks; weekIndex++) {
        const weekData = sorted_scoreboard_details[weekIndex];
        if (!weekData) continue; // Skip if there's no data for the week

        weekData.team_records.forEach(teamRecord => {
            const teamId = teamRecord.team.team_id;

            // Find or create the team's result in the final array
            let teamResult = result.find(r => r.team.team_id === teamId);
            if (!teamResult) {
                teamResult = { team: teamRecord.team, record: [] };
                result.push(teamResult);
            }

            // Sum the records
            teamRecord.record.forEach(statRecord => {
                const statId = statRecord.stat.stat_id;
                let statResult = teamResult.record.find(r => r.stat.stat_id === statId);
                if (!statResult) {
                    statResult = {
                        stat: statRecord.stat,
                        matchup: { wins: 0, losses: 0, ties: 0 },
                        vs_league: { wins: 0, losses: 0, ties: 0 }
                    };
                    teamResult.record.push(statResult);
                }

                // Sum matchup records
                statResult.matchup.wins += statRecord.matchup.wins;
                statResult.matchup.losses += statRecord.matchup.losses;
                statResult.matchup.ties += statRecord.matchup.ties;

                // Sum vs_league records
                statResult.vs_league.wins += statRecord.vs_league.wins;
                statResult.vs_league.losses += statRecord.vs_league.losses;
                statResult.vs_league.ties += statRecord.vs_league.ties;
            });
        });
    }

    return result;
}



export const calculate_weekly_scoreboard_details = (weekly_scores, entire_league_week_by_week_stats) => {
    const get_stat_we_care_about = (team, weekNumber, stat_id) => {
        const team_stats = team.team_stats_by_week;
        const this_weeks_stats = team_stats.find(w => w.week == weekNumber);
        const stat_of_interest = this_weeks_stats.weekly_stats.find(s => s.stat_id == stat_id);
        return stat_of_interest;
    }

    const get_stat_value_for_stat_we_care_about = (team, weekNumber, stat_id) => {
        const stat_of_interest = get_stat_we_care_about(team, weekNumber, stat_id);
        const stat_value = stat_of_interest?.value || 0; // TODO THIS WILL THROW OFF THE AVGS
        if (typeof stat_value === 'object' && Object.keys(stat_value).length === 0) {
            return 0;
        }
        return parseFloat(stat_value);
    }

    const results = [];
    if (!weekly_scores || !entire_league_week_by_week_stats) {
        return results;
    }
    weekly_scores.forEach((weekly_scoreboard, i) => {
        const week = weekly_scoreboard.week;
        const scoreboard = weekly_scoreboard.scoreboard;

        const team_weekly_win_losses = [];
        scoreboard.forEach((matchup, j) => {
            if (matchup.is_playoffs) {
                return;
            }
            matchup.teams.forEach((team, k) => {
                const my_team = entire_league_week_by_week_stats.find(x => x.team.team_id == team.team_id);
                const everyone_else = entire_league_week_by_week_stats.filter(x => x.team.team_id != team.team_id);

                const weekly_win_losses = []
                matchup.stat_winners.forEach((stat, h) => {
                    let matchup_result = { wins: 0, losses: 0, ties: 0 }
                    if (stat.is_tied == 1) {
                        matchup_result.ties += 1;
                    } else {
                        const winner_key_parts = stat.winner_team_key.split('.');
                        const winner_team_id = winner_key_parts.splice(-1)[0];
                        if (winner_team_id == team.team_id) {
                            matchup_result.wins += 1;
                        } else {
                            matchup_result.losses += 1
                        }
                    }


                    let vs_league_result = { wins: 0, losses: 0, ties: 0 }

                    const {value, ...rest} = my_team.team_stats_by_week.find(x => x.week == matchup.week).weekly_stats.find(s => s.stat_id == stat.stat_id);
                    const stat_info = my_team.team_stats_by_week.find(x => x.week == matchup.week).weekly_stats.find(s => s.stat_id == stat.stat_id);;

                    everyone_else.forEach(other_team => {

                        const my_teams_stat_value = get_stat_value_for_stat_we_care_about(my_team, week, stat.stat_id);
                        const other_teams_stat_value = get_stat_value_for_stat_we_care_about(other_team, week, stat.stat_id);

                        const wasTie = my_teams_stat_value == other_teams_stat_value;
                        
                        if (wasTie) {
                            vs_league_result.ties += 1;
                        } else {
                            if (stat_info.sort_order == '0') {
                                const teamWon = my_teams_stat_value < other_teams_stat_value;
                                if (teamWon) {
                                    vs_league_result.wins += 1;
                                } else {
                                    vs_league_result.losses += 1;
                                }

                            } else {
                                const teamWon = my_teams_stat_value > other_teams_stat_value;
                                if (teamWon) {
                                    vs_league_result.wins += 1;
                                } else {
                                    vs_league_result.losses += 1;
                                }
                            }
                        }
                    })

                    weekly_win_losses.push({ stat: stat_info, matchup: matchup_result, vs_league: vs_league_result });
                })

                team_weekly_win_losses.push({team, record: weekly_win_losses})
                
            })
            
        })
     

        results.push({week, team_records: team_weekly_win_losses})
    })

    return results;
}

import { Button, Col, Row } from 'reactstrap';
import { formatDateToMonthSlashDay } from '../../dates/date-helper';


export const PlayerInfoHeader = (props) => {
    const { player, openAddDrop } = props;
    if (!player) {
        return <div></div>
    }

    return (
        <>
            <Row className=''>
                <Col className='player-info'>
                    <PlayerInfo player={player}></PlayerInfo>
                </Col>
                <Col onClick={openAddDrop} className="text-align-center">

                    <img className="player-info-headshot" src={player.headshot?.url}></img>


                </Col>
            </Row>
            <Row className='bottom-border text-align-center'>
                <Col className='player-info'></Col>
                <Col onClick={openAddDrop} className='text-size-70'><Button color={'primary'} size='sm' className="player-add-btn py-0" >Add</Button></Col>

            </Row>
        </>
    )
}


const PlayerInfo = (props) => {
    const { player } = props;

    const bats = player.handedness.batting_side || '?';
    const throws = player.handedness.throwing_hand || '?';
    const throwsBats = player.eligible_positions.position.includes('P') ? `Throws: ${throws}` : `Bats: ${bats}`;

    const eligiblePositions = player.eligible_positions.position.filter(x => x != 'Util') || [];
    if (eligiblePositions.length == 0) {
        // if player is only Util elibible, we need to include otherwise everyone is util eligible so leaving out
        eligiblePositions.push('Util');
    }

    const availability = player.ownership.ownership_type == 'freeagents' ?
        'FA' :
        player.ownership.ownership_type === 'waivers' ?
            `W (${formatDateToMonthSlashDay(player.ownership.waiver_date)})` :
            player.ownership.ownership_type === 'team' ? `Owned: ${player.ownership.owner_team_name}` :
                player.ownership.ownership_type;

    return (
        <div>
            <Row className='name'><h5><a href={player.url} target="_">{player.name.full}</a></h5></Row>
            <Row className='text-size-70'>{eligiblePositions.join(', ')} - {player.editorial_team_full_name}</Row>
            <Row className='text-size-70'>{throwsBats}</Row>
            <Row className='text-size-70'>{availability}</Row>
            <Row className='text-size-60'>{`${player.percent_owned.value}% Rostered (${player.percent_owned.delta >= 0 ? '+' : '-'}${Math.abs(player.percent_owned.delta)}) this ${player.percent_owned.coverage_type}`}</Row>
        </div>
    );
};



import GameRow from './game-row';

const Games = (props) => {
  const { playerData, leagueId, watchedPlayerKeys, thirdPartyRatings, league, leagueSettings, rosters } = props;

  const gameRows = playerData.map((gameDay, index) => {
    const isOpen = index === 1;
    return (
      <GameRow key={index} isOpen={isOpen} leagueId={leagueId} league={league} leagueSettings={leagueSettings} rosters={rosters} gameDate={gameDay.gameDate} games={gameDay.games} watchedPlayerKeys={watchedPlayerKeys} addToWatch={props.addToWatchList} removeFromWatch={props.removeFromWatch} thirdPartyRatings={thirdPartyRatings}></GameRow>
    );
  });

  return (
    <div>
      {gameRows}
    </div>
  )
}

export default Games;
import { useEffect, useState } from "react";
import { getPitcherSplits } from "../../api/pitcher-stats";
import { useAuthContext } from "../../context/auth-context";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { PitcherSplitsTable } from "./PitcherSplitsTable";



const PitcherSplits = (props) => {
    const [splits, setSplits] = useState();
    const { user, userToken, refreshUserToken, logout } = useAuthContext()

    useEffect(() => {
        const getSplits = async (userToken, refreshUserToken, yahooPlayerId) => {
            const resp = await getPitcherSplits(userToken, refreshUserToken, yahooPlayerId);
            if (resp.success) {
                setSplits(resp.data);
            } else {
                handleUnauthorized(resp.error, logout)
                console.error(resp.error)
            }
        }

        getSplits(userToken, refreshUserToken, props.playerId)
    }, [props.playerId])


    if (!(splits?.length > 0)) {
        return <div>Splits</div>
    }

    const splitsToShowOnLoad = ["vs L", "vs R", "Home", "Away", "Mar/Apr", "May", "Jun", "Jul", "Aug", "Sept/Oct"];
    const bordersToRemove = ["vs R", "Away"]

    return (<PitcherSplitsTable splits={splits} splitsToShowOnLoad={splitsToShowOnLoad} bordersToRemove={bordersToRemove}></PitcherSplitsTable>)

}

export default PitcherSplits;


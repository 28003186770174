import { Button, Col, Row } from "reactstrap";



export const YahooAuthorizer = (props) => {
    const handleAuthorizeYahooClick = () => {
        const client_id = process.env.REACT_APP_YAHOO_CLIENT_ID;

        const redirect_uri = encodeURI(process.env.REACT_APP_YAHOO_REDIRECT_URI);
        const url = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&language=en-us`;
        // eslint-disable-next-line
        location.href = url;
    };

    return (
        <div>

            <Button color='primary' onClick={() => handleAuthorizeYahooClick()}>Authorize Forager</Button>

        </div>
    );
};

import { useEffect, useState } from "react";
import { Row, Table } from "reactstrap";
import { getPitcherHitterMatchupHistory } from "../../api/pitcher-hitter-matchup-history";
import { getTeamName } from "../../data/park-factors";
import LoadingIndicator from "../loading-indicator";
import { useAuthContext } from "../../context/auth-context";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";

const BattingLineup = (props) => {
    const [matchupStats, setMatchupStats] = useState();
    const [isLoading, setIsLoading] = useState();
    const [tableView, setTableView] = useState('basic');
    const { user, userToken, refreshUserToken, logout } = useAuthContext();

    const { lineup, pitcher } = props;


    const toggleTableView = () => {
        if (tableView === 'basic') {
            setTableView('advanced')
        } else {
            setTableView('basic')
        }
    }



    useEffect(() => {
        const getHistoryVsPitcher = async (userToken, refreshUserToken, request) => {
            setIsLoading(true);
            const resp = await getPitcherHitterMatchupHistory(userToken, refreshUserToken, request)
            if (resp.success) {
                setMatchupStats(resp.data)
            } else {
                handleUnauthorized(resp.error, logout)
                //ToDo: handle error
                console.log(resp.error)
            }
            setIsLoading(false)
        }

        const lineupNames = lineup.map(batter => {
            return { fullName: batter.Name, lastName: batter.Player.LastName, firstName: batter.Player.FirstName, playerId: batter.Player.PlayerID }
        })

        const request = {
            pitcherId: pitcher.player_id,
            pitcherName: pitcher.name.full,
            pitcherTeamAbbr: pitcher.editorial_team_abbr,
            hittingLineup: lineupNames,
            hittingTeamAbbr: lineup[0].Team
        }

        getHistoryVsPitcher(userToken, refreshUserToken, request);

    }, [])

    const renderLoadingIndicator = () => {
        return <LoadingIndicator></LoadingIndicator>
    }

    const statKeys = {
        shared: ['BattingOrder', 'Name', 'Position', 'BatHand'],
        basic: ['AB', 'H', 'HR', 'SO', 'AVG', 'SLG'],
        advanced: ['K%', 'Whiff', 'sOBA', 'xBA', 'xSLG', 'xwOBA', 'EV', 'LA']
    }

    const rows = lineup.map((batter, index) => {
        if (!matchupStats) {
            return [];
        }
        const batterMatchupStats = matchupStats.find(ms => ms.originalName === batter.Name);

        const sharedCells = statKeys.shared.map(x => {
            if (x === 'BatHand') {
                return <td>{batter.Player[x]}</td>
            } else {
                return <td>{batter[x]}</td>
            }

        }
        );
        const valueCells = statKeys[tableView].map(x => {
            if (x === 'AVG') {
                const avg = batterMatchupStats?.PA ? (batterMatchupStats?.H / batterMatchupStats?.PA).toFixed(3) : 0
                if (avg === 0) {
                    return <td></td>
                }
                return <td>{remove_leading_zeros(avg)}</td>
            }

            if (x === 'SLG') {
                const slg = batterMatchupStats?.[x];
                if (slg) {
                    return <td>{remove_leading_zeros(slg)}</td>
                }

            }
            return <td>{batterMatchupStats?.[x]}</td>
        })

        return (
            <tr key={index}>
                {sharedCells} {valueCells}
            </tr>
        )

        return (
            <tr key={index}>
                <td>{batter.BattingOrder}</td>
                <td>{batter.Name}</td>
                <td>{batter.Position}</td>
                <td>{batter.Player.BatHand}</td>
                <td>{batterMatchupStats?.AB}</td>
                <td>{batterMatchupStats?.SLG}</td>
                <td>{batterMatchupStats?.PA ? (batterMatchupStats?.H / batterMatchupStats?.PA).toFixed(3) : 0}</td>
                <td>{batterMatchupStats?.H}</td>
                <td>{batterMatchupStats?.["2B"]}</td>
                <td>{batterMatchupStats?.["3B"]}</td>
                <td>{batterMatchupStats?.HR}</td>
                <td>{batterMatchupStats?.SO}</td>
                <td>{batterMatchupStats?.["K%"]}</td>
                <td>{batterMatchupStats?.["Whiff%"]}</td>
                <td>{batterMatchupStats?.wOBA}</td>
                <td className="statcast">{batterMatchupStats?.xBA}</td>
                <td className="statcast">{batterMatchupStats?.xSLG}</td>
                <td className="statcast">{batterMatchupStats?.xwOBA}</td>
                <td className="statcast">{batterMatchupStats?.EV}</td>
                <td className="statcast">{batterMatchupStats?.LA}</td>
            </tr>
        )
    })

    const calculateStatsTotal = (players) => {
        if (!players?.length > 0) {
            return {}
        }
        const totals = calculateTotals(players);
        const AVG = totals["Total H"] / totals["Total AB"];
        const SLG = (totals["Total H"] + totals["Total Doubles"] + 2 * totals["Total Triples"] + 3 * totals["Total HR"]) / totals["Total AB"];
        const AB = totals["Total AB"];
        const H = totals["Total H"];
        const Doubles = totals["Total Doubles"];
        const Triples = totals["Total Triples"]
        const HR = totals["Total HR"];
        const K = totals["Total SO"];

        return { AVG, SLG, AB, H, Doubles, Triples, HR, K };
    }

    const calculateTotals = (players) => {
        let totalH = 0;
        let totalAB = 0;
        let totalDoubles = 0;
        let totalTriples = 0;
        let totalHR = 0;
        let totalBB = 0;
        let totalSO = 0;

        let totalSH = 0;
        let totalSF = 0;

        for (let i = 0; i < players.length; i++) {
            const player = players[i];
            if (!player.Player) {
                continue;
            }
            totalH += parseInt(player.H);
            totalAB += parseInt(player.AB);
            totalDoubles += parseInt(player["2B"]);
            totalTriples += parseInt(player["3B"]);
            totalHR += parseInt(player.HR);
            totalBB += parseInt(player.BB);
            totalSO += parseInt(player.SO);

            totalSH += parseInt(player.SH);
            totalSF += parseInt(player.SF);
        }

        return {
            "Total H": totalH,
            "Total AB": totalAB,
            "Total Doubles": totalDoubles,
            "Total Triples": totalTriples,
            "Total HR": totalHR,
            "Total BB": totalBB,
            "Total SO": totalSO,

            "Total SH": totalSH,
            "Total SF": totalSF,
        };
    }


    const renderLineupTable = () => {
       
        const lineupNames = lineup.map(p => p.Name);
        const matchupStatsFilteredByLineup = matchupStats?.filter(p => lineupNames.includes(p.originalName))

        const totalTeamStats = calculateStatsTotal(matchupStats);
        const totalLineupStats = calculateStatsTotal(matchupStatsFilteredByLineup)

        const statHeaders = {
            basic: ['Spot', 'Name', 'Pos', 'Bats', 'AB', 'H', 'HR', 'K', 'AVG', 'SLG'],
            advanced: ['Spot', 'Name', 'Pos', 'Bats', 'K%', 'Whiff%', 'sOBA', 'xBA', 'xSLG', 'xwOBA', 'EV', 'LA']
        }
        const headerCells = statHeaders[tableView].map(x => <th>{x}</th>);

        return (<Table bordered size="sm" className="text-size-60 text-align-center">
            <thead>
                <tr>
                    {headerCells}
                    {/* <th>Spot</th>
                    <th>Name</th>
                    <th>Pos.</th>
                    <th>BatHand</th>
                    <th>AB</th>
                    <th>SLG</th>
                    <th>AVG</th>
                    <th>H</th>
                    <th>2B</th>
                    <th>3B</th>
                    <th>HR</th>
                    <th>K</th>
                    <th>K%</th>
                    <th>Whiff%</th>
                    <th>wOBA</th>
                    <th className="statcast">xBA</th>
                    <th className="statcast">xSLG</th>
                    <th className="statcast">xwOBA</th>
                    <th className="statcast">EV</th>
                    <th className="statcast">LA</th> */}
                </tr>
            </thead>
            <tbody>
                {rows}
               
                <tr className="bold">
                    <td>Lineup</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{totalLineupStats.AB}</td>
                    <td>{totalLineupStats.H}</td>
                    {/* <td>{totalLineupStats.Doubles}</td>
                    <td>{totalLineupStats.Triples}</td> */}
                    <td>{totalLineupStats.HR}</td>
                    <td>{totalLineupStats.K}</td>
                    <td>{remove_leading_zeros(totalLineupStats.AVG?.toFixed(3))}</td>
                    <td>{remove_leading_zeros(totalLineupStats.SLG?.toFixed(3))}</td>
                </tr>
                {/* <tr>{headerCells.map(x => <td></td>)}</tr> */}
                <tr className="bold">
                    <td>Team</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{totalTeamStats.AB}</td>
                    <td>{totalTeamStats.H}</td>
                    {/* <td>{totalTeamStats.Doubles}</td>
                    <td>{totalTeamStats.Triples}</td> */}
                    <td>{totalTeamStats.HR}</td>
                    <td>{totalTeamStats.K}</td>
                    <td>{remove_leading_zeros(totalTeamStats.AVG?.toFixed(3))}</td>
                    <td>{remove_leading_zeros(totalTeamStats.SLG?.toFixed(3))}</td>
                </tr>
            </tbody>
        </Table>
        )
    }


    return (
        <div className="pitcher-hitter-matchup-container">
            <div className="text-align-center"><h6>{getTeamName(props.lineup[0].Team)} Lineup vs {props.pitcher.name.full}</h6></div>
            {isLoading ? renderLoadingIndicator() : renderLineupTable()}
        </div>
    )
}


export default BattingLineup;
import { Row, Table } from 'reactstrap';
import { RecentGameInfo } from './RecentGameInfo';
import { useState } from 'react';
import { areDatesEqual, formatDateToMonthSlashDay } from '../../dates/date-helper';


export const GameLogView = (props) => {

    return (
        <div>
            <Row className=''>
                <UpcomingGameCollapsibleView></UpcomingGameCollapsibleView>
            </Row>
            <Row  className='horizontal-scroll game-log-row vertical-scroll border-top'>

                <CompletedGameLog game_log={props.hitter.game_log} mlb_games_pitcher_handedness={props.mlbGamePitcherHandedness}></CompletedGameLog>

            </Row>
        </div>
    );
};


const UpcomingGameCollapsibleView = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const openCloseText = isOpen ? 'Hide Upcoming Games' : 'Show Upcoming Games';

    return (
        <div>
            <Row onClick={() => setIsOpen(!isOpen)} className='bg-white justify-center'>{''}</Row>
        </div>
    )
}

const CompletedGameLog = (props) => {
    const {game_log, mlb_games_pitcher_handedness} = props;

    const stat_reference_info = game_log.stat_categories.stats.stat;
    const stat_order = stat_reference_info.map(s => s.stat_id);
    const stat_headers = stat_reference_info.map(x => {
        return <th className='sticky-top'>{x.abbr}</th>
    })

    const game_log_entries = game_log.game_log_stats_collection.game_log_stats;
    const days_with_completed_games = game_log_entries.filter(x => !x.start_time); // ToDo: might need a better way here so it's more robust

    const game_rows = days_with_completed_games.map(day => {
        const date =  day.date.slice(-5)
        const opponent = day.opponent;
        const isHomeGame = !opponent.includes('@');
        const games = day.games;
        const oppPitcherHandedness = get_opp_pitcher_handedness(games, day.date, isHomeGame, mlb_games_pitcher_handedness);
        const result = get_game_result(games);

        // now stats.... I'm sure there are in order... but should we trust it? Let's just match on id
        const stats = day.stats.stat;
        
        const ordered_stat_cells = stat_order.map(x => {
            const day_stat = stats.find(s => s.stat_id === x);
            return <td>{day_stat.value}</td>
        })

        const started_indicator = result === 'PPD' ? '' : opponent.includes('x') ? 'red' : 'green';

        return (
            <tr>
            <td>{date}</td>
            <td className={started_indicator}>{opponent}</td>
            <td>{oppPitcherHandedness}</td>
            <td>{result}</td>
            {ordered_stat_cells}
            </tr>
        )



    })


    return (
        <div>
            <Table className='game-log-table'>
                <thead>
                    <tr className=''>
                        <th className='sticky-top'>Date</th>
                        <th className='sticky-top'>Opp</th>
                        <th className='sticky-top'>L/R</th>
                        <th className='sticky-top'>Result</th>
                        {stat_headers}
                    </tr>
                </thead>
                <tbody>
                    {game_rows}
                </tbody>
            </Table>
        </div>
    )
}

const get_opp_pitcher_handedness = (games, date, isHomeGame, mlb_games_pitcher_handedness) => {

    if (Array.isArray(games.game)) {
        console.log('found a DH, skipping for now')
        return '';
    }

    const gamesOnThisDate = mlb_games_pitcher_handedness.filter(x => x.date_str == date);
    // console.log(gamesOnThisDate)
    const thisGame = gamesOnThisDate.filter(x => x.home_team_abbr = games.game.home_abbr)
    // console.log(thisGame)
    const opp_pitcher = thisGame.find(x => x.is_home !== isHomeGame);
    return opp_pitcher?.throws || 'wtf';    
}

export function get_game_result(games) {

    let result = '';
    if (Array.isArray(games.game)) {
        if (games.game.length === 2) {
            result = `${games.game[0].status};${games.game[1].status}`;
        } else if (games.game.length === 1) {
            result = games.game[0].status;
        } else {
            console.log(`Not a single game nor a double header?! Hmmm....`);
        }
    } else {
        result = games.game.status;
    }
    return result;
}

import getApi from '../api/axios/api'

export async function getUserLeagues(token, refreshUserToken) {
        const api = getApi(token, refreshUserToken);
        const resp = await api.get('/leagues/getUserLeagues');
        return resp;
}

export async function createUserLeagueWithTeam(token, refreshUserToken, leagueId, leagueTypeId, teamId, teamName) {
    // validate format league id
        const body = { leagueId, leagueTypeId, teamId, teamName }
        const api = getApi(token, refreshUserToken);
        const resp = await api.post('/leagues/createUserLeagueWithTeam', body);
        return resp;
}

export async function deleteUserLeague(token, refreshUserToken, leagueId) {
        const body = { leagueId }
        const api = getApi(token, refreshUserToken);
        const resp = await api.post('/leagues/deleteUserLeague', body);
        return resp;
}
import getApi from "./axios/api";

export async function getLeagueWideHitterStats(userToken, refreshUserToken) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get('/hitterStats/leagueStats');
    return resp;
}

export async function getHitterStatsForTimeframes(userToken, refreshUserToken, body) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/hitterStats/byTimeframe', body);
    return resp;
}

export async function getPitcherStatsForTimeframes(userToken, refreshUserToken, body) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/pitcherStats/byTimeframe', body);
    return resp;
}
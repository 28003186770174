import { Link, useLocation } from 'react-router-dom'
import { Col, Row, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Collapse, NavbarText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { useAuthContext } from '../context/auth-context'
import { useEffect } from 'react';




const AppHeader = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] =  useState(window.location.pathname);
    const { user, logout } = useAuthContext();

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    }

    const handleNav = (newPage) => {
        
        toggleNavbar();
    }

    usePageViews(setCurrentPage)

    if (!user) {
        return null;
    }

    const currentPageMapper = {
        '/pitcher-finder': 'Pitcher Forager',
        '/hitter-finder': 'Hitter Forager',
        '/strategy-builder': 'Strategy Building',
        '/add-drop-scheduler': 'Add/Drop Schedule',
        '/roster-researcher': 'Roster Research',
        '/league-researcher': 'League Research',
        '/weekly-summaries': 'Weekly Summary',
    }

    const pageDisplay = currentPageMapper[currentPage] || 'Fantasy Forager';

    return (

        <Row className="App-header">
            <Col>
                <Navbar dark expand="md">
                    <NavbarBrand onClick={toggleNavbar}>
                        {pageDisplay}
                    </NavbarBrand>
                    <NavbarToggler className='navbar-toggler' onClick={toggleNavbar}>
                        <svg viewBox="0 0 30 30" width="20" height="20">
                            <path stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22" />
                        </svg>
                    </NavbarToggler>
                    <Collapse navbar isOpen={isOpen}>
                        <Nav className="me-auto" navbar>
                            <NavItem onClick={() => handleNav('Hitter Forager')}>
                                <Link className='nav-link' to="/hitter-finder">Hitter Forager</Link>
                            </NavItem>
                            <NavItem onClick={() => handleNav('Pitcher Forager')}>
                                <Link className="nav-link" to="/pitcher-finder">Pitcher Forager</Link>
                            </NavItem>
                            <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/add-drop-scheduler">Add/Drop Schedule</Link>
                            </NavItem> 
                            <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/roster-researcher">Roster Research</Link>
                            </NavItem>
                            <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/strategy-builder">Strategy Building</Link>
                            </NavItem>
                            <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/league-researcher">League Research</Link>
                            </NavItem>
                            <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/weekly-summaries">Weekly Summary</Link>
                            </NavItem>
                            <NavItem onClick={() => handleNav('Manage Leagues')}>
                                <Link className="nav-link" to="/">Leagues</Link>
                            </NavItem>
                            {/* <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/watchlist">Watchlist</Link>
                            </NavItem>*/}
                           
                            {/* <NavItem onClick={toggleNavbar}>
                                <Link className="nav-link" to="/roster-matchups">My Hitting Matchups</Link>
                            </NavItem> */}
                            {/* <NavItem onClick={toggleNavbar}>
                                <Link className='nav-link' to="/mlb-transactions">MLB Trannys</Link>
                            </NavItem> */}
                        </Nav>
                        <NavbarText>
                            <UncontrolledDropdown inNavbar>
                                <DropdownToggle nav caret>
                                    <FontAwesomeIcon icon={faUser} size="1x"></FontAwesomeIcon>
                                    {' '}{user.email}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={logout}>
                                        Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </NavbarText>
                    </Collapse>
                </Navbar>
            </Col>
        </Row>

    )
}

export default AppHeader;

const usePageViews = (setCurrentPage) => {
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location, setCurrentPage]);
};

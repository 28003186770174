import { Button, ButtonGroup } from "reactstrap";

export const SeasonRanksSubsetButtonGroup = (props) => {
    const { setSubsetView, subsetView } = props;

    return (
        <ButtonGroup className='season-rank-subset-btn-group' size='sm'>
            <Button outline active={subsetView === 'L0'} onClick={() => setSubsetView('L0')}>This Week</Button>
            <Button outline active={subsetView === 'L1'} onClick={() => setSubsetView('L1')}>L1</Button>
            <Button outline active={subsetView === 'L2'} onClick={() => setSubsetView('L2')}>L2</Button>
            <Button outline active={subsetView === 'L3'} onClick={() => setSubsetView('L3')}>L3</Button>
            <Button outline active={subsetView === 'L5'} onClick={() => setSubsetView('L5')}>L5</Button>
            <Button outline active={subsetView === 'season'} onClick={() => setSubsetView('season')}>Season</Button>
        </ButtonGroup>
    );

};

import { Table } from "reactstrap";
import { remove_leading_zeros } from "../../../display-helpers/remove-leading-zeros";

export const SplitsTableRatios = (props) => {
    const { statsToShow, stats, pitcherHand } = props;

    const headers = statsToShow.map(x => <th>{x}</th>);

    const dataSets = [{ key: 'all', display: "All" }, { key: 'vs_r', display: 'vs R' }, { key: 'vs_l', display: 'vs L' }];

    const buffer = 0.05;
    const calc_color_for_k = (team_avg_quotient) => team_avg_quotient - buffer > 1 ?
        'green-gradient' :
        team_avg_quotient + buffer < 1 ? 'red-gradient' : '';

    const calc_color_for_r = (team_avg_quotient) => team_avg_quotient - buffer > 1 ?
        'red-gradient' :
        team_avg_quotient + buffer < 1 ? 'green-gradient' : '';

    const color_rules_dictionary = {
        'AVG': calc_color_for_r,
        'K%': calc_color_for_k,
        'OPS': calc_color_for_r,
        'OBP': calc_color_for_r,
        'wOBA': calc_color_for_r,
    };

    const rows = dataSets.map(set => {
        const setStats = stats[set.key];

        const dataCells = statsToShow.map(key => {

            let displayValue = setStats[key].toFixed(2);
            const wholeNumbers = ['PA'];
            if (wholeNumbers.includes(key)) {
                displayValue = setStats[key].toFixed(0);
                return <td>{displayValue}</td>;
            }

            const color = color_rules_dictionary[key](setStats[key]);

            return <td className={color}>{remove_leading_zeros(displayValue)}x</td>;
        });

        const highLight = (set.key === 'vs_r' && pitcherHand == 'R') ? ' relevant ' : (set.key === 'vs_l' && pitcherHand === 'L') ? ' relevant ' : '';
        return <tr className={highLight}><th>{set.display}</th>{dataCells}</tr>;

    });

    return (
        <div>
            <Table size='sm' className="text-size-70">
                <thead>
                    <tr><th></th>{headers}</tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    );
};

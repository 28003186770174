import { RotatingLines } from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { className } = props;
  // pass className='relative' to get position relative to parent
    return <div className={`loading-indicator ${className}`}>
      <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  </div>
}

export default LoadingIndicator;
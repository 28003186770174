import { Col, Row } from 'reactstrap';
import { UpcomingGameTable } from './UpcomingGameTable';
import { LeftRightyGameBreakdown } from './LeftRightyGameBreakdown';
import { AggregatePitcherStats } from './AggregatePitcherStats';
import { UpcomingMatchupDetails } from './UpcomingMatchupDetails';
import { useState } from 'react';



export const UpcomingGamesView = (props) => {
    const [isShowingDetails, setIsShowingDetails] = useState({isShowing: false, selectedGameId: null});
    const { upcomingGames, hitter, leagueWideStats } = props;

    const onSingleMatchupClick = (clicked) => {
        const game = findObjectByDate(upcomingGames, clicked);
        const gameId = game?.gameDetails.mlb_com_game_id;

        setIsShowingDetails({isShowing: !isShowingDetails.isShowing, selectedGameId: gameId})
    }

    const toggleIsShowingDetails = () => {

        setIsShowingDetails({isShowing: !isShowingDetails.isShowing, selectedGameId:null})
    }

    const hideShowText = isShowingDetails.isShowing ? 'Hide Matchup Details' : 'View Matchup Details';
    return (
        <div>
            <Row>
                <LeftRightyGameBreakdown {...props}></LeftRightyGameBreakdown>
            </Row>
            <Row className='border-bottom'>
                <AggregatePitcherStats {...props}></AggregatePitcherStats>
            </Row>
            <Row className='bottom-border'>
            <Row className='border-bottom matchup-details clickable' onClick={() => toggleIsShowingDetails(null)}><strong className='text-size-90'>{hideShowText}</strong></Row>
                {!isShowingDetails.isShowing  && <UpcomingGameTable onGameClick={onSingleMatchupClick} {...props} />}
                <UpcomingMatchupDetails leagueWideStats={leagueWideStats} hitter={hitter} isShowingDetails={isShowingDetails} setIsShowingDetails={setIsShowingDetails} upcomingGames={upcomingGames}></UpcomingMatchupDetails>
            </Row>

           
            {/* <Row>{temp}</Row> */}
            {/* <Row>Show Matchups</Row> */}
        </div>
    );
};


function findObjectByDate(games, searchDate) {
    const [searchMonth, searchDay] = searchDate.split('/').map(Number);
  
    return games.find(item => {
      const date = new Date(item.date);
      return date.getMonth() + 1 === searchMonth && date.getDate() === searchDay;
    });
  }

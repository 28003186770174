import getApi from "./axios/api";

export async function getPitcherGameLog(userToken, refreshUserToken, pitcherId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/pitcherStats/gameLog/${pitcherId}`);
    return resp;
}

export async function getPitcherSplits(userToken, refreshUserToken, playerId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/pitcherStats/splits/${playerId}`);
    return resp;
}

export async function getPitcherSplitsBatch(userToken, refreshUserToken, pitcherNames) {
    const body = { pitcher_names: pitcherNames }
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.post('/pitcherStats/splits/batch', body);
    return resp;
}

export async function getSeasonStats(userToken, refreshUserToken, playerId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/pitcherStats/seasons/${playerId}`);
    return resp;
}

export async function getLeagueWidePitcherStats(userToken, refreshUserToken) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get('/pitcherStats/leagueStats');
    return resp;
}
import { Table } from 'reactstrap';

export const RecentGamesTable = (props) => {
    const { title, headerCells, lastGameCells, last3gamesCells, last7gamesCells, last10gamesCells, last14gamesCells } = props;

    return (
        <Table
            borderless size="sm"
            className='recent-games'
        >
            <thead>
                <tr>
                    <th colSpan={8} className='text-align-center'>{title}</th>
                </tr>
                <tr className='text-size-90'>
                    {headerCells}
                </tr>
            </thead>
            <tbody>
                {lastGameCells && <tr>
                        {lastGameCells}
                    </tr>}
                <tr>
                    {last3gamesCells}
                </tr>
                <tr>
                    {last7gamesCells}
                </tr>
                <tr>
                    {last10gamesCells}
                </tr>
                <tr>
                    {last14gamesCells}
                </tr>
            </tbody>
        </Table>
    );
};

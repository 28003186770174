import { Row, Col, Button } from 'reactstrap'
import { getParkFactor } from '../../data/park-factors'
import { formatDateToMonthSlashDay } from '../../dates/date-helper'
import { MatchupDetails } from '../pitcher-finder/MatchupDetails'
import { TeamMatchupHeader } from '../pitcher-finder/TeamMatchupHeader'
import { useState } from 'react'
import { useAuthContext } from '../../context/auth-context'



function Game(props) {
  const [selectedView, setSelectedView] = useState('summary');
  const [selectedPitcher, setSelectedPitcher] = useState();

  const {leagueSettings, rosters} = props;

  const toggleView = () => {
    if (selectedView == 'summary') {
      setSelectedView('details')
    } else {
      setSelectedView('summary')
    }
  }

  const openPitcherDetails = (pitcher) => {
    if (pitcher) {
      setSelectedPitcher(pitcher);
      setSelectedView('details');
    }
  }


  const renderPlayerImage = (playerUrl) => {
    return <img src={playerUrl}></img>
  }
  const parkData = getParkFactor(props.gameInfo.homeTeam.teamAbbr);

  const thirdPartyMatchupRatings = getMatchupRating(props);


  const gameDetails = <MatchupDetails leagueSettings={leagueSettings} rosters={rosters} gameInfo={props.gameInfo} pitcher={selectedPitcher} toggle={toggleView} league={props.league}></MatchupDetails>

    // ToDo: temporary hack
    const {user} = useAuthContext();
    const isAdmin = user.email === process.env.REACT_APP_ADMIN_ACCT_EMAIL;
    

  const gameSummary = (
    <Col className="game-tile">
      <TeamMatchupHeader gameInfo={props.gameInfo} parkData={parkData}></TeamMatchupHeader>
      <hr></hr>
      <Row>
        <Col onClick={() => openPitcherDetails(props.gameInfo.awayPitcher)}>{props.gameInfo.awayPitcher?.image_url ? renderPlayerImage(props.gameInfo.awayPitcher.image_url) : null}</Col>
        <Col onClick={() => openPitcherDetails(props.gameInfo.homePitcher)}>{props.gameInfo.homePitcher?.image_url ? renderPlayerImage(props.gameInfo.homePitcher.image_url) : null}</Col>
      </Row>
      <Row>
        <Col><a className="player-name-link" href={props.gameInfo.awayPitcher?.url} target="_">
          {props.gameInfo.awayPitcher?.name?.full}
        </a></Col>
        <Col><a className="player-name-link" href={props.gameInfo.homePitcher?.url} target="_">
          {props.gameInfo.homePitcher?.name?.full}
        </a></Col>
      </Row>
      <Row className='pitcher-stats'>
        <Col>{props.gameInfo.awayPitcher?.eligible_positions?.position.filter(x => x != 'P').join(', ')}</Col>
        <Col>{props.gameInfo.homePitcher?.eligible_positions?.position.filter(x => x != 'P').join(', ')}</Col>

      </Row>
      <Row className="pitcher-stats">
        <Col><PitcherWinLoss pitcher={props.gameInfo.awayPitcher}></PitcherWinLoss></Col>
        <Col><PitcherWinLoss pitcher={props.gameInfo.homePitcher}></PitcherWinLoss></Col>
      </Row>
      <Row className="pitcher-stats">
        <Col><PitcherStats pitcher={props.gameInfo.awayPitcher}></PitcherStats></Col>
        <Col><PitcherStats pitcher={props.gameInfo.homePitcher}></PitcherStats></Col>
      </Row>
      <Row className="pitcher-stats">
        <Col><PitcherStats2 pitcher={props.gameInfo.awayPitcher}></PitcherStats2></Col>
        <Col><PitcherStats2 pitcher={props.gameInfo.homePitcher}></PitcherStats2></Col>
      </Row>
      <Row className="pitcher-stats">
        <Col><MatchupRating pitcher={props.gameInfo.awayPitcher} ratings={thirdPartyMatchupRatings.awayPitcherMatchupRatings}></MatchupRating></Col>
        <Col><MatchupRating pitcher={props.gameInfo.homePitcher} ratings={thirdPartyMatchupRatings.homePitcherMatchupRatings}></MatchupRating></Col>
      </Row>
      <Row className="pitcher-stats">
        <Col>{renderAddWatch(props.gameInfo.gameId, props.gameInfo.awayPitcher?.player_id, props.leagueId, props.addToWatch, props.removeFromWatch, props.watchedPlayerKeys, () => openPitcherDetails(props.gameInfo.awayPitcher), isAdmin)}</Col>
        <Col>{renderAddWatch(props.gameInfo.gameId, props.gameInfo.homePitcher?.player_id, props.leagueId, props.addToWatch, props.removeFromWatch, props.watchedPlayerKeys, () => openPitcherDetails(props.gameInfo.homePitcher), isAdmin)}</Col>
      </Row>
      {/* <Link to='/add-drop-scheduler' state={{leagueId: props.leagueId, teamId:'6'}}>Test</Link> */}

    </Col>
  );



  return (
    // todo: wont have to re-render/get props if i use the 'isShowing' pattern from elsewhere
    selectedView == 'summary' ? gameSummary : gameDetails
  )
}

const renderAddWatch = (gameId, pitcherId, leagueId, addToWatch, removeFromWatch, watchedPlayerKeys, openPitcherDetails, isAdmin) => {
  
  

  if (!addToWatch) {
    return null;
  }

  if (!pitcherId) {
    return null;
  }

  if (!isAdmin) {
    return <Button size="sm" color='primary' onClick={openPitcherDetails}>Show Details</Button>
  }

  

  const isWatched = watchedPlayerKeys.some(x => parseInt(x.player_id) === parseInt(pitcherId) && parseInt(x.game_id) === parseInt(gameId));
  const text = isWatched ? "Remove Watch" : "Add Watch"
  const link = isWatched ?
    <Button size="sm" color='warning' className="clickable" onClick={() => removeFromWatch(gameId, pitcherId, leagueId)}>{text}</Button> :
    <Button size="sm" color='primary' className="clickable" onClick={() => addToWatch(gameId, pitcherId, leagueId)}>{text}</Button>
  return link;
}

export default Game;



function PitcherWinLoss(props) {
  const { pitcher } = props;
  if (!pitcher) {
    return null;
  }

  return (
    <span className="pitcher-stats">( <span className='bold'>{pitcher.stats?.W}</span> - <span className='bold'>{pitcher.stats?.L ?? 0} )</span></span>
  )
}

function PitcherStats(props) {
  const { pitcher } = props;
  if (!pitcher) {
    return null;
  }
  return (
    <span className="pitcher-stats"><span className='bold'>{pitcher.stats?.IP ?? 0}</span> IP, <span className='bold'>{pitcher.stats?.ERA?.toFixed(2) ?? 0}</span> ERA, <span className='bold'>{pitcher.stats?.SO ?? 0}</span> SO</span>
  )
}

function PitcherStats2(props) {
  const { pitcher } = props;
  if (!pitcher) {
    return null;
  }

  const inningsPitchedPerAppearance = parseInt(pitcher.stats?.G) > 0 ? (pitcher.stats.IP / parseInt(pitcher.stats.G)).toFixed(1) : 0;
  const strikeoutsPerAppearance = parseInt(pitcher.stats?.IP) > 0 ? ((pitcher.stats.SO / pitcher.stats?.IP) * inningsPitchedPerAppearance).toFixed(1) : 0;
  return (
    <span className="pitcher-stats"><span className='bold'>{inningsPitchedPerAppearance}</span> IP/G, <span className='bold'>{strikeoutsPerAppearance}</span> K/G</span>
  )
}

function MatchupRating(props) {
  const { ratings, pitcher } = props;
  if (!pitcher) {
    return null;
  }
  const rotoBallerRating = ratings?.find(x => x.ratingType == 'roto-baller') || { rating: 'unk' };
  const espnRating = ratings?.find(x => x.ratingType == 'espn') || { rating: 'unk' };

  return (
    <p>
      <span className="pitcher-stats">Roto: <span className='bold'>{rotoBallerRating?.rating} </span></span>
      <span className="pitcher-stats">Espn: <span className='bold'>{espnRating?.rating}</span></span>
    </p>
  )
}

function getMatchupRating(props) {
  const { thirdPartyRatings } = props;
  if (!thirdPartyRatings) {
    return {};
  }


  const gameDate = props.gameInfo.date;
  const formattedDate = formatDateToMonthSlashDay(gameDate);

  const homePitcherId = props.gameInfo.homePitcher?.player_id;
  const awayPitcherId = props.gameInfo.awayPitcher?.player_id;

  let homePitcherMatchupRatings = [];
  if (homePitcherId) {
    homePitcherMatchupRatings = thirdPartyRatings.filter(x => x.playerIdYahoo == homePitcherId && x.gameDate == formattedDate);
    // console.log(homePitcherMatchupRatings)
  }

  let awayPitcherMatchupRatings = [];
  if (awayPitcherId) {
    awayPitcherMatchupRatings = thirdPartyRatings.filter(x => x.playerIdYahoo == awayPitcherId && x.gameDate == formattedDate);
    // console.log(awayPitcherMatchupRatings)
  }

  return { homePitcherMatchupRatings, awayPitcherMatchupRatings };
  // console.log(props.gameInfo)
}



import { useState } from "react";
import { formatDateToMonthSlashDay } from "../../../../dates/date-helper";
import { remove_leading_zeros } from "../../../../display-helpers/remove-leading-zeros";
import { Bar, Label, Line, ReferenceLine, XAxis, YAxis } from "recharts";
import { ResponsiveBarChart } from "./ResponsiveBarChart";
import { renderYAxisPercentage } from "./render-y-axis-percentage";
import { create_and_merge_trendline_data } from "./trendline";



export const AdvancedPercentageStatsTrendChart = (props) => {
    const initialDataToShow = "hardHitCount";
    const [dataToShow, setDataToShow] = useState(initialDataToShow);

    const { leagueWideStats, chart_data, timeframe } = props;

    const toggleData = (dataKey) => {
            setDataToShow(dataKey)
    }

    const cycleView = () => {
        if (dataToShow == 'hardHitCount') {
            setDataToShow('babip')
        } else if (dataToShow == 'babip') {
            setDataToShow('line_drives')
        } else if (dataToShow == 'line_drives') {
            setDataToShow('o_swing_percent')
        } else {
            setDataToShow('hardHitCount')
        }
    }

    const last_10_games = chart_data.every_game.slice(-10);
    const last_20_games = chart_data.every_game.slice(-20);
    const last_30_games = chart_data.every_game.slice(-30);

    const last_x_games = timeframe === 'L10' ? last_10_games : timeframe === 'L20' ? last_20_games : last_30_games;

    const trendlineData = create_and_merge_trendline_data(last_x_games, dataToShow === 'o_swing_percent' ? 'o_swings' : dataToShow)

    const statTotals = last_x_games.reduce((acc, stats) => {
        acc.hardHits += stats.hardHitCount || 0;
        acc.bipCount += stats.bipCount || 0;
        acc.HR += stats.HR || 0;
        acc.H += stats.H || 0;
        acc.AB += stats.AB || 0;
        acc.SF += stats.sac_flies || 0;
        acc.K += stats.K || 0;
        acc.pitchesOutOfZone += stats.pitchesOutOfZone || 0;
        acc.oSwings += stats.o_swings || 0;
        acc.line_drives += stats.line_drives || 0;
        acc.events += stats.events || 0;
        return acc;

    }, { hardHits: 0, bipCount: 0, HR: 0, H: 0, AB: 0, SF: 0, K: 0, pitchesOutOfZone: 0, oSwings: 0, line_drives: 0, events: 0 });

    const sampleSizeDictionary = {
        o_swing_percent: statTotals.pitchesOutOfZone,
        hardHitCount: statTotals.events,
        line_drives: statTotals.bipCount,
    }

    const o_swing_percent_avg = leagueWideStats?.hitter?.all?.totals?.[0]['pfxO-Swing%'];
    const hard_hit_percent_avg = leagueWideStats?.hitter?.all?.totals?.[0]['HardHit%']; // ToDo: Figure out difference between 'HardHit%' and 'Hard%'
    const k_percent_avg = leagueWideStats?.hitter?.all?.totals?.[0]['K%'];
    const swinging_strikes_percent_avg = leagueWideStats?.hitter?.all?.totals?.[0]['SwStr%'];
    const babip_avg = leagueWideStats?.hitter?.all?.totals?.[0]['BABIP'];
    const line_drive_avg = leagueWideStats?.hitter?.all?.totals?.[0]['LD%'];

    const hitter_season_averages = {
        'hardHit%': chart_data.seasonTotals['HardHit%'],
        'o_swing_percent': chart_data.seasonTotals['pfxO-Swing%'],
        'line_drive%': chart_data.seasonTotals['LD%'],
        'babip': chart_data.seasonTotals['BABIP']
    }

    const hitter_last_x_average = {
        babip: (statTotals.AB - statTotals.K - statTotals.HR + statTotals.SF) === 0 ? 0 : (statTotals.H - statTotals.HR) / (statTotals.AB - statTotals.K - statTotals.HR + statTotals.SF),
        'oSwing%': statTotals.pitchesOutOfZone == 0 ? 0 : statTotals.oSwings / statTotals.pitchesOutOfZone,
        'line_drive%': statTotals.bipCount === 0 ? 0 : statTotals.line_drives / statTotals.bipCount,
        'hardHit%': statTotals.bipCount === 0 ? 0 : statTotals.hardHits / statTotals.bipCount,
    }

    const dataSetDisplayInfo = {
        'o_swing_percent': { color: '#4477AA', display: 'Chase%' },
        'hardHitCount': { color: '#CCBB44', display: 'HardHit%' },
        // {dataKey: 'k_percent', color: 'red', display: 'K%'},
        // {dataKey: 'swinging_strikes_percent', color: 'orange', display: 'SwStr%'},
        'babip': { color: '#66CCEE', display: 'BABIP' },
        'line_drives': { color: '#AA3377', display: 'LD%' },
    }

    const league_avg_color = 'black';
    const hitter_season_avg_color = '#33BBEE';

    const labelDictionary = {
        o_swing_percent: {
            league_avg: o_swing_percent_avg,
            hitter_season_avg:  hitter_season_averages['o_swing_percent'],
            hitter_x_day_avg: hitter_last_x_average['oSwing%'],
        },
        hardHitCount: {
            league_avg: hard_hit_percent_avg,
            hitter_season_avg: hitter_season_averages['hardHit%'],
            hitter_x_day_avg: hitter_last_x_average['hardHit%'],
        },
        babip: {
            league_avg: babip_avg,
            hitter_season_avg: hitter_season_averages.babip,
            hitter_x_day_avg: hitter_last_x_average.babip,
        },
        line_drives: {
            league_avg: line_drive_avg, 
            hitter_season_avg: hitter_season_averages['line_drive%'],
            hitter_x_day_avg: hitter_last_x_average['line_drive%']
        }

    }

    const o_swing_percent_league_avg_scaled = o_swing_percent_avg * sampleSizeDictionary['o_swing_percent'] / trendlineData.data.length;
    const o_swing_percent_hitter_season_avg_scaled = hitter_season_averages['o_swing_percent'] * sampleSizeDictionary['o_swing_percent'] / trendlineData.data.length;

    const hard_hit_percent_league_avg_scaled = hard_hit_percent_avg * sampleSizeDictionary['hardHitCount'] / trendlineData.data.length;
    const hard_hit_percent_hitter_season_avg_scaled = hitter_season_averages['hardHit%'] * sampleSizeDictionary['hardHitCount'] / trendlineData.data.length

    const line_drive_percent_league_avg_scaled =  line_drive_avg * sampleSizeDictionary['line_drives'] / trendlineData.data.length;
    const line_drive_percent_hitter_season_avg_scaled =  hitter_season_averages['line_drive%'] * sampleSizeDictionary['line_drives'] / trendlineData.data.length;

    const averageValuesReferenceDictionary = {
        o_swing_percent: [
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={o_swing_percent_league_avg_scaled} stroke={league_avg_color}></ReferenceLine>,
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={o_swing_percent_hitter_season_avg_scaled} stroke={hitter_season_avg_color}></ReferenceLine>,
        ],
        'hardHitCount': [
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={hard_hit_percent_league_avg_scaled} stroke={league_avg_color}></ReferenceLine>,
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={hard_hit_percent_hitter_season_avg_scaled} stroke={hitter_season_avg_color}></ReferenceLine>,
        ],
        babip: [
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={babip_avg} stroke={league_avg_color}></ReferenceLine>,
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={hitter_season_averages.babip} stroke={hitter_season_avg_color}></ReferenceLine>,
        ],
        line_drives: [
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={line_drive_percent_league_avg_scaled} stroke={league_avg_color}></ReferenceLine>,
            <ReferenceLine yAxisId={'left'} type='monotone' strokeDasharray="3 3" y={line_drive_percent_hitter_season_avg_scaled} stroke={hitter_season_avg_color}></ReferenceLine>,
        ],
        // k_percent: <ReferenceLine yAxisId={'right'} type='monotone' y={k_percent_avg} stroke={getDisplayInfo('k_percent', dataSetDisplayInfo).color}></ReferenceLine>,
        // swinging_strikes_percent: <ReferenceLine yAxisId={'right'} type='monotone' y={swinging_strikes_percent_avg} stroke={getDisplayInfo('swinging_strikes_percent', dataSetDisplayInfo).color}></ReferenceLine>
    };



    const renderCustomXAxisValue = (x) => {
        const formatted = formatDateToMonthSlashDay(x);
        // console.log(formatted)
        return formatted;
    };



    const renderBabipLeftAxis = (tickProps) => {
        const { x, y, payload } = tickProps;
        const { value, offset } = payload;

        return <text className="text-size-60" x={x - 18} y={y+3}>{remove_leading_zeros(value?.toFixed(3))}</text>
    }

    const renderXAxisTicks = (tickProps) => {
        const { x, y, payload, index, visibleTicksCount } = tickProps;
        const { value, offset } = payload;

        const divisor = 10 / visibleTicksCount;
        if (index % Math.round(1 / divisor) === 0) {
            const dateParts = value.split('-');
            const day = dateParts[2].startsWith(0) ? dateParts[2][1] : dateParts[2];
            const month = dateParts[1].startsWith(0) ? dateParts[1][1] : dateParts[1];
            return <text className='text-size-70' x={x - 9} y={y + 5} text-anchor={'left'}>{month}/{day}</text>;
        }

        return '';
    };

    const topLabelYValue = -190;
    const labelSpaceY = 10;
    const labelOffsetX = 88;

    const format_label_value = (labelDictionary, key, dataKey) => {
        let value = labelDictionary[dataKey][key];
        if (dataKey === 'babip') {
            value = remove_leading_zeros(labelDictionary[dataKey][key].toFixed(3))
        } else {
            value = `${remove_leading_zeros((labelDictionary[dataKey][key] * 100).toFixed(0))}%`;
        }
        
        if (key === 'league_avg') {
            return `--- League Avg ${value}`
        }
        
        if (key === 'hitter_season_avg') {
            return `--- Season Avg ${value}`
        }

        if (key === 'hitter_x_day_avg') {
            const text = timeframe === 'L10' ? '10' : timeframe === 'L20' ? '20' : '30';
            return `--- ${text} Game Avg ${value}`
        }
        
    }

    const trendlineInfo= {slope: trendlineData.slope,  intercept: trendlineData.intercept}

    const trendlineColorMap = {
        'o_swing_percent': trendlineInfo.slope > 0 ? 'red' : 'green',
        'hardHitCount': trendlineInfo.slope > 0 ? 'green' : 'red',
        'babip': trendlineInfo.slope > 0 ? 'green' : 'red',
        'line_drives': trendlineInfo.slope > 0 ? 'green' : 'red',
    }

    const trendlineColor = trendlineColorMap[dataToShow];

    const trendline = <Line yAxisId='left' type="monotone" dataKey="trendValue" stroke={trendlineColor} dot={false} />

    const ten_day_avg_color = dataToShow === 'o_swing_percent' ? 
        labelDictionary[dataToShow].hitter_x_day_avg > labelDictionary[dataToShow].league_avg ? 'red' : 'green' : 
        labelDictionary[dataToShow].hitter_x_day_avg > labelDictionary[dataToShow].league_avg ? 'green' : 'red';

    const x_axis_labels = [
        <Label className={'text-size-60 bold'} fill={'black'} value={format_label_value(labelDictionary, 'league_avg', dataToShow)} dx={labelOffsetX} dy={topLabelYValue} offset={5} position='top' ></Label>,
        <Label className={'text-size-60 bold'} fill={hitter_season_avg_color} value={format_label_value(labelDictionary, 'hitter_season_avg', dataToShow)} dx={labelOffsetX} dy={topLabelYValue + labelSpaceY} offset={5} position='top' ></Label>,
        <Label className={'text-size-60 bold'} fill={ten_day_avg_color} value={format_label_value(labelDictionary, 'hitter_x_day_avg', dataToShow)} dx={labelOffsetX + 4} dy={topLabelYValue + 2 * labelSpaceY} offset={5} position='top' ></Label>,
    ]

    


    const x_axis_tickline = false;
    const x_axis_interval = 0;
    const x_axis = { labels: x_axis_labels, dataKey: 'date', customRenderFxn: renderXAxisTicks, tickLine: x_axis_tickline, interval: x_axis_interval };

    const basicStatsTrendChartProps = { sampleSizeDictionary, trendline, renderBabipLeftAxis, x_axis, referenceLines: averageValuesReferenceDictionary, chart_data: trendlineData.data, dataToShow, renderCustomXAxisValue, renderCustomYAxisValue: renderYAxisPercentage, setDataToShow: toggleData, dataSetDisplayInfo };
    return (
        <ActualChart {...basicStatsTrendChartProps} onClick={cycleView} title={'Advanced'}></ActualChart>
    )
}

const ActualChart = (props) => {
    const {
        chart_data,
        dataToShow,
        referenceLines,
        renderCustomXAxisValue,
        renderCustomYAxisValue: renderHardHitCountRightAxis,
        renderBabipLeftAxis,
        title,
        dataSetDisplayInfo,
        setDataToShow,
        y_axis_min,
        y_axis_max,
        eventOccurenceBarSize,
        sampleSizeBarSize,
        x_axis, 
        onClick,
        trendline,
        sampleSizeDictionary
    } = props;

    const sample_bar_size =  200 / chart_data.length ;
    const event_bar_size = sample_bar_size * .75;

    const barGap = -(sample_bar_size + event_bar_size) / 2

    const barSizeInfoDictionary = {
        'hardHitCount': { barGap: barGap, sampleSizeBarSize: sample_bar_size, eventOccurenceBarSize: event_bar_size },
        'babip': { barGap: barGap, sampleSizeBarSize: sample_bar_size, eventOccurenceBarSize: event_bar_size },
        'o_swing_percent': { barGap: barGap, sampleSizeBarSize: sample_bar_size, eventOccurenceBarSize: event_bar_size },
        'line_drives': { barGap: barGap, sampleSizeBarSize: sample_bar_size, eventOccurenceBarSize: event_bar_size },
    }


    const axisRenderDictionary = {
        'hardHitCount': { y_axis_right: renderHardHitCountRightAxis },
        'babip': { y_axis_left: renderBabipLeftAxis },
        'o_swing_percent': { y_axis_right: renderHardHitCountRightAxis },
        'line_drives': { y_axis_right: renderHardHitCountRightAxis },
    }

    const dataBarsDictionary = {
        'hardHitCount': [
            <Bar yAxisId={'left'} minPointSize={3} barSize={barSizeInfoDictionary[dataToShow]?.sampleSizeBarSize || 20} dataKey={'bipCount'} fill={'#CCC'}></Bar>,
            <Bar yAxisId={'left'} barSize={barSizeInfoDictionary[dataToShow]?.eventOccurenceBarSize || 16} dataKey={dataToShow} fill={dataSetDisplayInfo[dataToShow].color}></Bar>,
        ],
        'babip': [
            <Bar yAxisId={'right'} minPointSize={3} barSize={barSizeInfoDictionary[dataToShow]?.sampleSizeBarSize || 22} dataKey={'bipCount'} fill={'#CCC'}></Bar>,
            <Bar yAxisId={'left'} barSize={barSizeInfoDictionary[dataToShow]?.eventOccurenceBarSize || 16} dataKey={'babip'} fill={dataSetDisplayInfo[dataToShow].color}></Bar>,

        ],
        'o_swing_percent': [
            <Bar yAxisId={'left'} minPointSize={3} barSize={barSizeInfoDictionary[dataToShow]?.sampleSizeBarSize || 22} dataKey={'pitchesOutOfZone'} fill={'#CCC'}></Bar>,
            <Bar yAxisId={'left'} barSize={barSizeInfoDictionary[dataToShow]?.eventOccurenceBarSize || 16} dataKey={'o_swings'} fill={dataSetDisplayInfo[dataToShow].color}></Bar>,

        ],
        'line_drives': [
            <Bar yAxisId={'left'} minPointSize={3} barSize={barSizeInfoDictionary[dataToShow]?.sampleSizeBarSize || 22} dataKey={'bipCount'} fill={'#CCC'}></Bar>,
            <Bar yAxisId={'left'} barSize={barSizeInfoDictionary[dataToShow]?.eventOccurenceBarSize || 16} dataKey={'line_drives'} fill={dataSetDisplayInfo[dataToShow].color}></Bar>,

        ],
    }

    const dataMax =
        Math.max(...chart_data.map(x => x[dataToShow]), ...chart_data.map(x => x[sampleSizeDictionary[dataToShow]]));

    const y_ticks = dataMax === 0 ? [0,.25,.5] : Array.from({ length: dataMax + 1 }, (_, i) => i);

    const yAxisDictionary = {
        'hardHitCount': [
            <YAxis orientation="left" yAxisId={'left'} ticks={y_ticks} interval={0} domain={[0, 'dataMax']}></YAxis>,
            // <YAxis orientation="right" yAxisId={'right'} tick={axisRenderDictionary[dataToShow]?.y_axis_right} domain={[.25, .6]}></YAxis>
        ],

        'babip': [
            <YAxis orientation="right" yAxisId={'right'} tickLine={false} tick={false} axisLine={false}></YAxis>,
            <YAxis orientation="left" yAxisId={'left'} tick={axisRenderDictionary[dataToShow]?.y_axis_left} domain={[0, .75]}></YAxis>
        ],
        'o_swing_percent': [
            <YAxis orientation="left" yAxisId={'left'} ticks={y_ticks} interval={0} domain={[0, 'dataMax']}></YAxis>,
            // <YAxis orientation="right" yAxisId={'right'} tick={axisRenderDictionary[dataToShow].y_axis_right} domain={[.1, .4]}></YAxis>
        ],
        'line_drives': [
            <YAxis orientation="left" yAxisId={'left'} ticks={y_ticks} interval={0} domain={[0, 'dataMax']}></YAxis>,
            // <YAxis orientation="right" yAxisId={'right'} tick={axisRenderDictionary[dataToShow].y_axis_right} domain={[.1, .4]}></YAxis>
        ],
    }


    const xAxes = [
        <XAxis dataKey={x_axis.dataKey} tickLine={x_axis.tickLine} tick={x_axis.customRenderFxn} interval={x_axis.interval}>{x_axis.labels}</XAxis>,
    ]

    const dataSetSelectorProps = { setDataToShow, dataSetDisplayInfo, dataToShow };


    const margin = dataToShow === 'babip' ?
        {
            top: 5,
            right: -55,
            left: -25,
            bottom: -10,
        } : {
            top: 5,
            right: 0,
            left: -30,
            bottom: -10,
        }


    return (
        <ResponsiveBarChart
            onClick={onClick}
            margin={margin}
            chart_data={chart_data}
            title={title}
            barSizeInfo={barSizeInfoDictionary[dataToShow]}
            dataBars={dataBarsDictionary[dataToShow]}
            referenceLines={referenceLines[dataToShow]}
            yAxes={yAxisDictionary[dataToShow]}
            xAxes={xAxes}
            dataSetSelectorProps={dataSetSelectorProps}
            trendline={trendline}
        >
        </ResponsiveBarChart>
    );
};


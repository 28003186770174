import { Col, Row } from "reactstrap";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { calculate_week_record } from "./weekly-record-calculator";
import { display_team_record } from "../../display-helpers/stat_value_display";

export const StandingShakeups = (props) => {
    const { biggest_riser, biggest_faller } = props;

    const biggest_risers_last_week_record = calculate_week_record(biggest_riser.prev_record.matchup, biggest_riser.new_record.matchup);
    const biggest_fallers_last_week_record = calculate_week_record(biggest_faller.prev_record.matchup, biggest_faller.new_record.matchup);


    return (
        <div>
            <Row className="spacer-25"></Row>
            <Row><h6>Standings Shakeups 📈</h6></Row>
            <Row className="weekly-summary-standout">
                <Col className=" standings-shakeup riser">
                    <Row>

                        <PlayerStatDisplayCol className={'good-label'} size='sm' label='Change' value={`+${biggest_riser.delta}`}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'good-label'} size='sm' label='From' value={biggest_riser.prev_rank}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'good-label'} size='sm' label='To' value={biggest_riser.new_rank}></PlayerStatDisplayCol>

                    </Row>
                    <Row>

                        <PlayerStatDisplayCol className={'good-label'} size='xs' label='Record' value={display_team_record(biggest_risers_last_week_record)}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'good-label'} size='xs' label='Manager' value={biggest_riser.team.managers.manager.nickname}></PlayerStatDisplayCol>

                    </Row>
                    <Row>
                        <PlayerStatDisplayCol className={'good-label'}  size='sm' label='Biggest Riser' value={biggest_riser.team.name}></PlayerStatDisplayCol>
                    </Row>
                </Col>
               
                <Col className=" standings-shakeup faller soft-border-left">
                    <Row>
                        <PlayerStatDisplayCol className={'bad-label'} size='sm' label='Change' value={biggest_faller.delta}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'bad-label'} size='sm' label='From' value={biggest_faller.prev_rank}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'bad-label'} size='sm' label='To' value={biggest_faller.new_rank}></PlayerStatDisplayCol>

                    </Row>
                    <Row>

                        <PlayerStatDisplayCol className={'bad-label'} size='xs' label='Record' value={display_team_record(biggest_fallers_last_week_record)}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'bad-label'} size='xs' label='Manager' value={biggest_faller.team.managers.manager.nickname}></PlayerStatDisplayCol>

                    </Row>
                    <Row>
                        <PlayerStatDisplayCol className={'bad-label'} size='sm' label='Biggest Faller' value={biggest_faller.team.name}></PlayerStatDisplayCol>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

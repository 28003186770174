import { useState } from "react";
import { Col, Row } from "reactstrap";
import { LeagueRankingStats } from "./LeagueRankingStats";
import { SeasonRanksViewSwitcherButtonGroup } from "./SeasonRanksViewSwitcherButtonGroup";

export const SeasonRanksByCategory = (props) => {
    const [viewSelected, setViewSelected] = useState('rank');
    const { stat_categories, selectedStat, setSelectedStat, weekByWeekStats, subsetView, team_record } = props;

    if (!stat_categories?.stats) {
        return <div></div>;
    }

    const toggleViewSelected = (e) => {
        e.preventDefault();
        if (viewSelected === 'rank') {
            setViewSelected('matchup');
        } else if (viewSelected === 'matchup') {
            setViewSelected('vs_league');
        } else if (viewSelected === 'vs_league') {
            setViewSelected('totals')
        } else {
            setViewSelected('rank')
        }
    };

    const totals = {wins: 0, losses: 0, ties: 0};
    team_record.record.forEach(rec => {
        totals.wins += rec.vs_league.wins
        totals.losses += rec.vs_league.losses
        totals.ties += rec.vs_league.ties
    })
  
    const subset_selected = weekByWeekStats.season_subtotals.find(x => subsetView === x.subset) || weekByWeekStats.season_subtotals.slice(-1)[0];

    const stat_groups = stat_categories.groups.group;

    const stat_group_names = stat_groups.map(x => x.group_name);

    const title = viewSelected === 'rank' ? "My Team's Rankings" : viewSelected == 'matchup' ? "Record vs My Opp" : viewSelected === 'totals' ? 'Stat Totals' : "Record vs League"

    return (
        <>
            
            <Row className="text-align-center margin-5">
                <Col></Col>
                <Col xs='7'><h6>{title}</h6></Col>
                <Col className="text-align-center">
                    <SeasonRanksViewSwitcherButtonGroup toggleViewSelected={toggleViewSelected} viewSelected={viewSelected}></SeasonRanksViewSwitcherButtonGroup>
                </Col>
            </Row>
            <LeagueRankingStats selectedStat={selectedStat} team_record={team_record} stat_categories={stat_categories} setSelectedStat={setSelectedStat} stat_group_names={stat_group_names} viewSelected={viewSelected} subset_selected={subset_selected}></LeagueRankingStats>
            {/* <Row className="text-align-center">
                {totals.wins} - {totals.losses} - {totals.ties}
            </Row> */}
        </>
    );
};



import axios from "axios";
import { createErrorResponse, createSuccessResponse } from "./api-response";
import getApi from "./axios/api";


export async function getTeamStats() {
    try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const resp = await axios.get(`${baseUrl}/teamStats`);
        return createSuccessResponse(resp.data);
    } catch (err) {
        console.error(err);
        return createErrorResponse(err);
    }
}

export async function getTeamGameLog(userToken, refreshUserToken, teamAbbr) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/teamStats/gameLog/${teamAbbr}`);
    return resp;
}

export async function getTeamStatRankings(userToken, refreshUserToken) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/teamStats/statRankings`);
    return resp;
}

export async function getTeamHittingSplits(userToken, refreshUserToken) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/teamStats/seasonSplits`);
    return resp;
}
import { Button } from "reactstrap";
import DatePicker from "react-datepicker";
import { useState } from "react";

export const AddDropTimeSelector = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const { desiredRosteredDate, setDesiredRosteredDate, setHasDateBeenSelected } = props;


    const handleChange = (e) => {
        const oldTime = desiredRosteredDate ? desiredRosteredDate.getHours() * 60 + desiredRosteredDate.getMinutes() : null;
        const newTime = e.getHours() * 60 + e.getMinutes();

        if (oldTime !== newTime) {
            setIsOpen(false);
            setHasDateBeenSelected(true);
        } else {
        }
        setDesiredRosteredDate(e);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
        setHasDateBeenSelected(false);
    };

    function formatDate(date) {
        // Days of the week
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Months are 0-indexed in JavaScript
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        const dayOfWeek = days[date.getDay()];

        // Hours and minutes
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // AM/PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'


        // Format the date string
        const formattedDate = `${dayOfWeek} ${month}/${day} ${hours}:${minutes} ${ampm}`;

        return formattedDate;
    }

    return (
        <div className="margin-5">
            <Button color='primary' onClick={handleClick}>{formatDate(desiredRosteredDate)}</Button>
            {/* <DatePicker timeIntervals={15}  className="text-align-center" selected={desiredRosteredDate} onChange={handleChange} showTimeSelect dateFormat="E MM/d h:mm aa"></DatePicker> */}
            {isOpen && <DatePicker timeIntervals={15} inline selected={desiredRosteredDate} onChange={handleChange} showTimeSelect dateFormat="E MM/d h:mm aa"></DatePicker>}
        </div>
    );

};

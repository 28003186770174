import getApi from "./axios/api";

export async function getLeagueMatchupInfo(userToken, refreshUserToken, leagueId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/leagueMatchups/${leagueId}`);
    return resp;
}

export async function getLeagueStats(userToken, refreshUserToken, leagueId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/leagueMatchups/leagueSeasonStats/${leagueId}`);
    return resp;
}

export async function getWeekByWeek(userToken, refreshUserToken, leagueId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/leagueMatchups/leagueWeekByWeekStats/${leagueId}`);
    return resp;
}
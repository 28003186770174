import { Col, Row } from "reactstrap";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { display_team_record } from "../../display-helpers/stat_value_display";

export const LuckyReport = (props) => {
    const { lucky_wins } = props;
   
    if (!lucky_wins) {
        return null;
    }

    const groups = lucky_wins.map((group, index) => {

        const stats = group.results.map(stat => {

            const should_show_lucky = stat.luckiest_win.stat.vs_league.wins <= stat.luckiest_win.stat.vs_league.losses;

            const should_show_unlucky = stat.unluckiest_loss.stat.vs_league.wins >= stat.unluckiest_loss.stat.vs_league.losses;

            const unlucky_render = (<Col><p className="text-size-70 ">No unlucky losers</p></Col>)
            const lucky_render = (<Col><p className="text-size-70 ">No lucky winners</p></Col>)

            const conditional_render = (should_show, show_if_true, show_if_false) => {
                if (should_show) {
                    return show_if_true;
                }
                return show_if_false;
            }

            return (
                <>
                    <Col className="lucky-col">
                        <Row className="justify-center text-align-center text-size-90"><Col xs='1'></Col><Col className="bottom-border">{stat.stat.name}</Col><Col xs='1'></Col></Row>

                        {/* <Row>
                            <PlayerStatDisplayCol className='good-label' size='sm' label='luckiest 🍀' value={stat.luckiest_win.team.name}></PlayerStatDisplayCol>
                            <PlayerStatDisplayCol className='bad-label' size='sm' label='unluckiest 💔' value={stat.unluckiest_loss.team.name}></PlayerStatDisplayCol>
                        </Row> */}
                        <Row>
                            {conditional_render(should_show_lucky, <PlayerStatDisplayCol className='good-label' size='sm' label='luckiest' value={stat.luckiest_win.team.name}></PlayerStatDisplayCol>, lucky_render)}
                            {conditional_render(should_show_unlucky, <PlayerStatDisplayCol className='bad-label' size='sm' label='unluckiest' value={stat.unluckiest_loss.team.name}></PlayerStatDisplayCol>, unlucky_render)}
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    {conditional_render(should_show_lucky, <PlayerStatDisplayCol className='good-label' size='xs' label='won with' value={`${stat.luckiest_win.stat.stat.value} ${stat.stat.display_name}`}></PlayerStatDisplayCol>, null)}
                                    {conditional_render(should_show_lucky, <PlayerStatDisplayCol className='good-label' size='xs' label='vs League' value={display_team_record(stat.luckiest_win.stat.vs_league)}></PlayerStatDisplayCol>, null)}

                                </Row>
                            </Col>
                            <Col className="soft-border-lef">

                                <Row>
                                    {conditional_render(should_show_unlucky,
                                        <PlayerStatDisplayCol className='bad-label' size='xs' label='lost with' value={`${stat.unluckiest_loss.stat.stat.value} ${stat.stat.display_name}`}></PlayerStatDisplayCol>
                                        , null)}
                                    {conditional_render(should_show_unlucky,
                                        <PlayerStatDisplayCol className='bad-label' size='xs' label='vs League' value={display_team_record(stat.unluckiest_loss.stat.vs_league)}></PlayerStatDisplayCol>
                                        , null)}
                                </Row>
                            </Col>
                        </Row>
                        <Row className="spacer-15 ">
                            <Col></Col>
                            <Col className="border-"></Col>
                            <Col className="border-"></Col>
                            <Col className="border-"></Col>
                            <Col className="border-"></Col>
                            <Col className="border-"></Col>
                            <Col className="border-"></Col>
                            <Col></Col>
                        </Row>

                    </Col>


                </>
            )
        })


        const title = group.group == 'batting' ? 'Lucky/Unlucky Hitting' : 'Lucky/Unlucky Pitching'
        return (
            <Row className="">
                <Col>
                    <Row className=""><h6>{title} 🍀</h6></Row>
                    <Row className="justify-center text-align-center weekly-summary-standout">{stats}</Row>
                    <Row className="spacer-25"></Row>
                </Col>
            </Row>
        )

    })

    return (
        <div>
            {groups}
        </div>
    );

};

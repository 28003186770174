import { getUserLeagues } from "../../api/user-leagues";
import { handleUnauthorized } from "../errors/handle-unauthorized";



export async function get_user_leagues_async(authToken, setUserLeagues, logout, refreshUserToken) {
    const userLeaguesResponse = await getUserLeagues(authToken, refreshUserToken);
    if (userLeaguesResponse.success) {
        setUserLeagues(userLeaguesResponse.data)
    } else {
        handleUnauthorized(userLeaguesResponse.error, logout)
        console.log(`Something went wrong getting user leagues`)
    }
}
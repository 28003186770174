import React, { useState } from 'react';
import { Alert } from 'reactstrap';



const ErrorAlert = (props) => {
    const [visible, setVisible] = useState(true);
    const {clearErrors, messages, renderInElement} = props;

    if (!messages?.length > 0) {
        return null;
    }

    const onDismiss = () => {
        clearErrors();
        // setVisible(false)
    };
    const displayMessages = messages.map((message, index) => {
        return (<p key={index}>{message}</p>)
    })

    return (
        <div className={`text-align-center z-index-1000 ${renderInElement ? '' : 'centered-on-screen'}`}>
            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                {displayMessages}
            </Alert>
        </div>
    );
}

export default ErrorAlert;
import { Table } from "reactstrap";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";
import { sort_teams_by_matchup_win_percent } from "./weekly-summary-container";

export const LeagueStandings = (props) => {

    const { team_records, rank_changes } = props;
    // console.log(team_records)
    // console.log(rank_changes)
    const team_records_sorted_by_win_percent = sort_teams_by_matchup_win_percent(team_records);
    // console.log(team_records_sorted_by_win_percent)
    const table_rows = team_records_sorted_by_win_percent.map((team, index) => {
        const team_rank_change_info = rank_changes.find(x => x.team.team_id == team.team.team_id);
        const team_rank_delta = team_rank_change_info.delta;
        return (
            <tr>
                <td>{index + 1}</td>
                <td>{team.team.name}</td>
                <td>{team_rank_delta > 0 ? `+${team_rank_delta}` : team_rank_delta}</td>
                <td>{team.record.matchup.wins} - {team.record.matchup.losses} - {team.record.matchup.ties}</td>
                <td>{remove_leading_zeros(team.matchup_win_percent.toFixed(3))}</td>
            </tr>
        );
    });
    return (
        <div>
            <Table size='sm' className="text-size-70 weekly-summary-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Team</th>
                        <th>Diff</th>
                        <th>Record</th>
                        <th>Win %</th>
                    </tr>
                </thead>
                <tbody>
                    {table_rows}
                </tbody>
            </Table>
        </div>
    );
};

import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth-context";
import { useLeagueSelector } from "../../context/league-selector-context";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import { UserLeagueDropdown } from "../user-leagues/user-league-dropdown";
import { getWeekByWeek } from "../../api/league-matchups";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { get_league_settings } from "../../api/yahoo-integration-info";
import { Col, Row } from "reactstrap";
import { LeagueRankingStats } from "../strategy-builder/LeagueRankingStats";
import { SeasonRanksSubsetButtonGroup } from "../strategy-builder/SeasonRanksSubsetButtonGroup";
import { SeasonRanksViewSwitcherButtonGroup } from "../strategy-builder/SeasonRanksViewSwitcherButtonGroup";
import { calculate_weekly_scoreboard_details, get_subset_scoreboard_details } from "../strategy-builder/weekly_scoreboard_details";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { WeeklyStatBreakdown } from "../strategy-builder/WeeklyStatBreakdown";

const LeagueResearcher = (props) => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();
    const [userLeagues, setUserLeagues] = useState([]);
    const [weekByWeekStats, setWeekByWeekStats] = useState();
    const [yahooLeagueSettings, setYahooLeagueSettings] = useState();
    const [selectedStat, setSelectedStat] = useState();
    const [subsetView, setSubsetView] = useState('season');
    const [weekly_scoreboard, setWeeklyScoreboard] = useState();
    const [seasonStatsView, setSeasonStatsView] = useState('rank');

    useEffect(() => {
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);

        if (selectedLeague) {
            const getWeekByWeekAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await getWeekByWeek(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setWeekByWeekStats(result.data.weekly_stats);
                    setWeeklyScoreboard(result.data.weekly_scoreboard);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.error(result.error)
                }
            }

            getWeekByWeekAsync(userToken, refreshUserToken, selectedLeague.league_id)

            const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_settings(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setYahooLeagueSettings(result.data);
                    const defaultStat = result.data.settings?.stat_categories.stats.stat[1];
                    if (defaultStat) {
                        setSelectedStat(defaultStat);
                    }

                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }

            getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id)
        }
    }, [selectedLeague])

    const toggleViewSelected = (e) => {
        e.preventDefault();
        if (seasonStatsView === 'rank') {
            setSeasonStatsView('matchup');
        } else if (seasonStatsView === 'matchup') {
            setSeasonStatsView('vs_league');
        } else if (seasonStatsView === 'vs_league') {
            setSeasonStatsView('totals')
        } else {
            setSeasonStatsView('rank')
        }
    };




    const stat_categories = yahooLeagueSettings?.settings?.stat_categories;

    const scoreboard_details = calculate_weekly_scoreboard_details(weekly_scoreboard, weekByWeekStats);
    const scoreboard_details_for_subset = get_subset_scoreboard_details(scoreboard_details, subsetView);


    let charts = <div></div>

    if (stat_categories) {
        charts = weekByWeekStats?.map((weeklyStats, index) => {
            const subset_selected = weeklyStats.season_subtotals.find(x => subsetView === x.subset) || weeklyStats.season_subtotals.slice(-1)[0];

            const stat_groups = stat_categories.groups.group;

            const stat_group_names = stat_groups.map(x => x.group_name);

            const this_teams_scoreboard_details_for_subset = scoreboard_details_for_subset.find(t => t.team.team_id == weeklyStats.team.team_id);

            const totals = { matchup: { wins: 0, losses: 0, ties: 0 }, vs_league: { wins: 0, losses: 0, ties: 0 } };
           
            this_teams_scoreboard_details_for_subset?.record.forEach(rec => {
                totals.matchup.wins += rec.matchup.wins
                totals.matchup.losses += rec.matchup.losses
                totals.matchup.ties += rec.matchup.ties
            })

            this_teams_scoreboard_details_for_subset?.record.forEach(rec => {
                totals.vs_league.wins += rec.vs_league.wins
                totals.vs_league.losses += rec.vs_league.losses
                totals.vs_league.ties += rec.vs_league.ties
            })


            const matchup_scores = weekly_scoreboard?.map(week => {
                const weekNum = week.week;
                const my_matchup = week.scoreboard.find(s => s.teams.some(t => t.team_id == weeklyStats.team.team_id));
                return { week: weekNum, matchup_result: my_matchup }
            })


            const stat_id = selectedStat?.stat_id || 7;

            let stat_specific_results = matchup_scores.map(m => {
                const week = m.week;
                if (!m.matchup_result) {
                    return null;
                }
                const stat_we_care_about = m.matchup_result.stat_winners.find(sw => sw.stat_id == stat_id);
                const wasTied = stat_we_care_about.is_tied == 1;
                let result = '';
                if (m.matchup_result.status === 'midevent') {
                    result = null;
                } else if (wasTied) {
                    result = 'tie'
                } else {
                    const winner_key_parts = stat_we_care_about.winner_team_key.split('.');
                    result = winner_key_parts.slice(-1) == weeklyStats.team.team_id ? 'win' : 'loss';
                }
                return { week, result };
            })

            stat_specific_results = stat_specific_results.filter(x => x != null);


            const win_percent_vs_league = ((totals.vs_league.wins + .5 * totals.vs_league.ties) / (totals.vs_league.wins + totals.vs_league.losses + totals.vs_league.ties)) * 100

            const win_percent_vs_opponent = (((totals.matchup.wins + .5 * totals.matchup.ties) / (totals.matchup.wins + totals.matchup.losses + totals.matchup.ties)) * 100);

            const color = win_percent_vs_league < win_percent_vs_opponent ? 'green' : 'red'
            return (
                <Col key={index} className=' solid-border league-research-league-stat-rankings'>
                    <Row className="justify-center">
                        {weeklyStats.team.name}
                    </Row>
                    <Row className="text-align-center season-ranks">
                        <LeagueRankingStats selectedStat={selectedStat} team_record={this_teams_scoreboard_details_for_subset} stat_categories={stat_categories} setSelectedStat={setSelectedStat} stat_group_names={stat_group_names} viewSelected={seasonStatsView} subset_selected={subset_selected}></LeagueRankingStats>
                    </Row>
                    <Row className="text-align-center justify-center">
                        <PlayerStatDisplayCol className={'sm'} label='vs League' value={`${totals.vs_league.wins} - ${totals.vs_league.losses} - ${totals.vs_league.ties}`}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={'sm'} label='vs Opp' value={`${totals.matchup.wins} - ${totals.matchup.losses} - ${totals.matchup.ties}`}></PlayerStatDisplayCol>
                    </Row><Row>
                        <PlayerStatDisplayCol className={'sm'} label='Win % vs League' value={`${win_percent_vs_league.toFixed(1)}%`}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol className={`${color} sm`} label='Win % vs Opp' value={`${win_percent_vs_opponent.toFixed(1)}%`}></PlayerStatDisplayCol>



                    </Row>
                    <Row>
                        <WeeklyStatBreakdown key={index} selectedLeague={selectedLeague} week_by_week_stats={weekByWeekStats} subset_selected_key={subsetView} team_matchup={matchup_scores} stat_specific_results={stat_specific_results} team_weekly_ranks={weeklyStats.weekly_stat_rankings} selectedStat={selectedStat} league_weekly_ranks={weekByWeekStats} team_id={weeklyStats.team.team_id}></WeeklyStatBreakdown>
                    </Row>
                </Col>
            )
        })

    }


    return (
        <div className="fit-better">
            <Row>
                <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
            </Row>
            {/* <Row>
                {weekByWeekStats && <LeagueWideLineChart selectedStat={selectedStat} week_by_week_stats={weekByWeekStats}></LeagueWideLineChart>}
            </Row> */}
            {selectedLeague && <>
                <Row>

                </Row>

                <Row className="text-align-center season-rank-subset-btn-group sticky-top">
                    <Col>
                        <SeasonRanksSubsetButtonGroup subsetView={subsetView} setSubsetView={setSubsetView}></SeasonRanksSubsetButtonGroup>
                    </Col>
                    <Col>
                        <SeasonRanksViewSwitcherButtonGroup toggleViewSelected={toggleViewSelected} viewSelected={seasonStatsView}></SeasonRanksViewSwitcherButtonGroup>
                    </Col>
                </Row></>}
            <Row className="text-align-center justify-center">

                {charts}

            </Row>
        </div>
    )
}

export default LeagueResearcher;



import { Table } from "reactstrap";
import { getLogoId } from "../../mlb-team-logos/logo-mapper";
import { convertTimezone, convertTo12Hour } from "../../dates/date-helper";
import { getParkFactor } from "../../data/park-factors";
import { timeZoneDisplayMap } from "../../display-helpers/timezone-display-map";


const WeatherGame = (props) => {

    console.log(props)

    if (!props.game?.weather?.length > 0) {
        return <div>Weather not available.</div>
    }


    const parkFactor = getParkFactor(props.game.homeTeam.teamAbbr);

    const hours = props.game.weather;
    
    return (
        <div>
            <div className="weather-game-header">
                <div>
                {/* <img className="weather-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(props.game.awayTeam.teamAbbr)}.svg`}></img>
                {` @ `} */}
                <img className="weather-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(props.game.homeTeam.teamAbbr)}.svg`}></img>
                </div>
                <div>{parkFactor?.Venue}</div>
                {props.game.date} {convertTimezone(props.game.gameTime.time, props.game.gameTime.tz, props.game.stadium_timezone )} 
                
                {` (${timeZoneDisplayMap[props.game.stadium_timezone]})`} 
            </div>
            <WeatherTable rowsToShow={["Conditions", "Temp", "Feels Like", "Precip %", "Wind", "Wind Dir"]} hours={hours}></WeatherTable>
        </div>
    )
}

export default WeatherGame;


export const WeatherTable = (props) => {
    const {hours, className, rowsToShow} = props;

    const show2HourBuffer = false;
    const show1HourBuffer = true;

    if (!show1HourBuffer && show2HourBuffer) {
        console.log('Invalid display state for weather table')
    }

    


    const tableHeaders = hours.map((hour, index) => {
        if (!show2HourBuffer && (index == 0 || index == 6)) {
            return null;
        }

        if (!show1HourBuffer && (index == 1 || index == 5)) {
            return null;
        }
        return <th key={index} className="">{convertTo12Hour(hour.time_local.slice(11, 16), false)}</th>
    })

    const firstCellMapper = { 0: "Conditions", 1: "Temp", 2: "Feels Like", 3: "Precip %", 4: "Wind", 5: "Wind Dir" }
    return (
        <Table className={` text-align-center ${className} weather-table`} size="sm" bordered>
                <thead>
                <tr className="">
                    <th></th>
                    {tableHeaders}
                </tr>
                </thead>
                <tbody>
                {rowsToShow.includes(firstCellMapper[0]) && <tr>
                    <td className="table-constant">{/*firstCellMapper[0]*/}</td>
                    {show2HourBuffer && <td><img className="weather-condition-icon" src={hours[0].condition.icon}></img> 
                    {hours[0].condition.description}
                    </td>}
                    {show1HourBuffer && <td className='weather-condition-cell'><img className="weather-condition-icon" src={hours[1].condition.icon}></img> {hours[1].condition.description}</td>}
                    <td className="weather-condition-cell game-time-hours"><img className="weather-condition-icon" src={hours[2].condition.icon}></img> {hours[2].condition.description}</td>
                    <td className="weather-condition-cell game-time-hours"><img className="weather-condition-icon" src={hours[3].condition.icon}></img> {hours[3].condition.description}</td>
                    <td className="weather-condition-cell game-time-hours"><img className="weather-condition-icon" src={hours[4].condition.icon}></img> {hours[4].condition.description}</td>
                    {show1HourBuffer && <td className='weather-condition-cell'><img className="weather-condition-icon" src={hours[5].condition.icon}></img> {hours[5].condition.description}</td>}
                    {show2HourBuffer && <td className='weather-condition-cell'><img className="weather-condition-icon" src={hours[6].condition.icon}></img> {hours[6].condition.description}</td>}
                </tr>}
                {rowsToShow.includes(firstCellMapper[1]) && <tr>
                    <td className="table-constant">{firstCellMapper[1]}</td>
                    {show2HourBuffer && <td>{hours[0].temp_f}</td>}
                    {show1HourBuffer && <td>{hours[1].temp_f}</td>}
                    <td className="game-time-hours">{hours[2].temp_f}</td>
                    <td className="game-time-hours">{hours[3].temp_f}</td>
                    <td className="game-time-hours">{hours[4].temp_f}</td>
                    {show1HourBuffer && <td>{hours[5].temp_f}</td>}
                    {show2HourBuffer && <td>{hours[6].temp_f}</td>}
                </tr>}
                {rowsToShow.includes(firstCellMapper[2]) && <tr>
                    <td className="table-constant">{firstCellMapper[2]}</td>
                    {show2HourBuffer && <td>{hours[0].feels_like_f}</td>}
                    {show1HourBuffer && <td>{hours[1].feels_like_f}</td>}
                    <td className="game-time-hours">{hours[2].feels_like_f}</td>
                    <td className="game-time-hours">{hours[3].feels_like_f}</td>
                    <td className="game-time-hours">{hours[4].feels_like_f}</td>
                    {show1HourBuffer && <td>{hours[5].feels_like_f}</td>}
                    {show2HourBuffer && <td>{hours[6].feels_like_f}</td>}
                </tr>}
                {rowsToShow.includes(firstCellMapper[3]) && <tr>
                    <td className="table-constant">{firstCellMapper[3]}</td>
                    {show2HourBuffer && <td>{hours[0].chance_of_rain}</td>}
                    {show1HourBuffer && <td>{hours[1].chance_of_rain}</td>}
                    <td className="game-time-hours">{hours[2].chance_of_rain}</td>
                    <td className="game-time-hours">{hours[3].chance_of_rain}</td>
                    <td className="game-time-hours">{hours[4].chance_of_rain}</td>
                    {show1HourBuffer && <td>{hours[5].chance_of_rain}</td>}
                    {show2HourBuffer && <td>{hours[6].chance_of_rain}</td>}
                </tr>}
                {rowsToShow.includes(firstCellMapper[4]) && <tr>
                    <td className="table-constant">{firstCellMapper[4]}</td>
                    {show2HourBuffer && <td>{hours[0].wind.speed_mph} {hours[0].wind.direction}</td>}
                    {show1HourBuffer && <td>{hours[1].wind.speed_mph} {hours[1].wind.direction}</td>}
                    <td className="game-time-hours">{hours[2].wind.speed_mph} {hours[2].wind.direction}</td>
                    <td className="game-time-hours">{hours[3].wind.speed_mph} {hours[3].wind.direction}</td>
                    <td className="game-time-hours">{hours[4].wind.speed_mph} {hours[4].wind.direction}</td>
                    {show1HourBuffer && <td>{hours[5].wind.speed_mph} {hours[5].wind.direction}</td>}
                    {show2HourBuffer && <td>{hours[6].wind.speed_mph} {hours[6].wind.direction}</td>}
                </tr>}
                {/* {rowsToShow.includes(firstCellMapper[5]) && <tr>
                    <td className="table-constant">{firstCellMapper[5]}</td>
                    {show2HourBuffer && <td>{hours[0].wind.direction}</td>}
                    {show1HourBuffer && <td>{hours[1].wind.direction}</td>}
                    <td className="game-time-hours">{hours[2].wind.direction}</td>
                    <td className="game-time-hours">{hours[3].wind.direction}</td>
                    <td className="game-time-hours">{hours[4].wind.direction}</td>
                    {show1HourBuffer && <td>{hours[5].wind.direction}</td>}
                    {show2HourBuffer && <td>{hours[6].wind.direction}</td>}
                </tr>} */}
                </tbody>
            </Table>
    )
}
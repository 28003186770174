import axios from 'axios'
import { createErrorResponse, createSuccessResponse } from './api-response';
import getApi from './axios/api';


export async function getLiveGameData() {
    try {
        const url = 'https://statsapi.mlb.com/api/v1.1/game/717909/feed/live';
        const response = await axios.get(url);
        return createSuccessResponse(response.data);
    } catch (error) {
        console.log(error);
        return createErrorResponse(error);
    }
}

export async function getRosteredPlayers(userToken, refreshUserToken) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/gamecast/allRosteredPlayers`);
    return resp;
}


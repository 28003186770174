import { useEffect, useState } from "react";
import { getWatchedPlayers, removeFromWatchlistEntry } from "../../api/watchlist";
import { Col, Row } from "reactstrap";
import WeatherGame from "../game-weather/weather-game";
import BattingLineup from "./batting-lineup";
import WatchlistPanel from "./watchlist-panel";
import PitcherInfo from "./pitcher-info";
import { useAuthContext } from "../../context/auth-context";
import { handleUnauthorized } from '../errors/handle-unauthorized'
import { UserLeagueDropdown } from "../user-leagues/user-league-dropdown";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import { NoLeagueConnectedInstructions } from "../user-leagues/NoLeagueConnectedInstructions";
import { useLeagueSelector } from "../../context/league-selector-context";



const WatchlistContainer = (props) => {

    const [watchlistPlayers, setWatchlistPlayers] = useState();
    const [visiblePitcher, setVisiblePitcher] = useState();
    const [hasUpdated, setHasUpdated] = useState(false)
    const [userLeagues, setUserLeagues] = useState([]);
    const { user,userToken, refreshUserToken, logout } = useAuthContext();
    const {selectedLeague, setSelectedLeague} = useLeagueSelector();

    useEffect(() => {
        if (user) {
            const getWatchlist = async (leagueId, userToken, refreshUserToken) => {
                const result = await getWatchedPlayers(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setWatchlistPlayers(result.data);

                } else {
                    handleUnauthorized(result.error, logout)
                    console.error(result)
                }
            }

            if (selectedLeague?.league_id) {
                getWatchlist(selectedLeague.league_id, userToken, refreshUserToken);
            }

            get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken)

        }

    }, [hasUpdated, selectedLeague])

    const showPlayerList = league => {
        setWatchlistPlayers(null)
        setVisiblePitcher(null)

        setSelectedLeague(league);

    }

    const removeFromWatchlist = async (args) => {
        const removeResponse = await removeFromWatchlistEntry(userToken, refreshUserToken, args);
        if (removeResponse.success) {
            setVisiblePitcher(null)
            setHasUpdated(!hasUpdated)
        } else {
            handleUnauthorized(removeResponse.error, logout)
            console.error(removeResponse.error)
        }
    }

    if (!watchlistPlayers?.length > 0 || !selectedLeague?.league_id) {
        return (
            <div className="App">
                <Row>
                    <Col Col lg="3" className="side-panel">
                        <LeagueSelectorDropDown userLeagues={userLeagues} onLeagueSelection={showPlayerList} selectedLeague={selectedLeague}></LeagueSelectorDropDown>
                    </Col>
                </Row>
            </div>

        )
    }

    const daysWithGames = watchlistPlayers.filter(wp => wp.games.length !== 0);
    let gameToShow = {};
    if (visiblePitcher) {
        const gameDateWithDesiredPitcher = daysWithGames.find(gameDateObj =>
            gameDateObj.games.some(gameObj =>
                gameObj.awayPitcher?.name?.full === visiblePitcher ||
                gameObj.homePitcher?.name?.full === visiblePitcher
            )
        );

        const awayPitcherGame = gameDateWithDesiredPitcher.games.find(g => g.awayPitcher?.name?.full === visiblePitcher)

        const homePitcherGame = gameDateWithDesiredPitcher.games.find(g => g.homePitcher?.name?.full === visiblePitcher)

        const game = awayPitcherGame ? awayPitcherGame : homePitcherGame;

        let pitcher = {};
        let isPitcherHome = false;
        if (homePitcherGame) {
            pitcher = game.homePitcher
            isPitcherHome = true;
        } else {
            pitcher = game.awayPitcher
        }
        gameToShow = <WatchedPlayer visiblePitcher={visiblePitcher} game={game}></WatchedPlayer>
    } else {
        gameToShow = <div>Click on a Player</div>

    }

    return (
        <div className='App'>
            <Row>
                <Col lg="3" className="side-panel">
                    <LeagueSelectorDropDown userLeagues={userLeagues} onLeagueSelection={showPlayerList} selectedLeague={selectedLeague}></LeagueSelectorDropDown>
                    <Row>
                        <WatchlistPanel visiblePitcher={visiblePitcher} setVisiblePitcher={setVisiblePitcher} leagueId={selectedLeague.league_id} watchlist={watchlistPlayers} removeFromWatchlist={removeFromWatchlist}></WatchlistPanel>
                    </Row>
                </Col>
                <Col>
                    {gameToShow}
                </Col>
            </Row>
        </div>
    )
}

export default WatchlistContainer;

const LeagueSelectorDropDown = (props) => {
    const {userLeagues, onLeagueSelection, selectedLeague} = props;
    return (
        <Row className="margin-5">
            <UserLeagueDropdown userLeagues={userLeagues} onLeagueSelection={onLeagueSelection} selectedLeague={selectedLeague}></UserLeagueDropdown>
            {!(userLeagues?.length > 0) && <NoLeagueConnectedInstructions></NoLeagueConnectedInstructions>}
        </Row>
    )
}

const WatchedPlayer = (props) => {
    let pitcher = {};
    let isPitcherHome = false;
    if (props.game.homePitcher) {
        pitcher = props.game.homePitcher
        isPitcherHome = true;
    } else {
        pitcher = props.game.awayPitcher
    }

    return (
        <div>
            <Row>
                <Col sm="4">
                    <PitcherInfo pitcher={pitcher}></PitcherInfo>
                </Col>
                <Col>
                    <Row>
                        <BattingLineup pitcher={pitcher} lineup={isPitcherHome ? props.game.awayBattingOrder : props.game.homeBattingOrder}></BattingLineup>
                    </Row>
                    <Row><WeatherGame game={props.game}></WeatherGame></Row>
                </Col>
            </Row>
        </div>
    )
}


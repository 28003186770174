import { Row, Col } from "reactstrap"
import WeatherGame, { WeatherTable } from "../game-weather/weather-game";
import { useState } from "react";
import { BasicStatsDisplay } from "./BasicStatsDisplay";
import { PitcherSplitsMultiView } from "./PitcherSplitsMultiView";
import { getLogoId } from "../../mlb-team-logos/logo-mapper";
import { convertTimezone, formatDateToMonthSlashDay } from "../../dates/date-helper";
import { timeZoneDisplayMap } from "../../display-helpers/timezone-display-map";



export const MatchupSummary = (props) => {

    const { className, gameDate, opposingPitcher, gameDetails, parkInfo, hitter, hitterHomeGame, leagueWideStats, isLoading, id, tabIndex } = props;
    
    const pitcherBasicStats = opposingPitcher?.stats;

    const basicStatsToShow = [
        {
            key: 'innings_pitched',
            display: 'IP',
            predisplay_func: (val) => { return val || 0 }

        },
        {
            key: 'era',
            display: 'ERA',
            predisplay_func: (val) => { return val?.toFixed(2) || 0 }

        },
        {
            key: 'batting_avg_against',
            display: 'BAA',
            predisplay_func: (val) => { return val?.toFixed(3).toString().substring(1) || 0 }

        },
        {
            key: 'home_runs',
            display: 'HR',
            predisplay_func: (val) => { return val || 0 }


        }
    ]

    const [pitcherSplitsActiveView, setPitcherSplitsActiveView] = useState('season');

    const togglePitcherSplitsActiveView = () => {
        if (pitcherSplitsActiveView == 'season') {
            setPitcherSplitsActiveView('career')
        } else {
            setPitcherSplitsActiveView('season')
        }
    }

    const hitterHand = hitter.handedness.batting_side;

    const pitcherThrowWinLosses = opposingPitcher?.Name ? `${opposingPitcher.throwHand}HP (${opposingPitcher.stats?.W ?? 0}-${opposingPitcher.stats?.L ?? 0}, ${opposingPitcher.stats?.ERA?.toFixed(2) ?? '--'})` : "Unknown"

    const pitcherImgUrl = `https://img.mlbstatic.com/mlb-photos/image/upload/d_people:generic:headshot:silo:current.png/r_max/w_180,q_auto:best/v1/people/${opposingPitcher?.pitcher_splits?.mlb_player_id}/headshot/silo/current`
    const dateDisplay = formatDateToMonthSlashDay(gameDate);
    const gameTime = convertTimezone(gameDetails.gameTime?.time, gameDetails.gameTime?.tz, gameDetails.stadium_timezone);
    const timeZone = timeZoneDisplayMap[props.gameDetails.stadium_timezone];
    return (
        <div id={id} tabIndex={tabIndex} className={`matchup-summary-container solid-border ${className}`}>
            <Row className='text-size-70 justify-center'>{dateDisplay} {gameTime} {timeZone}</Row>
            <Row className='solid-border-temp'>
                <Col className="player-info text-align-left solid-border-temp">
                    <Row className='name'><span className='bold'>{opposingPitcher?.Name}</span></Row>
                    <Row className='text-size-70'>{pitcherThrowWinLosses}</Row>
                </Col>
            <Col className='matchup-summary-pitcher-headshot'>
                    <Row>
                        <img className='matchup-summary-pitcher-headshot solid-border-temp' src={pitcherImgUrl}></img>
                    </Row>
                </Col>
            </Row>
            <Row>
                <BasicStatsDisplay statsToShow={basicStatsToShow} stats={pitcherBasicStats} leagueWidePitcherStats={leagueWideStats.pitcher}></BasicStatsDisplay>
            </Row>
            <Row className='solid-border-temp'>
                <PitcherSplitsMultiView isLoading={isLoading} hitterHand={hitterHand} hitterHomeGame={hitterHomeGame} activeView={pitcherSplitsActiveView} onClick={togglePitcherSplitsActiveView} splits={opposingPitcher?.pitcher_splits?.splits} hitterHand={hitterHand} hitterHomeGame={hitterHomeGame}></PitcherSplitsMultiView>
            </Row>
            <Row><ParkAndWeatherInfo {...parkInfo} gameDetails={gameDetails}></ParkAndWeatherInfo></Row>

        </div>
    )
}

const ParkAndWeatherInfo = (props) => {
    const {weather, homeTeam} = props.gameDetails;

    return (
        <Col className="bottom-border">
            <Row className="border-top">
                {/* <Col xs='1'><img className="hitting-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(props.homeTeam)}.svg`}></img></Col> */}
                <Col><img className="hitting-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(homeTeam.teamAbbr)}.svg`}></img> {props.Venue}</Col>
                
            </Row>
            <Row className="bg-light-gray ">
                <Col xs>
                    <Row className='player-ranking-value sm'>{props.R / 100}</Row>
                    <Row className='player-ranking-label sm'>Rs</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value sm'>{props.HR / 100}</Row>
                    <Row className='player-ranking-label'>HRs</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value sm'>{props['1B'] / 100}</Row>
                    <Row className='player-ranking-label'>1Bs</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value sm'>{props['2B'] / 100}</Row>
                    <Row className='player-ranking-label'>2Bs</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value sm'>{props['3B'] / 100}</Row>
                    <Row className='player-ranking-label'>3Bs</Row>
                </Col>
            </Row>
            <Row className='shrink-weather-game'>
               
                {weather && <WeatherTable className='text-size-70 matchup-summary-weather-game-table' rowsToShow={["Conditions", 'Temp', 'Precip %']} hours={weather}></WeatherTable>}
                
            </Row>
        </Col>
    )
}


export const remove_leading_zeros = (val) => {
     // Convert the number to a string
  const decimalString = String(val);

  // Check if the decimal string starts with '0.' (zero followed by a dot)
  if (decimalString.startsWith('0.')) {
    // If yes, remove the first character ('0')
    return decimalString.slice(1);
  } else {
    // If no leading zero, return the original string
    return decimalString;
  }
}
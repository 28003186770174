import { Row, Col } from 'reactstrap';
import { getLogoId } from '../../mlb-team-logos/logo-mapper';
import ParkFactorPopover from '../games/park-factor-popover';
import { TeamStatsRows } from './TeamStatsRows';
import { timeZoneDisplayMap } from '../../display-helpers/timezone-display-map';
import { formatDateToMonthSlashDay } from '../../dates/date-helper';

export const TeamMatchupHeader = (props) => {

  const { gameInfo, parkData } = props;

  const dateDisplay = formatDateToMonthSlashDay(gameInfo.gameDate);
   
  const gameDateTime = `${dateDisplay} ${gameInfo.gameTime?.time} ${timeZoneDisplayMap[gameInfo.gameTime?.tz]}`
  return (
    <div>
      <Row className="team-info">
        <Col className="team-abbr">{gameInfo.awayTeam?.teamAbbr}</Col>
        <Col className='text-size-60'>{gameDateTime}</Col>
        <Col className="team-abbr">{gameInfo.homeTeam?.teamAbbr}</Col>
      </Row>
      <Row className="team-info">
        <Col><img className="hitting-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(gameInfo.awayTeam?.teamAbbr)}.svg`}></img></Col>
        <Col>@</Col>
        <Col><img className="hitting-team-logo" src={`https://www.mlbstatic.com/team-logos/${getLogoId(gameInfo.homeTeam?.teamAbbr)}.svg`}></img></Col>
      </Row>
      <Row className="team-info">
        <Col className="team-record">{`(${gameInfo.awayTeam?.wins} - ${gameInfo.awayTeam?.losses})`}</Col>
        <Col></Col>
        <Col className="team-record">{`(${gameInfo.homeTeam?.wins} - ${gameInfo.homeTeam?.losses})`}</Col>
      </Row>
      <TeamStatsRows gameInfo={gameInfo}></TeamStatsRows>

      <Row className="team-info">
        <Col className="park-factor">{parkData?.Venue} <span id={`${parkData.TeamAbbr}`} className='bold park-factor'>PF: {parkData.ParkFactor}</span></Col>
      </Row>
      <ParkFactorPopover popoverId={`${parkData.TeamAbbr}`} parkFactor={parkData.ParkFactor}></ParkFactorPopover>
    </div>
  );
};



import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from "recharts";
import { populate_stat_sets, get_max_stat_value, get_average_stat_value } from "./strategy-builder-container";

export const TeamStatRadarChart = (props) => {
    const { week_by_week_stats, team_id, subset_selected_key, stat_group } = props;
    if (!week_by_week_stats) {
        return <div></div>;
    }

    const teams_stats = week_by_week_stats.find(x => x.team.team_id == team_id);

    let team_specific_set_of_stats_for_subset = [];
    let rest_of_league_set_of_stats_for_subset = [];

    ({ team_specific_set_of_stats_for_subset, rest_of_league_set_of_stats_for_subset } = populate_stat_sets(subset_selected_key, teams_stats, team_specific_set_of_stats_for_subset, rest_of_league_set_of_stats_for_subset, week_by_week_stats));

    const chart_data = [];
    team_specific_set_of_stats_for_subset.filter(x => x.group == stat_group).forEach(stat => {
        const statsToSkip = ['H/AB', 'IP'];

        if (statsToSkip.includes(stat.abbr)) {
            return;
        }
        const stat_max_value = get_max_stat_value(rest_of_league_set_of_stats_for_subset, stat.stat_id);
        const stat_avg = get_average_stat_value(rest_of_league_set_of_stats_for_subset, stat.stat_id);

        const point = { stat: stat.display_name, my_team: stat_max_value == 0 ? 0 : (stat.value / stat_max_value) * 100, average: stat_max_value == 0 ? 0 : (stat_avg / stat_max_value) * 100 };
        chart_data.push(point);
    });

    return (
        <div>
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chart_data} width={350} height={350}>
                <PolarGrid></PolarGrid>
                <PolarAngleAxis dataKey='stat'></PolarAngleAxis>
                <PolarRadiusAxis angle={90} axisLine={false} tick={false} domain={[0, 100]}></PolarRadiusAxis>

                {/* <Radar name='Leader' dataKey='leader'stroke="black" fill="#eee" fillOpacity={0.05} ></Radar> */}
                <Radar name='League Avg' dataKey='average' stroke="#BBBBBB" fill="#BBBBBB" fillOpacity={0.6}></Radar>
                <Radar name='My Team' dataKey='my_team' stroke="#33BBEE" fill="#33BBEE" fillOpacity={0.3}></Radar>

                <Legend></Legend>
            </RadarChart>
        </div>
    );
};

import getApi from "./axios/api";

export async function getUserRoster(userToken, refreshUserToken, leagueId, teamId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/roster/getRoster/${leagueId}/${teamId}`);
    return resp;
}

export async function getUserCurrentAndFutureRoster(userToken, refreshUserToken, leagueId, teamId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/roster/getCurrentAndFutureRoster/${leagueId}/${teamId}`);
    return resp;
}

export async function getUserPlayers(userToken, refreshUserToken, leagueId, teamId) {
    const api = getApi(userToken, refreshUserToken);
    const resp = await api.get(`/roster/getMyPlayers/${leagueId}/${teamId}`);
    return resp;
}

import { HitterStatsView } from './HitterStatsView';
import { statsToShow } from "./hitter-stats-to-show";

export const HitterStatsPerGameView = (props) => {
    
    // const modified_stats_to_show = [{statKey: "G", statLabel:"G"}, ...statsToShow]
    console.log(props.hitter)

    const season_splits = props.hitter.splits?.season?.per_game;
    const season_stats = props.hitter.stats?.season;

    return (
      <HitterStatsView statsToShow={statsToShow} stats={season_stats?.per_game} splits={season_splits} platoonInfo={props.hitter.platoonInfo} shouldRound></HitterStatsView>
    );
};



import { Col, Row } from 'reactstrap';

export const LeftRightyGameBreakdown = (props) => {
    const { gamesVsLefty, gamesVsRighty, gamesVsUnknown } = props;
    return (
        <div className=''>
            <Row>
                <Row className='bottom-border'>
                    <Col>
                    </Col>
                    <Col>
                        <Row className='player-ranking-value'>{gamesVsLefty + gamesVsRighty + gamesVsUnknown}</Row>
                        <Row className='player-ranking-label'>Games</Row>
                    </Col>
                    <Col>
                        <Row className='player-ranking-value'>{gamesVsRighty}</Row>
                        <Row className='player-ranking-label'>vs RHP</Row>
                    </Col>
                    <Col>
                        <Row className='player-ranking-value'>{gamesVsLefty}</Row>
                        <Row className='player-ranking-label'>vs LHP</Row>
                    </Col>
                    <Col>

                    </Col>

                </Row>
            </Row>
        </div>
    );
};

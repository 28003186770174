import { Col, Row } from 'reactstrap';





export const PlayerRankingInfo = (props) => {
    if (!props.hitter?.player_ranks?.player_rank?.length > 0) {
        return <div></div>;
    }
    const current_season = '2024';
    const player_rankings = props.hitter.player_ranks.player_rank;

    // ToDo: is this the right rank?
    const seasonRank = player_rankings.find(x => x.rank_type == "S" && x.rank_season == current_season);
    const last7rank = player_rankings.find(x => x.rank_type === 'L7');
    const last14rank = player_rankings.find(x => x.rank_type === 'L14');
    const last30rank = player_rankings.find(x => x.rank_type === 'L30');

    return (
        <div className='bg-light-gray margin-10'>

            <Row className='text-align-center'><strong>Rankings</strong></Row>
            <Row>
                {/* <Col sm='1'>
        <FontAwesomeIcon className="" icon={faArrowUp} size="1x"></FontAwesomeIcon>
        </Col> */}
                <Col>
                    <Row className='player-ranking-value'>
                        {seasonRank.rank_value}
                        {/* <FontAwesomeIcon className="" icon={faArrowUp} size="1x"></FontAwesomeIcon> */}
                    </Row>
                    <Row className='player-ranking-label'>Season</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value'>{last7rank.rank_value}</Row>
                    <Row className='player-ranking-label'>Last 7 Days</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value'>{last14rank.rank_value}</Row>
                    <Row className='player-ranking-label'>Last 14 Days</Row>
                </Col>
                <Col>
                    <Row className='player-ranking-value'>{last30rank.rank_value}</Row>
                    <Row className='player-ranking-label'>Last 30 Days</Row>
                </Col>
            </Row>
        </div>
    );
};

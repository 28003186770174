import { UncontrolledPopover, PopoverHeader, PopoverBody, Row,Col, Table } from "reactstrap";

export const ParkInfoPopover = (props) => {
    const {parkInfo, targetId} = props;
        

    return (
    <UncontrolledPopover trigger='legacy' target={targetId}>
        <PopoverHeader className="text-align-center">{parkInfo.venue}</PopoverHeader>
        <PopoverBody>
            <Row>
                <Col>
                    <Row className="player-ranking-value">{parkInfo.ParkFactor}</Row>
                    <Row className="player-ranking-label">Park Factor</Row>
                </Col>
           
                <Col>
                    <Row className="player-ranking-value">{parkInfo.PfRank}</Row>
                    <Row className="player-ranking-label">PF-Rank</Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className="player-ranking-value">{parkInfo.HR}</Row>
                    <Row className="player-ranking-label">HR</Row>
                </Col>
           
                <Col>
                    <Row className="player-ranking-value">{parkInfo.HR_Rank}</Row>
                    <Row className="player-ranking-label">HR-Rank</Row>
                </Col>
            </Row>
        </PopoverBody>
    </UncontrolledPopover>
    )
}